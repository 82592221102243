import React__default from 'react';

// export const useToggle = ({initialValue = false}: ToggleValue) => {
//   if (typeof initialValue !== 'boolean') {
//     console.warn('Invalid type for useToggle')
//   }
//   console.log('initial value in hook: ', initialValue)
//   const [value, setValue] = useState(initialValue)

//   function toggleValue() {
//     setValue(currentValue => !currentValue)
//   }
//   console.log('value in hook: ', value)
//   return [value, toggleValue]
// }
const useToggle = (initialValue = false) => {
  const [value, setValue] = React__default.useState(initialValue);
  const toggle = React__default.useCallback(() => {
    setValue(v => !v);
  }, []);
  return [value, toggle];
};

export { useToggle };
