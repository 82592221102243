import { partitionColors, omitScale } from "../ThemeSelector.helpers"
import light from "@fuegokit/tokens/dist/js/esm/fk-monday/colors/light"
import dark from "@fuegokit/tokens/dist/js/esm/fk-monday/colors/dark"
import typography from "@fuegokit/tokens/dist/js/esm/fk-monday/base/typography"
import radiiValues from "@fuegokit/tokens/dist/js/esm/fk-monday/base/radii"
import spacing from "@fuegokit/tokens/dist/js/esm/fk-monday/base/size"

// @TODO: ESM generates default exports, so we have to do this dance
const colors = { light: { ...light }, dark: { ...dark } }

let colorSchemes = Object.entries(colors).reduce((acc, [name, variables]) => {
  const { colors, shadows } = partitionColors(variables)
  //@ts-expect-error @TODO - types
  acc[name] = {
    colors: omitScale(colors),
    shadows: omitScale(shadows),
  }

  return acc
}, {})

const { size, space: spaceObject } = spacing

const {
  fontStack,
  fontFamily,
  fontSize: fontSizeObject,
  lineHeight: lineHeightsObject,
  fontWeight: fontWeights,
} = typography

const fonts = {
  sansSerif: fontStack.sansSerif,
  monospace: fontStack.monospace,
  title: fontStack.title,
  body: fontStack.body,
}

const { radii: radiiObject } = radiiValues

const space = Object.values(spaceObject)
const radii = Object.values(radiiObject)

const fontSizes = Object.values(fontSizeObject)
const lineHeights = Object.values(lineHeightsObject)

export const monday = {
  /** colors, spacing, typography */
  lineHeights,
  fontWeights,
  space,
  radii,
  colorSchemes,
  fontSizes,
  fonts,
}
