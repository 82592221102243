import React from "react"
import styled from "styled-components"
import { Button, ButtonProps, themeGet, sx } from "@fuegokit/react"
import { TemporaryDemoType, Appearance } from "../../types/shared"

type AppfireButtonType = {
  appearance?: Appearance
}
type AppfireDemoButtonProps = TemporaryDemoType &
  AppfireButtonType &
  ButtonProps

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const colors: { [key: string]: any } = {
  background: {
    default: {
      default: "#090a0f",
      // @TODO: brand tokens don't have nested surface interaction modifiers, but they should
      // right now, if you tried to reference themeGet('colors.elevation.surface.default'), TS will complain that that
      // token doesn't exist in FuegokitThemeTypes; we should keep that as the DX and just add the nested modifier to the token
      hover: "#1C1B1C",
      active: "colors.background.neutral.default.pressed",
      disabled: "colors.background.disabled",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
    primary: {
      default: "colors.background.brand.bold.default",
      hover: "colors.background.brand.bold.hovered",
      active: "colors.background.brand.bold.pressed",
      disabled: "colors.background.disabled",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
    warning: {
      default: "colors.background.warning.bold.default",
      hover: "colors.background.warning.bold.hovered",
      active: "colors.background.warning.bold.pressed",
      disabled: "colors.background.disabled",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
    danger: {
      default: "colors.background.danger.bold.default",
      hover: "colors.background.danger.bold.hovered",
      active: "colors.background.danger.bold.pressed",
      disabled: "colors.background.disabled",
      selected: "colors.background.danger.bold.pressed",
      focusSelected: "colors.background.selected.default.[default]",
    },
    link: {
      default: "colors.background.neutral.subtle.default",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
    subtle: {
      default: "colors.elevation.surface.default",
      hover: "colors.elevation.surface.raised.hovered",
      active: "colors.background.neutral.subtle.pressed",
      disabled: "colors.background.neutral.subtle.default",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
    "subtle-link": {
      default: "colors.background.neutral.subtle.default",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
    outline: {
      // @TODO: brand elevation tokens need surface.default modifiers
      default: "colors.elevation.surface.default",
      hover: "colors.elevation.surface.raised.hovered",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
  },
  boxShadowColor: {
    default: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
      offset: "colors.elevation.surface.default",
    },
    primary: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
      offset: "colors.elevation.surface.default",
    },
    warning: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
      offset: "colors.elevation.surface.default",
    },
    danger: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
      offset: "colors.elevation.surface.default",
    },
    link: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
      offset: "colors.elevation.surface.default",
    },
    subtle: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
      offset: "colors.elevation.surface.default",
    },
    "subtle-link": {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
      offset: "colors.elevation.surface.default",
    },
    outline: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
      offset: "colors.elevation.surface.default",
    },
  },
  color: {
    default: {
      default: "colors.text.default",
      active: "colors.text.default",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    primary: {
      default: "colors.text.default",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    warning: {
      default: "colors.text.warning.inverse",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    danger: {
      default: "colors.text.inverse",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    link: {
      default: "colors.link.default",
      hover: "colors.link.default",
      active: "colors.link.pressed",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    subtle: {
      default: "colors.text.default",
      active: "colors.text.default",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    "subtle-link": {
      default: "colors.text.subtle",
      hover: "colors.text.subtle",
      active: "colors.text.default",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    outline: {
      default: "colors.text.default",
      hover: "colors.text.subtle",
      active: "colors.text.default",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
  },
  borderColor: {
    default: {
      default: "colors.text.default",
      active: "colors.border.default",
      disabled: "colors.border.disabled",
      selected: "colors.border.selected",
      focusSelected: "colors.border.selected",
    },
    primary: {
      default: "colors.background.brand.bold.default",
      hover: "colors.background.brand.bold.hovered",
      disabled: "colors.border.disabled",
      selected: "colors.border.selected",
      focusSelected: "colors.border.selected",
    },
    warning: {
      default: "colors.text.warning.inverse",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    danger: {
      default: "colors.text.inverse",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    link: {
      default: "colors.link.default",
      hover: "colors.link.default",
      active: "colors.link.pressed",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    subtle: {
      default: "colors.text.default",
      active: "colors.text.default",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    "subtle-link": {
      default: "colors.text.subtle",
      hover: "colors.text.subtle",
      active: "colors.text.default",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    outline: {
      default: "colors.text.default",
      hover: "colors.text.subtle",
      active: "colors.text.default",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
  },
}
export const AppfireButton = ({
  children,
  appearance = "outline",
  ...props
}: AppfireDemoButtonProps) => {
  return (
    <StyledAppfireButton appearance={appearance} {...props}>
      {children}
    </StyledAppfireButton>
  )
}

const StyledAppfireButton = styled(Button)<AppfireDemoButtonProps>`
  align-items: baseline;
  border-width: 0;
  box-sizing: border-box;
  display: inline-flex; // Margins don't collapse with inline-flex, which helps with preceding/trailing visuals and button content spacing
  font-style: normal;
  max-width: 100%;
  position: relative;

  text-align: center;
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  box-shadow: none;
  //
  font-size: ${themeGet("fontSizes.3")};
  padding: 12px 24px 9px 24px;
  border-radius: ${themeGet("radii.5")};
  //
  border: 2px solid;
  //

  // appearances
  background-color: ${p =>
    p.appearance && p.appearance === "outline"
      ? // @TODO: we can't use themeGet here until we have nested interactive modifiers on elevation.surface.default in brand tokens
        "#090a0f"
      : p.appearance
      ? themeGet(colors.background[p.appearance].default)
      : ""};
  color: ${p =>
    p.appearance
      ? themeGet(colors.color[p.appearance].default)
      : themeGet("colors.text.default")};
  border-color: transparent;
  //

  &:hover {
    cursor: pointer;

    color: ${p =>
      p.appearance ? themeGet(colors.color[p.appearance].hover) : "#FFFFFF"};

    background-color: ${p =>
      p.appearance ? themeGet(colors.background[p.appearance].hover) : ""};

    // link styles
    text-decoration: ${p =>
      p.appearance && p.appearance === "link" ? "underline" : "inherit"};

    text-decoration-color: ${p =>
      p.appearance && p.appearance === "link"
        ? themeGet(colors.borderColor[p.appearance].focus)
        : "#FFFFFF"};

    text-underline-offset: ${p =>
      p.appearance && p.appearance === "link" ? "6px" : ""};

    text-decoration-thickness: ${p =>
      p.appearance && p.appearance === "link" && p.size !== "small"
        ? "2px"
        : ""};
  }

  &:active {
    outline: none;
    transition-duration: 0s;

    background: ${p =>
      p.appearance ? themeGet(colors.background[p.appearance].active) : ""};
    color: ${p =>
      p.appearance ? themeGet(colors.color[p.appearance].active) : ""};
  }

  &:disabled {
    cursor: not-allowed;

    color: ${p =>
      p.disabled && p.appearance
        ? themeGet(colors.color[p.appearance].disabled)
        : ""};

    background: ${p =>
      p.disabled && p.appearance
        ? themeGet(colors.background[p.appearance].disabled)
        : ""};

    border-color: ${p =>
      p.disabled && p.appearance
        ? themeGet(colors.borderColor[p.appearance].disabled)
        : ""};

    text-decoration: none;
  }

  transition-delay: 0s;
  transition-duration: 0.25s;
  transition-property: background-color, border-color, color;
  transition-timing-function: ease-in-out;
  text-rendering: geometricPrecision;
  font-family: "Area Normal Regular";
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  ${sx}
`
