import React from "react";
import styled from "styled-components";
import { themeGet, useTheme } from "@fuegokit/react";

export const AdaptiveImage = ({ lightSrc, darkSrc, alt }) => {
  const { colorMode } = useTheme();

  return <Image src={colorMode == "night" ? darkSrc : lightSrc} alt={alt} />;
};

const Image = styled.img`
  border-radius: ${themeGet("radii.1")};
`;
