import sx from '../../sx.js';
import styled from 'styled-components';
import Radio from '../Radio/Radio.js';

const UnstyledRadio = styled(Radio).withConfig({
  displayName: "UnstyledRadio",
  componentId: "sc-ylwd4d-0"
})(["appearance:none;border-color:unset;border-style:unset;border-width:unset;background-color:unset;cursor:unset;display:unset;height:unset;margin:unset;margin-top:unset;place-content:unset;position:unset;width:unset;border-radius:unset;transition:unset;@media (forced-colors:active){background-color:unset;border-color:unset;}", ""], sx);

export { UnstyledRadio };
