import { useState, useEffect } from "react"
import isEmpty from "lodash.isempty"
import isObject from "lodash.isobject"
import chroma from "chroma-js"

// @TODO - types
export function omitScale(obj: any) {
  const { scale, palette, ...rest } = obj
  return rest
}

// @TODO - types
export const fontStack = (font: any) => {
  //@ts-expect-error @TODO - types
  return fonts.map(font => (font.includes(" ") ? `"${font}"` : font)).join(", ")
}

// @TODO - types
function isShadowValue(value: any) {
  return (
    typeof value === "string" &&
    /(inset\s|)([0-9.]+(\w*)\s){1,4}(rgb[a]?\(.*\)|\w+)/.test(value)
  )
}

// @TODO - types
function isColorValue(value: any) {
  return chroma.valid(value)
}

// @TODO - types
export const filterObject = (obj: any, predicate: any) => {
  if (Array.isArray(obj)) {
    return obj.filter(predicate)
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (isObject(value)) {
      const result = filterObject(value, predicate)

      // Don't include empty objects or arrays
      if (!isEmpty(result)) {
        //@ts-expect-error @TODO - types
        acc[key] = result
      }
    } else if (predicate(value)) {
      //@ts-expect-error @TODO - types
      acc[key] = value
    }
    return acc
  }, {})
}

// @TODO - types
export const partitionColors = (colors: any) => {
  return {
    colors: filterObject(colors, (value: any) => isColorValue(value)),
    shadows: filterObject(colors, (value: any) => isShadowValue(value)),
  }
}

// @TODO - types
export const capitalize = (str: any) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// @TODO - types
export const setToLocalStorage = (key: any, value: any) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
}

// @TODO - types
export const getFromLocalStorage = (key: any) => {
  if (typeof window !== "undefined") {
    const value = window.localStorage.getItem(key)

    if (value) {
      return JSON.parse(value)
    }
  }
}

export const useProductTheme = () => {
  const themes = getFromLocalStorage("all-themes")

  const [activeTheme, setActiveTheme] = useState(themes ? themes.atlassian : "")
  const [themeLoaded, setThemeLoaded] = useState(false)

  // @TODO - types
  const setMode = (mode: any) => {
    setToLocalStorage("theme", mode)
    setActiveTheme(mode)
  }

  const getFonts = () => {
    const allFonts = Object.values(
      //@ts-expect-error @TODO - types
      Object.entries(themes).reduce((a, [key, { fonts }]) => {
        //@ts-expect-error @TODO - types
        a[key] = fonts
        return a
      }, {})
    )

    return allFonts
  }

  useEffect(() => {
    const localTheme = getFromLocalStorage("theme")
    localTheme ? setActiveTheme(localTheme) : setActiveTheme(themes.atlassian)
    setThemeLoaded(true)
  }, [])

  return { activeTheme, themeLoaded, setMode }
}
