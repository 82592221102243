import styled from "styled-components"
import { themeGet } from "@fuegokit/react"
import React, { forwardRef } from "react"

interface ArticlePageDividerProps extends React.HTMLAttributes<HTMLHRElement> {
  docsTheme?: "default" | "brand"
}

const ArticlePageDivider = forwardRef<HTMLHRElement, ArticlePageDividerProps>(
  ({ docsTheme = "brand", ...props }, ref) => {
    return <StyledHorizontalRule docsTheme={docsTheme} {...props} ref={ref} />
  }
)

const StyledHorizontalRule = styled.hr<ArticlePageDividerProps>`
  height: 1px;
  padding: 0;
  margin: ${themeGet("space.4")} 0;
  border: 0;
`

export default ArticlePageDivider
