import React, { useState, useEffect } from "react"
import { RadioGroup } from "@fuegokit/react"
import {
  capitalize,
  getFromLocalStorage,
  useProductTheme,
} from "./ThemeSelector.helpers"
import { Radio, RadioStack, RadioLabel, RadioWrapper } from "../Radio"

// @TODO: properly annotate the types for this component
const ThemeSelector = ({
  themeSetter,
  activeTheme,
  selectedTheme,
  ...props
}: any) => {
  const themesFromStore = getFromLocalStorage("all-themes")
  const [themesData, setThemesData] = useState(themesFromStore)
  const [themes, setThemes] = useState([])
  const { setMode } = useProductTheme()

  // @TODO - types
  const switchTheme = (selectedTheme: any) => {
    setMode(selectedTheme)
    themeSetter(selectedTheme)
  }

  useEffect(() => {
    // @TODO - types
    setThemes(Object.keys(themesData) as any)
  }, [themesData])

  return (
    <RadioGroup name={`themesChoices`}>
      <RadioStack>
        {themes.map((theme, index) => {
          return (
            <RadioWrapper key={`${theme}-${index}`}>
              <Radio
                id={capitalize(themesData[theme].name)}
                name="ecosystemMode"
                value={capitalize(themesData[theme].name)}
                onChange={() => {
                  switchTheme(themesData[theme])
                }}
                defaultChecked={theme && theme === "atlassian" ? true : false}
              />
              <RadioLabel
                activeTheme={activeTheme}
                htmlFor={capitalize(themesData[theme].name)}
              >
                {capitalize(themesData[theme].name)}
              </RadioLabel>
            </RadioWrapper>
          )
        })}
      </RadioStack>
    </RadioGroup>
  )
}

export default ThemeSelector
