import React from "react";
import styled from "styled-components";

import { navigate } from "gatsby";
import { Box, Text, Link, themeGet, AtlasButton } from "@fuegokit/react";

import { AfArrowLeftIcon } from "@fuegokit/fuegoicons-react";

const BackButton = () => {
  const handleBackClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };
  return (
    <StyledBackButton appearance="subtle" onClick={handleBackClick}>
      <AfArrowLeftIcon size={16} />
      Back
    </StyledBackButton>
  );
};

const StyledBackButton = styled(AtlasButton)`
  color: ${themeGet("colors.text.subtle")};
  align-self: flex-start;
  margin-bottom: ${themeGet("space.5")};
  margin-left: -10px;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${themeGet("space.1")};
  }
`;
export default BackButton;
