import React from "react"
import { Text, Stack, Box, Link } from "@fuegokit/react"

const Footer = () => {
  return (
    <Stack
      as="footer"
      size={2}
      sx={{
        backgroundColor: "elevation.surface.raised",
        alignItems: "flex-end",
        minHeight: "84px",
        p: 4,
        maxWidth: "1200px",
        fontSize: 3,

        color: "text.subtle",
        margin: "0 auto",
      }}
    >
      <Text as="p" sx={{ fontSize: 1 }}>
        Designed, built, and maintained by the Fuegokit team.
      </Text>

      <Box as="span" sx={{ fontSize: 1 }}>
        © {new Date().getFullYear()} &middot; {` `}
        <Link href="https://www.appfire.com">Appfire</Link>
      </Box>
    </Stack>
  )
}
export default Footer
