function isTextDropItem(dropItem) {
  return dropItem.kind === 'text' && dropItem.types.has('text/plain');
}
function isFileDropItem(dropItem) {
  return dropItem.kind === 'file';
}
function isDirectoryDropItem(dropItem) {
  return dropItem.kind === 'directory';
}

export { isDirectoryDropItem, isFileDropItem, isTextDropItem };
