import React from "react";
import {
  GlobalHeaderMenu,
  AtlasButton,
  Text,
  Link,
  themeGet,
  VisuallyHidden,
} from "@fuegokit/react";
import styled from "styled-components";

import { AkAppSwitcherIcon, AkLockIcon } from "@fuegokit/fuegoicons-react";

export const HeaderNavDropdown = ({ items, docsTheme = "default" }) => {
  return (
    <GlobalHeaderMenu>
      <GlobalHeaderMenu.Trigger spacing={`default`} docsTheme={docsTheme}>
        <VisuallyHidden>Expand menu</VisuallyHidden>

        <AkAppSwitcherIcon size={"small"} />
      </GlobalHeaderMenu.Trigger>
      <GlobalHeaderMenu.Portal>
        <StyledGlobalHeaderMenuContent sideOffset={10} docsTheme={docsTheme}>
          {items.map((item, index) => {
            if (item.children) {
              return (
                <React.Fragment key={`${item}-${index}`}>
                  <StyledMenuItemsTitle>{item.title}</StyledMenuItemsTitle>

                  {item.children.map((child, index) => (
                    <GlobalHeaderMenu.Item
                      key={child.title + index}
                      onSelect={() => {}}
                      $isPrivate={child.isPrivate}
                    >
                      <StyledLink
                        href={child.url}
                        $isPrivate={child.isPrivate}
                        docsTheme={docsTheme}
                      >
                        {child.title}
                        {child.isPrivate && <AkLockIcon size={`small`} />}
                      </StyledLink>
                    </GlobalHeaderMenu.Item>
                  ))}
                </React.Fragment>
              );
            }
            return (
              // todo: return this to Link when URL deployed and link stable
              <GlobalHeaderMenu.Item
                key={item.title + index}
                onSelect={() => {}}
              >
                <StyledLink href={item.url}>{item.title}</StyledLink>
              </GlobalHeaderMenu.Item>
            );
          })}
        </StyledGlobalHeaderMenuContent>
      </GlobalHeaderMenu.Portal>
    </GlobalHeaderMenu>
  );
};

const StyledMenuItemsTitle = styled(Text)`
  padding-top: ${themeGet("space.2")};
  padding-bottom: ${themeGet("space.3")};
  margin: ${themeGet("space.2")} ${themeGet("space.3")};
  margin-top: ${themeGet("space.2")};
  border-bottom: 1px solid ${themeGet("colors.border.default")};
  display: block;
  color: inherit;

  text-decoration: none;
  font-weight: ${themeGet("fontWeights.medium")};
  font-size: ${themeGet("fontSizes.3")};
  &:hover {
    text-decoration: none;
    cursor: revert;
    background-color: revert;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  padding-right: ${themeGet("space.2")};
  white-space: nowrap;
  display: flex;
  color: ${(p) =>
    p.$isPrivate
      ? themeGet("colors.text.disabled")
      : themeGet("colors.text.subtle")};
  text-decoration: none;

  border-radius: ${themeGet("radii.2")};
  font-weight: ${themeGet("fontWeights.normal")};
  justify-content: space-between;
  &:hover {
    pointer-events: ${(p) => (p.$isPrivate ? "none" : "revert")};
    text-decoration: none;
    cursor: pointer;
  }
`;
const StyledGlobalHeaderMenuContent = styled(GlobalHeaderMenu.Content)`
  background-color: ${themeGet("colors.elevation.surface.default.[default]")};
  border-color: ${themeGet("colors.border.subtle")};
  border-radius: ${themeGet("radii.2")};
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;
`;
