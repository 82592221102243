import sx from '../../sx.js';
import { themeGet } from '../../utils/theme-get.js';
import styled from 'styled-components';
import Box from '../Box/Box.js';

const MaxWidthWrapper = styled(Box).withConfig({
  displayName: "MaxWidthWrapper",
  componentId: "sc-ipay2f-0"
})(["margin:0 auto;@media (min-width:350px){min-width:325px;}@media (min-width:", "){width:564px;}@media (min-width:", "){width:769px;}@media (min-width:", "){width:956px;}", ""], themeGet('breakpoints.0'), themeGet('breakpoints.1'), themeGet('breakpoints.2'), sx);

export { MaxWidthWrapper };
