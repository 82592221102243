import React, { useEffect } from "react";
import styled from "styled-components";
import Highlight, { defaultProps } from "prism-react-renderer";
import Prism from "../../../prism";
import { Text, Box, AtlasButton, Spacer, themeGet } from "@fuegokit/react";
import { AkArrowDownIcon, AkArrowUpIcon } from "@fuegokit/fuegoicons-react";

import { CodePath } from "./CodePath";
import { LiveCode } from "./LiveCode";
import CopyToClipboard from "../CopyToClipboard";
import fuegokitTheme from "./fuegokitTheme";

// TODO: make collapsible snippet logic optional
export const Code = ({
  children,
  className,
  path,
  src,
  noinline,
  live,
  collapsible = true,
}) => {
  const [isLongSnippet, setIsLongSnippet] = React.useState(false);

  const [shouldDisplayMore, setShouldDisplayMore] = React.useState(false);
  const language = className ? className.replace(/language-/, "") : "";

  // removes new lines and empty space at the bottom of a <pre>
  const code = children.trim();

  const removeEmptySpace = (lines) => {
    const [lastLine] = lines.splice(-1);
    if (lastLine[0].empty) {
      return lines;
    }
    return [...lines, lastLine];
  };

  const getCode = (lines) => {
    const withoutEmptySpace = removeEmptySpace(lines);

    if (withoutEmptySpace.length > 9) {
      setIsLongSnippet(true);
    }

    if (!collapsible || shouldDisplayMore) {
      return withoutEmptySpace;
    } else {
      return withoutEmptySpace.slice(0, 9);
    }
  };

  if (live) {
    return (
      <LiveCode
        code={code}
        language={language}
        noinline={noinline}
        path={path}
      />
    );
  }

  return (
    <PreWrapperBox>
      <CodePath src={src} path={path} code={code}>
        {children}
      </CodePath>

      <Highlight
        {...defaultProps}
        Prism={Prism}
        code={code}
        language={language}
        theme={fuegokitTheme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Box sx={{ display: "flex" }}>
            <Box
              as="pre"
              className={className}
              style={{ ...style, overflow: "auto", position: "relative" }}
              sx={{
                borderRadius: 2,
                my: 0,
                p: 3,
                border: 0,
              }}
            >
              {getCode(tokens).map((line, i) => (
                // eslint-disable-next-line react/jsx-key
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <Text
                      key={key}
                      {...getTokenProps({ token, key })}
                      sx={{ fontFamily: "mono", fontSize: "88%" }}
                    />
                  ))}
                </div>
              ))}
            </Box>
          </Box>
        )}
      </Highlight>
      <Box
        sx={{
          top: 1,
          right: 1,
          p: 2,
          borderRadius: 2,
          position: "absolute",
          bg: "rgb(35, 40, 46)",
          ":hover": {
            bg: "background.neutral.bold.hovered",
          },
        }}
      >
        <CopyToClipboard value={code} />
      </Box>
      {isLongSnippet && collapsible ? (
        <Box sx={{ display: "flex" }}>
          <ShowMoreBtn
            onClick={() => setShouldDisplayMore(!shouldDisplayMore)}
            sx={{ color: "text.inverse" }}
            as="button"
          >
            {shouldDisplayMore ? (
              <>
                <Box as="span">Show less</Box>
                <Spacer axis={`horizontal`} size={4} />
                <AkArrowUpIcon size={`small`} />
              </>
            ) : (
              <>
                <Box as="span">Show more</Box>
                <Spacer axis={`horizontal`} size={4} />
                <AkArrowDownIcon size={`small`} />
              </>
            )}
          </ShowMoreBtn>
        </Box>
      ) : null}
    </PreWrapperBox>
  );
};

const ShowMoreBtn = styled(AtlasButton)`
  /* todo: define in elements 
   color: ${themeGet("colors.text.inverse")}; */
  display: flex;
  color: #ffffff;
  border: 1px solid transparent;
  &:hover {
    color: #ffffff;
    background-color: ${themeGet("colors.background.neutral.subtle.hovered")};
    border-color: ${themeGet("colors.border.discovery")};
    cursor: pointer;
  }
  background-color: #23282e;
`;

const PreWrapperBox = styled(Box)`
  margin-top: 8px;
  margin-bottom: ${themeGet("space.3")};
  border-radius: ${themeGet("radii.3")};
  background-color: #23282e;
  // Making a <pre> element adjust to the width of the container
  // https://stackoverflow.com/a/14406386
  display: table;
  table-layout: fixed;
  width: 100%;
  position: relative;
`;
