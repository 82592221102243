import React from "react";
import styled from "styled-components";
import { Link, Text, themeGet } from "@fuegokit/react";
import { AkBitbucketSourceIcon } from "@fuegokit/fuegoicons-react";

const SourceLink = ({ href }) => {
  return (
    <StyledLink
      subtle
      href={href}
      sx={{ display: "flex", alignItems: "center", p: 3, borderRadius: 2 }}
    >
      <AkBitbucketSourceIcon />
      <Text sx={{ ml: 1, display: "inline-block" }}>Source</Text>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  border: 1px solid;
  border-color: ${themeGet("colors.border.subtle")};
  &.active {
    font-weight: ${themeGet("fontWeights.semibold")};
    color: ${themeGet("colors.link.default")};
  }
  &:hover {
    text-decoration: none;
    background-color: ${themeGet("colors.background.neutral.default.hovered")};
    color: ${themeGet("colors.text.default")};
  }
`;

export default SourceLink;
