import React from "react";
import styled from "styled-components";
import { Box, Link, Text, Spacer, themeGet } from "@fuegokit/react";
import { PropsTable } from "./PropsTable";
import { DataAttributeTable } from "./DataAttributeTable";
import { H3 } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Heading";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";

// We're moving the source of truth for component prop documentation into .docs.json files
// that live in the same directory as the
// component's source code. Example:
//
//   src/
//     Button/
//       Button.tsx
//       Button.docs.json
//
// We'll use this `ComponentProps` component to render the prop tables for
// components given the data defined in the .docs.json file.
//
// To render prop documentation in an .mdx file, import the component data:
//
//   import data from '../../src/Button/Button.docs.json'
//
// Then pass the data to `ComponentProps`:
//
//   ## Props
//   <ComponentProps data={data} />
//
// The schema for the `data` object is defined in /script/component-props/component.schema.json

function ComponentProps({ data }) {
  return (
    <Wrapper>
      <H3>{data.name}</H3>
      {data.description ? (
        <Box>
          <Text>{data.description}</Text>
          <Spacer size={24} axis={`vertical`} />
        </Box>
      ) : null}
      <Props props={data.props} passthrough={data.passthrough} />
      {data.subcomponents?.map((subcomponent) => {
        return (
          <React.Fragment key={`${data.name}.${subcomponent.name}`}>
            <H3>{subcomponent.name}</H3>
            {subcomponent.description ? (
              <Box>
                <Text>{subcomponent.description}</Text>
                <Spacer size={24} axis={`vertical`} />
              </Box>
            ) : null}
            <Props
              props={subcomponent.props}
              passthrough={subcomponent.passthrough}
            />
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
}

function Props({ props, passthrough }) {
  const hasBaseProps = props.some((prop) => prop.group === "Base");
  const hasEventsProps = props.some((prop) => prop.group === "Events");
  const hasA11yProps = props.some((prop) => prop.group === "Accessibility");
  const hasLayoutProps = props.some((prop) => prop.group === "Layout");
  const hasAdvancedProps = props.some((prop) => prop.group === "Advanced");
  const hasDataAttributes = props.some(
    (prop) => prop.group === "Data Attributes"
  );

  const hasTestIdType = props.some((prop) => prop.name === "testId");
  const hasUnsafeClassNameType = props.some(
    (prop) => prop.name === "UNSAFE_className"
  );

  if (props.length > 0) {
    return (
      <>
        {hasBaseProps && (
          <PropsTable>
            {props
              .filter((prop) => prop.group === "Base")
              .map((prop, index) => {
                const isPolymorphic = props.some((prop) => prop.name === "as");
                let type = prop.type;
                let description = prop.description;

                // Provide default types and descriptions for common props like `as`, `ref`, and `sx`
                switch (prop.name) {
                  case "as":
                    type = (
                      <Link href="https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react/index.d.ts#L73">
                        React.ElementType
                      </Link>
                    );
                    description =
                      description ||
                      "The underlying element to render — either a HTML element name or a React component.";
                    break;

                  case "ref":
                    if (isPolymorphic) {
                      description = description || (
                        <>
                          A ref to the element rendered by this component.
                          Because this component is polymorphic, the type will
                          vary based on the value of the{" "}
                          <InlineCode>as</InlineCode> prop.
                        </>
                      );
                    } else {
                      description =
                        description ||
                        "A ref to the element rendered by this component.";
                    }
                    break;

                  case "sx":
                    type = (
                      <Link href="https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/styled-system__css/index.d.ts#L407">
                        SystemStyleObject
                      </Link>
                    );
                    description = description || (
                      <>
                        Style overrides to apply to the component. See also{" "}
                        <Link href="/getting-started/sx-prop">the sx prop</Link>
                        .
                      </>
                    );
                    break;
                }

                return (
                  <PropsTable.Row
                    key={`${prop.name}-${index}`}
                    name={prop.name}
                    required={prop.required}
                    deprecated={prop.deprecated}
                    type={type}
                    defaultValue={prop.defaultValue}
                    description={description}
                  />
                );
              })}
            {hasTestIdType && (
              <PropsTable.Row
                name="testId"
                type="string"
                description="A `testId` prop is provided for specified elements, which is a unique string that appears as a data attribute `data-testid` in the rendered code, serving as a hook for automated tests."
              />
            )}
            {hasUnsafeClassNameType && (
              <PropsTable.Row
                name="UNSAFE_className"
                type="string"
                description="Use only as a last resort. The class name applied to the component."
              />
            )}
            {passthrough ? (
              <PropsTable.PassthroughPropsRow
                elementName={passthrough.element}
                passthroughPropsLink={
                  <Link href={passthrough.url}>{passthrough.element} docs</Link>
                }
              />
            ) : null}
          </PropsTable>
        )}
        {hasEventsProps ? (
          <>
            <details>
              <summary>Events</summary>
              <PropsTable>
                {props
                  .filter((prop) => prop.group === "Events")
                  .map((prop) => {
                    let type = prop.type;
                    let description = prop.description;

                    return (
                      <PropsTable.Row
                        key={prop.name}
                        name={prop.name}
                        required={prop.required}
                        deprecated={prop.deprecated}
                        type={type}
                        defaultValue={prop.defaultValue}
                        description={description}
                      />
                    );
                  })}
              </PropsTable>
            </details>
          </>
        ) : null}
        {hasLayoutProps ? (
          <>
            <details>
              <summary>Layout</summary>
              <PropsTable>
                {props
                  .filter((prop) => prop.group === "Layout")
                  .map((prop) => {
                    let type = prop.type;
                    let description = prop.description;

                    return (
                      <PropsTable.Row
                        key={prop.name}
                        name={prop.name}
                        required={prop.required}
                        deprecated={prop.deprecated}
                        type={type}
                        defaultValue={prop.defaultValue}
                        description={description}
                      />
                    );
                  })}
              </PropsTable>
            </details>
          </>
        ) : null}
        {hasA11yProps ? (
          <>
            <details>
              <summary>Accessibility</summary>
              <PropsTable>
                {props
                  .filter((prop) => prop.group === "Accessibility")
                  .map((prop) => {
                    let type = prop.type;
                    let description = prop.description;

                    return (
                      <PropsTable.Row
                        key={prop.name}
                        name={prop.name}
                        required={prop.required}
                        deprecated={prop.deprecated}
                        type={type}
                        defaultValue={prop.defaultValue}
                        description={description}
                      />
                    );
                  })}
              </PropsTable>
            </details>
          </>
        ) : null}
        {hasAdvancedProps ? (
          <>
            <details>
              <summary>Advanced</summary>
              <PropsTable>
                {props
                  .filter((prop) => prop.group === "Advanced")
                  .map((prop) => {
                    let type = prop.type;
                    let description = prop.description;

                    return (
                      <PropsTable.Row
                        key={prop.name}
                        name={prop.name}
                        required={prop.required}
                        deprecated={prop.deprecated}
                        type={type}
                        defaultValue={prop.defaultValue}
                        description={description}
                      />
                    );
                  })}
              </PropsTable>
            </details>
          </>
        ) : null}
        {hasDataAttributes ? (
          <>
            <details>
              <summary>Data attributes</summary>
              <DataAttributeTable>
                {props
                  .filter((prop) => prop.group === "Data Attributes")
                  .map((prop) => {
                    return (
                      <DataAttributeTable.Row
                        key={prop.name}
                        name={prop.name}
                        type={prop.type}
                        required={prop.values}
                      />
                    );
                  })}
              </DataAttributeTable>
            </details>
          </>
        ) : null}
      </>
    );
  }
}

const Wrapper = styled(Box)`
  details {
    margin-top: ${themeGet("space.4")};
  }
  details summary {
    font-size: ${themeGet("fontSizes.4")};
    color: ${themeGet("colors.text.default")};
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 1.1667;
    text-transform: none;
  }
`;
export default ComponentProps;
