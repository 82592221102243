import { Box, themeGet } from "@fuegokit/react";
import { AkCheckIcon, AkCopyIcon } from "@fuegokit/fuegoicons-react";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import React from "react";

import { UnstyledButton } from "../UnstyledButton";

function CopyToClipboard({ value, iconColor, size }) {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <UnstyledButton
      aria-label="Copy to clipboard"
      onClick={() => {
        copy(value);
        setCopied(true);
      }}
      sx={{ px: 2 }}
    >
      {copied ? (
        <Box sx={{ color: "icon.success" }}>
          <AkCheckIcon size={size ? size : 24} />
        </Box>
      ) : (
        <StyledBox
          sx={{ color: iconColor ? iconColor : "#F0F1F4", opacity: 1 }}
        >
          <AkCopyIcon size={size ? size : 24} />
        </StyledBox>
      )}
    </UnstyledButton>
  );
}

const StyledBox = styled(Box)`
  &:hover {
    color: ${themeGet("colors.link.default")};
  }
  &:active {
    color: ${themeGet("colors.link.pressed")};
  }
`;

export default CopyToClipboard;
