import Button from '../Button/Button.js';
import sx from '../../sx.js';
import styled from 'styled-components';

const UnstyledButton = styled(Button).withConfig({
  displayName: "UnstyledButton",
  componentId: "sc-x2xv4b-0"
})(["display:'block';margin:0;padding:0;border:none;background:transparent;cursor:pointer;text-align:left;font:inherit;color:inherit;&:focus{outline-offset:2px;}&:focus:not(:focus-visible){outline:none;}", ""], sx);

export { UnstyledButton };
