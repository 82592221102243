import styled, { keyframes, css } from 'styled-components';
import Dialog from '../Dialog2/Dialog2.js';
import { get } from '../../constants.js';
import Box from '../../components/Box/Box.js';

const overlayShow = keyframes(["from{opacity:0;}to{opacity:1;}"]);
const StyledDialogOverlay = styled(Dialog.Overlay).withConfig({
  displayName: "styles__StyledDialogOverlay",
  componentId: "sc-15mqdp2-0"
})(["background-color:", ";position:fixed;inset:0;animation:", " 150ms cubic-bezier(0.16,1,0.3,1);"], get('colors.blanket.default'), overlayShow);
const contentShow = keyframes(["from{opacity:0;transform:translate(-50%,-48%) scale(0.96);}to{opacity:1;transform:translate(-50%,-50%) scale(1);}"]);
const StyledDialogBox = styled(Dialog.Content).withConfig({
  displayName: "styles__StyledDialogBox",
  componentId: "sc-15mqdp2-1"
})(["", " background-color:", ";border-radius:", ";box-shadow:hsl(206 22% 7% / 35%) 0px 10px 38px -10px,hsl(206 22% 7% / 20%) 0px 10px 20px -15px;position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);max-width:90vw;padding:", ";animation:", " 150ms cubic-bezier(0.16,1,0.3,1);&:focus{outline:none;}"], props => {
  switch (props.size) {
    case 'sm':
      return css(["width:400px;"]);
    case 'md':
      return css(["width:600px;"]);
    case 'lg':
      return css(["width:752px;"]);
    case 'xl':
      return css(["width:968px;"]);
    default:
      return css(["width:400px;"]);
  }
}, get('colors.elevation.surface.default.[default]'), get('radii.1'), get('space.4'), contentShow);
const StyledAlertDialogHeader = styled(Dialog.Title).withConfig({
  displayName: "styles__StyledAlertDialogHeader",
  componentId: "sc-15mqdp2-2"
})(["margin:0;margin-bottom:", ";font-weight:", ";color:", ";font-size:", ";display:flex;gap:", ";"], get('space.3'), get('fontWeights.semibold'), get('colors.text.default'), get('fontSizes.6'), get('space.1'));
const StyledAlertDialogBody = styled(Dialog.Description).withConfig({
  displayName: "styles__StyledAlertDialogBody",
  componentId: "sc-15mqdp2-3"
})(["margin:", " 0;color:", ";font-size:", ";line-height:", ";overflow:auto;max-height:60vh;"], get('space.2'), get('colors.text.subtle'), get('fontSizes.3'), get('lineHeights.default'));
const StyledAlertDialogFooter = styled(Box).withConfig({
  displayName: "styles__StyledAlertDialogFooter",
  componentId: "sc-15mqdp2-4"
})(["display:flex;margin-top:", ";gap:", ";justify-content:flex-end;"], get('space.3'), get('space.2'));

export { StyledAlertDialogBody, StyledAlertDialogFooter, StyledAlertDialogHeader, StyledDialogBox, StyledDialogOverlay, contentShow };
