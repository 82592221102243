import { themeGet } from "@fuegokit/react";
import styled from "styled-components";

const Image = styled.img`
  max-width: 100%;
  box-sizing: content-box;
  background-color: ${themeGet("elevation.surface.default.[default]")};
`;

export default Image;
