import React, { useRef, useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import axios from "axios";
import { AkCheckIcon } from "@fuegokit/fuegoicons-react";
import {
  ThemeProvider,
  BaseStyles,
  Popover,
  Button,
  Box,
  AtlasTextfield,
  themeGet,
  sx,
  Text,
} from "@fuegokit/react";
import {
  AkCrossIcon,
  AkEditorCloseIcon,
  AkPreferencesIcon,
} from "@fuegokit/fuegoicons-react";
import Experience from "./Experience";
import Comment from "./Comment";

const FeedbackSurvey = ({
  hidden,
  visible,
  setVisible,
  onSubmit: submitHandler,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formRef = useRef();
  const experienceRef = useRef();

  const resetForm = () => {
    setFormSubmitted(false);
    formRef.current["feedback-survey-comment"].value = "";
    formRef.current["feedback-survey-experience"].value = "Neutral";
  };

  const onSubmit = () => {
    const form = new FormData(formRef.current);
    const data = {
      experience: form.get("feedback-survey-experience"),
      comment: form.get("feedback-survey-comment"),
      path: window.location.href,
      timestamp: new Date(),
    };

    if (submitHandler) {
      submitHandler(data);
    }

    setFormSubmitted(true);

    setTimeout(() => {
      setVisible(false);
    }, 1200);
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.code === "Escape") {
        setVisible(false);
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);
  }, [setVisible]);

  return (
    <>
      <StyledPopover hidden={hidden}>
        <Popover.Trigger asChild>
          <TriggerButton aria-label="This launches a modal form to give website feedback.">
            {/* <AkPreferencesIcon /> */}
            Feedback
          </TriggerButton>
        </Popover.Trigger>
        <Popover.Portal>
          <StyledPopoverContent className="PopoverContent" sideOffset={5}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                backgroundColor: "elevation.surface.default.[default]",
              }}
            >
              <Box sx={{ pl: "12px", pt: 3 }}>
                <Text
                  id="feedback-survey-label"
                  sx={{ fontSize: 3, fontWeight: "semibold" }}
                >
                  Was this page helpful?
                </Text>
              </Box>
              <form ref={formRef}>
                <Box sx={{ px: "8px", paddingBottom: "8px" }}>
                  {formSubmitted ? (
                    <SubmittedComment>
                      <AkCheckIcon />
                    </SubmittedComment>
                  ) : (
                    <Comment />
                  )}
                </Box>
                <ButtonRow>
                  <Box
                    sx={{
                      marginRight: "auto",
                      height: "100%",
                      display: "flex",
                    }}
                  >
                    {formSubmitted ? (
                      <SubmittedExperience />
                    ) : (
                      <Experience ref={experienceRef} />
                    )}
                  </Box>
                  <Box sx={{ px: 2, paddingTop: "3px" }}>
                    <StyledButton onClick={onSubmit} disabled={formSubmitted}>
                      {formSubmitted ? "Thanks!" : "Send"}
                      {/* {formSubmitted && <AkCheckIcon />} */}
                    </StyledButton>
                  </Box>
                </ButtonRow>
              </form>
            </Box>
            <StyledPopoverClose aria-label="Close">
              <AkEditorCloseIcon />
            </StyledPopoverClose>
            <StyledPopoverArrow />
          </StyledPopoverContent>
        </Popover.Portal>
      </StyledPopover>
    </>
  );
};

const StyledPopover = styled(Popover)`
  button,
  fieldset,
  input {
    all: unset;
  }
  ${sx}
`;
const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const StyledPopoverContent = styled(Popover.Content)`
  border-radius: ${themeGet("radii.2")};
  /* padding: ${themeGet("space.5")}; */
  width: 260px;
  background-color: ${themeGet("colors.elevation.surface.raised.[default]")};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  border: 1px solid ${themeGet("colors.border.default")};

  &:focus {
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
      0 0 0 2px ${themeGet("colors.border.focused")};
  }
  &[data-state="open"][data-side="top"] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state="open"][data-side="right"] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state="open"][data-side="bottom"] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state="open"][data-side="left"] {
    animation-name: ${slideRightAndFade};
  }
`;
const StyledPopoverArrow = styled(Popover.Arrow)`
  fill: ${themeGet("colors.elevation.surface.default.[default]")};
  stroke: ${themeGet("colors.border.default")};
`;
const StyledPopoverClose = styled(Popover.Close)`
  // reset
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;
  user-select: none;
  display: flex;
  align-items: center;
  color: inherit;
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet("colors.icon.subtle")};
  position: absolute;
  top: 8px;
  right: 8px;

  &:hover {
    background-color: ${themeGet("colors.background.neutral.subtle.hovered")};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${themeGet("colors.border.focused")};
  }
`;
const TriggerButton = styled(Button)`
  // reset
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;
  user-select: none;
  display: flex;
  align-items: center;
  color: inherit;

  border-radius: ${themeGet("radii.2")};
  border: 1px solid ${themeGet("colors.border.information")};
  padding: ${themeGet("space.2")} ${themeGet("space.2")};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet("colors.text.information")};
  background-color: ${themeGet(
    "colors.background.information.default.[default]"
  )};
  box-shadow: ${themeGet("shadows.shadow.overlay")};

  font-size: ${themeGet("fontSizes.3")};

  transition: background-color 0.1s ease-out,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);

  &:hover {
    background-color: ${themeGet(
      "colors.background.information.default.hovered"
    )};
  }
  &:active {
    background-color: ${themeGet(
      "colors.background.information.default.pressed"
    )};
    color: ${themeGet("colors.text.default")};
  }
  &:focus {
    border: 1px solid;
    border-color: ${themeGet("colors.border.focused")};
    box-shadow: 0 0 0 2px ${themeGet("colors.border.focused")};
  }
`;
const ButtonRow = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  background-color: ${themeGet("colors.elevation.surface.sunken")};
  border-top: 1px solid ${themeGet("colors.border.subtle")};
  padding-top: 8px;
`;
const EmojiWrapper = styled(Box)``;
const StyledButton = styled(Button)`
  // reset
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;
  user-select: none;
  display: flex;
  color: inherit;

  border-radius: ${themeGet("radii.2")};
  border: 1px solid ${themeGet("colors.border.brand")};
  padding: ${themeGet("space.2")} ${themeGet("space.2")};

  display: inline-flex;
  align-items: center;

  svg {
    margin-bottom: -4px;
  }
  line-height: 1;
  justify-content: center;
  color: ${themeGet("colors.text.inverse")};
  background-color: ${themeGet("colors.background.brand.bold.default")};

  font-size: ${themeGet("fontSizes.2")};

  transition: background-color 0.1s ease-out,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);

  &:hover {
    background-color: ${themeGet("colors.background.brand.bold.hovered")};
  }
  &:active {
    background-color: ${themeGet("colors.background.brand.bold.pressed")};
    color: ${themeGet("colors.text.inverse")};
  }
  &:focus {
    border: 1px solid;
    border-color: ${themeGet("colors.border.focused")};
    box-shadow: 0 0 0 2px ${themeGet("colors.border.focused")};
  }
`;
const SubmittedComment = styled(Box)`
  padding: 10px 12px;
  border-radius: ${themeGet("radii.2")};
  border: 1px solid transparent;
  transition: border-color 0.2s;
  background: ${themeGet("colors.background.input")};
  width: 100%;
  height: 100px;
  resize: none;
  font-size: ${themeGet("fontSizes.2")};
  font-family: ${themeGet("fonts.normal")};
  background-color: ${themeGet("colors.background.input.default")};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${themeGet("colors.icon.success")};
  }
`;
const SubmittedExperience = styled(Box)`
  display: flex;
  gap: ${themeGet("space.4")};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0;
  background: ${themeGet("colors.surface.sunken")};
  min-height: 44px;
`;

export default FeedbackSurvey;
