import React__default from 'react';
import { throttle } from '../utils/index.js';

/* eslint-disable @typescript-eslint/no-explicit-any */
const useOnScroll = (callback, throttleBy = 500) => {
  const isMounted = React__default.useRef(true);
  const handleScroll = React__default.useCallback(throttle((...args) => {
    if (isMounted.current) {
      callback(...args);
    }
  }, throttleBy), [callback, throttleBy]);
  React__default.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      isMounted.current = false;
    };
  }, [handleScroll]);
};

export { useOnScroll };
