import * as fuegokitComponents from "@fuegokit/react"
import * as fuegokitExperimentalComponents from "@fuegokit/react/experimental"
import * as fuegodocsComponents from "@fuegokit/gatsby-theme-fuegodocs"

import {
  AfChevronUpIcon,
  AfTransformIcon,
  AkWarningIcon,
  AfTimeIcon,
  AfUserIcon,
  AfLinkIcon,
  AfCheckIcon,
  AfCancelIcon,
  AfInfoIcon,
  AkDiscoverIcon,
  AfSearchIcon,
  AkLightbulbIcon,
  AkBitbucketSourceIcon,
  AkUserAvatarCircleIcon,
  AkPeopleIcon,
  AfChevronRightIcon,
  AkChevronDownIcon,
  AkOpenIcon,
  AkEditorSettingsIcon,
  AkQuestionsIcon,
  AkFeedbackIcon,
  AkErrorIcon,
} from "@fuegokit/fuegoicons-react"

export default function resolveScope() {
  return {
    ...fuegokitComponents,
    ...fuegodocsComponents,
    ...fuegokitExperimentalComponents,
    AfChevronUpIcon,
    AfTransformIcon,
    AkWarningIcon,
    AfTimeIcon,
    AfUserIcon,
    AfLinkIcon,
    AfCheckIcon,
    AfCancelIcon,
    AfInfoIcon,
    AkDiscoverIcon,
    AfSearchIcon,
    AkErrorIcon,
    AkLightbulbIcon,
    AkBitbucketSourceIcon,
    AkUserAvatarCircleIcon,
    AkPeopleIcon,
    AfChevronRightIcon,
    AkChevronDownIcon,
    AkEditorSettingsIcon,
    AkQuestionsIcon,
    AkOpenIcon,
    AkFeedbackIcon,
  }
}
