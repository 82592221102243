import React from "react";
import styled from "styled-components";
import { Box, Text, themeGet } from "@fuegokit/react";
import { AkShortcutIcon } from "@fuegokit/fuegoicons-react";

import { Link as MarkdownLink } from "../Link";

export const CodePath = ({ src, path, code, children }) => {
  if (!path) return null;
  return (
    <Box>
      <Box
        as="span"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "18px",
          p: 3,
          borderBottom: "0.25px solid",
          borderColor: "border.default",
        }}
      >
        <Text as="p" sx={{ color: "#FFFFFF" }}>
          {path}
        </Text>

        {src ? (
          <StyledMarkdownLink
            href={src}
            target="_blank"
            rel="noopener noreferrer"
            title="View source"
          >
            <AkShortcutIcon size={18} />
          </StyledMarkdownLink>
        ) : null}
      </Box>
    </Box>
  );
};
const StyledMarkdownLink = styled(MarkdownLink)`
  color: #ffffff;
  &:visited {
    text-decoration: none;
    color: #ffffff;
  }
  &:hover {
    color: ${themeGet("colors.link.default")};
  }
  &:active {
    color: ${themeGet("colors.link.pressed")};
  }
`;
