// ------------------------------

// @TODO
// until BoxWithIndicator supports top and bottom placement, we'll just be using 'left' and 'right'

const STATUSES = {
  inprogress: 'in progress',
  default: 'default',
  moved: 'moved',
  new: 'new',
  removed: 'removed',
  success: 'success',
  done: 'done',
  todo: 'todo',
  any: 'any',
  locked: 'locked'
};
/**
 * Component props
 */

// ------------------------------
/** A combobox for rendering a filtered list of items a user can select from */

// ------------------------------
/** Renders a box with a caret indicator positioned vertically. Maintains awareness of the y-axis center of a given element in the corresponding pane of the rule builder layout */

// ------------------------------
/** A component that wraps children and calculates the vertical center position of its boundingClientRect using the useElementCenterY hook. */

// ------------------------------

// ------------------------------
/** Renders a containing element that describes the details contained with a rule, and consists of a container and a caret that follows a rule as it progresses through a workflow */

// ------------------------------
// @TODO: RuleFilterType
// ------------------------------
/** Provides a visual indicator that a user has added a script or filter to an item. Use with a RuleFilter. */

// ------------------------------
/** * A rule item used to describe an item in a workflow and provides actions a user can take. */

// ------------------------------
/** Step Connector */

// ------------------------------
/** Displays a Rule Builder Step Label, which describes a condition. */

// ------------------------------
/** Describes a transition status */

/** Describes a transition status */

/** Describes a transition status */

// -------------------------------
/** Scrollable list, with items in an overflow container */

/**
 * RuleBuilderLayout props
 */

// @TODO: ScrollableListItemType
// -------------------------------
// Exports

//

export { STATUSES };
