/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint doesn't know about TextArea labeling
import React, { useState } from "react";
import styled from "styled-components";
import { Box, themeGet } from "@fuegokit/react";

const Comment = () => {
  const [focused, setFocused] = useState(false);
  const handleBlur = (e) => {
    e.target.scrollTop = 0;
    setFocused(false);
  };

  return (
    <Wrapper>
      <TextArea
        placeholder="Your feedback (optional):"
        onBlur={handleBlur}
        onFocus={() => setFocused(true)}
        rows={5}
        name="feedback-survey-comment"
        id="feedback-survey-comment"
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)``;

const TextArea = styled.textarea`
  padding: 10px 12px;
  border-radius: ${themeGet("radii.2")};
  border: 1px solid ${themeGet("colors.border.input")};
  transition: border-color 0.2s;
  background: ${themeGet("colors.background.input")};
  width: 100%;
  height: 100px;
  resize: none;
  font-size: ${themeGet("fontSizes.2")};
  font-family: ${themeGet("fonts.normal")};
  background-color: ${themeGet("colors.background.input.default")};
  &:focus {
    background-color: ${themeGet("colors.surface.default")};
    border-color: ${themeGet("colors.border.focused")};
  }
  &::placeholder {
    font-family: ${themeGet("fonts.normal")};
    margin-bottom: -2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default Comment;
