/**
 * @fuegokit/tokens 0.24.1
 * Do not edit directly
 * Generated on Fri, 13 Dec 2024 14:28:54 GMT
 */
export default {
  scale: {
    gray: {
      "100": "#d8d8d8",
      "200": "#cccccc",
      "300": "#bdbdbd",
      "400": "#adadad",
      "500": "#969696",
      "600": "#7d7d7d",
      "700": "#636363",
      "800": "#4a4a4a",
      "900": "#303030",
      "000": "#f2f2f2",
    },
    neutral: {
      "100": "#181b34",
      "200": "#292f4c",
      "250": "#2b3150",
      "300": "#30324e",
      "350": "#343755",
      "400": "#3c3f59",
      "500": "#4b4e69",
      "600": "#797e93",
      "700": "#9699a6",
      "800": "#c3c6d4",
      "900": "#caccd9",
      "1000": "#d5d8df",
      "1100": "#ffffff",
      "000": "#323338",
    },
    neutralAlpha: {
      "100": "#ffffff0a",
      "200": "#ffffff0f",
      "250": "#ffffff12",
      "300": "#ffffff14",
      "350": "#ffffff1a",
      "400": "#ffffff26",
      "500": "#ffffff33",
      "600": "#ffffff4d",
      "700": "#ffffff99",
      "800": "#ffffffb3",
      "900": "#ffffffcc",
      "000": "#ffffff00",
    },
    blue: {
      "100": "#aed4fc",
      "200": "#67aef9",
      "300": "#1e89fa",
      "400": "#0073ea",
      "500": "#1f76db",
      "600": "#1f76c2",
      "700": "#0060b9",
      "800": "#003d75",
      "900": "#00284d",
      "000": "#cce5ff",
    },
    teal: {
      "100": "#c1ebf1",
      "200": "#8bdae4",
      "300": "#6acfdc",
      "400": "#38bfd1",
      "500": "#2696a6",
      "600": "#1d717c",
      "700": "#175a63",
      "800": "#12484f",
      "900": "#0e393e",
      "000": "#d6f2f5",
    },
    purple: {
      "100": "#e7d5f6",
      "200": "#d0aeed",
      "300": "#b781e4",
      "400": "#a25ddc",
      "500": "#904acf",
      "600": "#8050ab",
      "700": "#68418b",
      "800": "#4e3168",
      "900": "#342145",
      "000": "#f3eafa",
    },
    green: {
      "100": "#bbdbc9",
      "200": "#b5cec0",
      "300": "#89bda0",
      "400": "#33995f",
      "500": "#00854d",
      "600": "#007a41",
      "700": "#007038",
      "800": "#006130",
      "900": "#004d26",
      "000": "#d6ebdf",
    },
    yellow: {
      "100": "#ffe580",
      "200": "#fbd850",
      "300": "#ffd329",
      "400": "#f5c300",
      "500": "#e5b700",
      "600": "#dbaf00",
      "700": "#c29e11",
      "800": "#755f0a",
      "900": "#503e02",
      "000": "#ffefb2",
    },
    red: {
      "100": "#f4c3cb",
      "200": "#ecb7bf",
      "300": "#e06c7d",
      "400": "#dd5569",
      "500": "#d83a52",
      "600": "#c53a4c",
      "700": "#b63546",
      "800": "#76232e",
      "900": "#4b161d",
      "000": "#fbe9ec",
    },
    orange: {
      "100": "#fceba1",
      "200": "#f2d973",
      "300": "#ffcb00",
      "400": "#f5c238",
      "500": "#eaaa15",
      "600": "#c58f12",
      "700": "#aa7909",
      "800": "#7c5803",
      "900": "#5a3f02",
      "000": "#fdf4ce",
    },
    magenta: {
      "100": "#ffc2e0",
      "200": "#ff8ac4",
      "300": "#ff5cad",
      "400": "#ff3399",
      "500": "#ff158a",
      "600": "#e1197e",
      "700": "#c21e71",
      "800": "#610f39",
      "900": "#4b0c2c",
      "000": "#ffe5f2",
    },
    lime: {
      "100": "#e0f1bb",
      "200": "#cde992",
      "300": "#b9e066",
      "400": "#a8d841",
      "500": "#9cd326",
      "600": "#89ba21",
      "700": "#7ca32b",
      "800": "#4d651b",
      "900": "#2e3d10",
      "000": "#ecf6d5",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#181b34",
        hovered: "#181b34",
        pressed: "#292f4c",
      },
      sunken: "#30324e",
      raised: {
        default: "#292f4c",
        hovered: "#2b3150",
        pressed: "#30324e",
      },
      overlay: {
        default: "#292f4c",
        hovered: "#30324e",
        pressed: "#30324e",
      },
    },
    shadow: {
      raised: {
        x: 0,
        y: 1,
        blur: 2,
        spread: 0,
        color: "#090b1980",
        type: "dropShadow",
      },
      overlay: {
        x: 0,
        y: 4,
        blur: 8,
        spread: 0,
        color: "#090b1980",
        type: "dropShadow",
      },
      overflow: {
        default: {
          x: 0,
          y: 6,
          blur: 20,
          spread: 0,
          color: "#090b1980",
          type: "dropShadow",
        },
        spread: "#ffffff14",
        perimeter: "#ffffff0f",
      },
    },
  },
  border: {
    default: "#4b4e69",
    bold: "#797e93",
    selected: "#0073ea",
    focused: "#67aef9",
    subtle: "#797e93",
    input: "#4b4e69",
    inverse: "#323338",
    disabled: "#ffffff0f",
    brand: "#67aef9",
    danger: "#dd5569",
    warning: "#f5c238",
    success: "#33995f",
    discovery: "#d0aeed",
    information: "#0073ea",
    accent: {
      blue: "#0073ea",
      red: "#dd5569",
      orange: "#f5c238",
      yellow: "#ffd329",
      green: "#33995f",
      purple: "#d0aeed",
      teal: "#38bfd1",
      magenta: "#ff8ac4",
      gray: "#797e93",
    },
    table: {
      default: "#ffffff14",
      container: "#4b4e69",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#00284d",
        subtler: "#003d75",
        subtle: "#0060b9",
        bolder: "#1e89fa",
      },
      red: {
        subtlest: "#4b161d",
        subtler: "#76232e",
        subtle: "#b63546",
        bolder: "#e06c7d",
      },
      orange: {
        subtlest: "#5a3f02",
        subtler: "#7c5803",
        subtle: "#aa7909",
        bolder: "#f5c238",
      },
      yellow: {
        subtlest: "#503e02",
        subtler: "#755f0a",
        subtle: "#c29e11",
        bolder: "#ffd329",
      },
      green: {
        subtlest: "#004d26",
        subtler: "#006130",
        subtle: "#007038",
        bolder: "#89bda0",
      },
      purple: {
        subtlest: "#342145",
        subtler: "#4e3168",
        subtle: "#68418b",
        bolder: "#b781e4",
      },
      teal: {
        subtlest: "#0e393e",
        subtler: "#12484f",
        subtle: "#175a63",
        bolder: "#6acfdc",
      },
      magenta: {
        subtlest: "#4b0c2c",
        subtler: "#610f39",
        subtle: "#c21e71",
        bolder: "#ff5cad",
      },
      gray: {
        subtlest: {
          default: "#30324e",
          hovered: "#292f4c",
          pressed: "#181b34",
        },
        subtler: {
          default: "#3c3f59",
          hovered: "#30324e",
          pressed: "#292f4c",
        },
        subtle: {
          default: "#4b4e69",
          hovered: "#3c3f59",
          pressed: "#30324e",
        },
        bolder: {
          default: "#9699a6",
          hovered: "#797e93",
          pressed: "#4b4e69",
        },
      },
      lime: {
        subtlest: "#2e3d10",
        subtler: "#4d651b",
        subtle: "#7ca32b",
        bolder: "#b9e066",
      },
    },
    input: {
      default: "#181b34",
      hovered: "#292f4c",
      pressed: "#181b34",
    },
    inverse: {
      subtle: {
        default: "#ffffff29",
        hovered: "#ffffff3d",
        pressed: "#ffffff52",
      },
    },
    brand: {
      subtlest: {
        default: "#00284d",
        hovered: "#003d75",
        pressed: "#0060b9",
      },
      bold: {
        default: "#0073ea",
        hovered: "#1e89fa",
        pressed: "#67aef9",
      },
      boldest: {
        default: "#cce5ff",
        hovered: "#aed4fc",
        pressed: "#67aef9",
      },
    },
    neutral: {
      default: {
        "[default]": "#ffffff0f",
        hovered: "#ffffff14",
        pressed: "#ffffff26",
      },
      subtle: {
        default: "#ffffff00",
        hovered: "#ffffff0f",
        pressed: "#ffffff14",
      },
      bold: {
        default: "#c3c6d4",
        hovered: "#caccd9",
        pressed: "#d5d8df",
      },
    },
    selected: {
      default: {
        "[default]": "#00284d",
        hovered: "#003d75",
        pressed: "#003d75",
      },
      bold: {
        default: "#1e89fa",
        hovered: "#67aef9",
        pressed: "#aed4fc",
      },
    },
    disabled: "#3c3f59",
    information: {
      default: {
        "[default]": "#00284d",
        hovered: "#003d75",
        pressed: "#0060b9",
      },
      bold: {
        default: "#1e89fa",
        hovered: "#67aef9",
        pressed: "#aed4fc",
      },
    },
    danger: {
      default: {
        "[default]": "#76232e",
        hovered: "#b63546",
        pressed: "#c53a4c",
      },
      bold: {
        default: "#e06c7d",
        hovered: "#ecb7bf",
        pressed: "#f4c3cb",
      },
    },
    success: {
      default: {
        "[default]": "#004d26",
        hovered: "#006130",
        pressed: "#007038",
      },
      bold: {
        default: "#89bda0",
        hovered: "#b5cec0",
        pressed: "#bbdbc9",
      },
    },
    discovery: {
      default: {
        "[default]": "#342145",
        hovered: "#4e3168",
        pressed: "#68418b",
      },
      bold: {
        default: "#b781e4",
        hovered: "#d0aeed",
        pressed: "#e7d5f6",
      },
    },
    warning: {
      default: {
        "[default]": "#503e02",
        hovered: "#755f0a",
        pressed: "#c29e11",
      },
      bold: {
        default: "#ffd329",
        hovered: "#fbd850",
        pressed: "#ffe580",
      },
    },
  },
  blanket: {
    default: "#ffffff26",
    selected: "#1f76c21a",
    danger: "#b635461a",
  },
  text: {
    default: "#d5d8df",
    subtle: "#9699a6",
    subtlest: "#c3c6d4",
    disabled: "#797e93",
    selected: "#67aef9",
    inverse: "#323338",
    success: "#89bda0",
    danger: "#ecb7bf",
    information: "#67aef9",
    warning: {
      default: "#f5c300",
      inverse: "#323338",
    },
    discovery: "#d0aeed",
    brand: "#67aef9",
    accent: {
      blue: {
        default: "#67aef9",
        bolder: "#cce5ff",
      },
      red: {
        default: "#ecb7bf",
        bolder: "#fbe9ec",
      },
      orange: {
        default: "#ffcb00",
        bolder: "#fdf4ce",
      },
      yellow: {
        default: "#fbd850",
        bolder: "#ffefb2",
      },
      green: {
        default: "#b5cec0",
        bolder: "#d6ebdf",
      },
      purple: {
        default: "#d0aeed",
        bolder: "#f3eafa",
      },
      teal: {
        default: "#8bdae4",
        bolder: "#d6f2f5",
      },
      magenta: {
        default: "#ff8ac4",
        bolder: "#ffe5f2",
      },
      gray: {
        default: "#c3c6d4",
        bolder: "#d5d8df",
      },
      lime: {
        default: "#b9e066",
        bolder: "#e0f1bb",
      },
    },
  },
  link: {
    default: "#aed4fc",
    hovered: "#aed4fc",
    pressed: "#cce5ff",
    visited: "#aed4fc",
  },
  icon: {
    accent: {
      blue: "#67aef9",
      red: "#dd5569",
      orange: "#eaaa15",
      yellow: "#e5b700",
      green: "#89bda0",
      purple: "#b781e4",
      teal: "#38bfd1",
      magenta: "#e1197e",
      gray: "#797e93",
      lime: "#89ba21",
    },
    default: "#c3c6d4",
    subtle: "#9699a6",
    inverse: "#323338",
    disabled: "#4b4e69",
    brand: "#67aef9",
    selected: "#67aef9",
    danger: "#dd5569",
    success: "#89bda0",
    discovery: "#b781e4",
    information: "#67aef9",
    warning: {
      default: "#eaaa15",
      inverse: "#323338",
    },
  },
  interaction: {
    hovered: "#ffffff14",
    pressed: "#ffffff26",
  },
  skeleton: {
    default: "#ffffff0f",
    subtle: "#ffffff0a",
  },
  opacity: {
    disabled: 0.38,
    loading: 0.7,
  },
  chart: {
    brand: {
      default: "#0073ea",
      hovered: "#1e89fa",
    },
    neutral: {
      default: "#797e93",
      hovered: "#9699a6",
    },
    success: {
      default: {
        "[default]": "#33995f",
        hovered: "#89bda0",
      },
      bold: {
        default: "#b5cec0",
        hovered: "#bbdbc9",
      },
    },
    danger: {
      default: {
        "[default]": "#d83a52",
        hovered: "#dd5569",
      },
      bold: {
        default: "#ecb7bf",
        hovered: "#f4c3cb",
      },
    },
    warning: {
      default: {
        "[default]": "#eaaa15",
        hovered: "#f5c238",
      },
      bold: {
        default: "#ffcb00",
        hovered: "#f2d973",
      },
    },
    information: {
      default: {
        "[default]": "#1f76db",
        hovered: "#1e89fa",
      },
      bold: {
        default: "#67aef9",
        hovered: "#aed4fc",
      },
    },
    discovery: {
      default: {
        "[default]": "#904acf",
        hovered: "#a25ddc",
      },
      bold: {
        default: "#d0aeed",
        hovered: "#e7d5f6",
      },
    },
    categorical: {
      "1": {
        default: "#2696a6",
        hovered: "#38bfd1",
      },
      "2": {
        default: "#d0aeed",
        hovered: "#e7d5f6",
      },
      "3": {
        default: "#eaaa15",
        hovered: "#f5c238",
      },
      "4": {
        default: "#ff8ac4",
        hovered: "#ffc2e0",
      },
      "5": {
        default: "#aed4fc",
        hovered: "#cce5ff",
      },
      "6": {
        default: "#904acf",
        hovered: "#a25ddc",
      },
      "7": {
        default: "#ffc2e0",
        hovered: "#ffe5f2",
      },
      "8": {
        default: "#f2d973",
        hovered: "#fceba1",
      },
    },
    blue: {
      bold: {
        default: "#1f76db",
        hovered: "#0073ea",
      },
      bolder: {
        default: "#0073ea",
        hovered: "#1e89fa",
      },
      boldest: {
        default: "#67aef9",
        hovered: "#aed4fc",
      },
    },
    red: {
      bold: {
        default: "#d83a52",
        hovered: "#dd5569",
      },
      bolder: {
        default: "#dd5569",
        hovered: "#e06c7d",
      },
      boldest: {
        default: "#ecb7bf",
        hovered: "#f4c3cb",
      },
    },
    orange: {
      bold: {
        default: "#f5c238",
        hovered: "#ffcb00",
      },
      bolder: {
        default: "#ffcb00",
        hovered: "#f2d973",
      },
      boldest: {
        default: "#f2d973",
        hovered: "#fceba1",
      },
    },
    yellow: {
      bold: {
        default: "#f5c300",
        hovered: "#ffd329",
      },
      bolder: {
        default: "#ffd329",
        hovered: "#fbd850",
      },
      boldest: {
        default: "#fbd850",
        hovered: "#ffe580",
      },
    },
    green: {
      bold: {
        default: "#33995f",
        hovered: "#89bda0",
      },
      bolder: {
        default: "#89bda0",
        hovered: "#b5cec0",
      },
      boldest: {
        default: "#b5cec0",
        hovered: "#bbdbc9",
      },
    },
    teal: {
      bold: {
        default: "#38bfd1",
        hovered: "#6acfdc",
      },
      bolder: {
        default: "#6acfdc",
        hovered: "#8bdae4",
      },
      boldest: {
        default: "#8bdae4",
        hovered: "#c1ebf1",
      },
    },
    purple: {
      bold: {
        default: "#904acf",
        hovered: "#a25ddc",
      },
      bolder: {
        default: "#a25ddc",
        hovered: "#b781e4",
      },
      boldest: {
        default: "#d0aeed",
        hovered: "#e7d5f6",
      },
    },
    magenta: {
      bold: {
        default: "#ff158a",
        hovered: "#ff3399",
      },
      bolder: {
        default: "#ff3399",
        hovered: "#ff5cad",
      },
      boldest: {
        default: "#ff8ac4",
        hovered: "#ffc2e0",
      },
    },
    gray: {
      bold: {
        default: "#4b4e69",
        hovered: "#797e93",
      },
      bolder: {
        default: "#797e93",
        hovered: "#9699a6",
      },
      boldest: {
        default: "#9699a6",
        hovered: "#c3c6d4",
      },
    },
    lime: {
      bold: {
        default: "#9cd326",
        hovered: "#b9e066",
      },
      bolder: {
        default: "#b9e066",
        hovered: "#cde992",
      },
      boldest: {
        default: "#cde992",
        hovered: "#e0f1bb",
      },
    },
  },
};
