import React from "react";
import styled from "styled-components";
import { Box, Link, Text, Card, Logo, themeGet } from "@fuegokit/react";
import { Link as GatsbyLink } from "gatsby";
import {
  AfBoxExternalIcon,
  AfDownloadIcon,
  AfCancelIcon,
  AfArrowRightIcon,
} from "@fuegokit/fuegoicons-react";

const ResourceCard = ({
  children,
  raised = false,
  disabled = false,
  href,
  subtitle,
  imageIconLink,
  title,
  actionIcon,
  docsTheme = "default",
  ...rest
}) => {
  let isLink;
  if (href !== undefined && !rest.download) {
    isLink = href.charAt(0) === "/";
  }
  return (
    <StyledSurfaceCard
      as="a"
      href={href}
      disabled={disabled}
      raised={raised}
      docsTheme={docsTheme}
      {...rest}
    >
      {subtitle && (
        <Subtitle as="h5" disabled={disabled} docsTheme={docsTheme}>
          {subtitle}
        </Subtitle>
      )}
      <TitleWrapper>
        {imageIconLink && (
          <img width="20" alt={title + " logo"} src={imageIconLink} />
        )}
        {title && (
          <Title as="h4" disabled={disabled}>
            {title}
          </Title>
        )}
      </TitleWrapper>

      <IconImgWrapper>{children}</IconImgWrapper>
      {actionIcon && (
        <ActionIconWrapper disabled={disabled}>
          <ActionIcon icon={actionIcon} />
        </ActionIconWrapper>
      )}
    </StyledSurfaceCard>
  );
};

const StyledSurfaceCard = styled(Box)`
  padding: ${themeGet("space.3")} ${themeGet("space.3")};
  position: relative;
  height: 100%;
  min-height: 164px;
  flex: 0 1 280px;
  display: grid;
  grid-template-rows: auto auto 1fr auto 0.5fr;
  gap: ${themeGet("space.2")};
  border-radius: ${(p) =>
    (p.docsTheme = "brand"
      ? themeGet("radii.3")
      : (p.docsTheme = "default" ? themeGet("radii.1") : themeGet("radii.1")))};
  border: 1px solid ${themeGet("colors.border.default")};
  text-decoration: none;
  box-shadow: ${(p) =>
    p.raised === true ? themeGet("shadows.elevation.shadow.raised") : ""};
  transition: 150ms;

  &:hover {
    background-color: ${(p) =>
      p.disabled ? "" : themeGet("colors.background.neutral.subtle.hovered")};
    box-shadow: ${(p) =>
      p.raised === true ? themeGet("shadows.elevation.shadow.overlay") : ""};
    cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  }

  &:focus-within {
    background-color: ${themeGet("colors.background.neutral.subtle.hovered")};
    box-shadow: ${themeGet("shadows.elevation.shadow.overlay")};
    text-decoration: none;
  }

  &:active {
    background-color: ${themeGet("colors.background.neutral.subtle.pressed")};
    box-shadow: ${themeGet("shadows.elevation.shadow.overlay")};
    text-decoration: none;
  }
`;

const Subtitle = styled(Text)`
  font-size: ${themeGet("fontSizes.3")};
  margin-bottom: ${themeGet("space.1")};
  letter-spacing: -0.003em;
  font-weight: 400;
  text-decoration: none;

  color: ${(p) =>
    p.disabled
      ? themeGet("colors.text.subtlest")
      : themeGet("colors.text.subtle")};
`;

const TitleWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${themeGet("space.2")};
`;
const Title = styled(Text)`
  font-size: ${themeGet("fontSizes.6")};
  line-height: ${themeGet("lineHeights.condensed")};
  color: ${(p) =>
    p.disabled
      ? themeGet("colors.text.subtlest")
      : themeGet("colors.text.default")};
  font-weight: ${themeGet("fontWeights.semibold")};
`;

const MainLink = styled(Link)`
  color: ${themeGet("colors.text.link")};
  display: block;
  z-index: 1;
  padding: 4px;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
  }
  &:hover {
    color: ${themeGet("colors.link.default")};
  }
  &:focus {
    outline: 2px solid ${themeGet("colors.border.focused")};
    border-radius: ${themeGet("radii.1")};
    color: ${themeGet("colors.link.default")};
  }
  grid-row: 5 / 6;
  margin-top: auto;
`;

const ActionIconWrapper = styled(Box)`
  position: absolute;
  bottom: ${themeGet("space.2")};
  right: ${themeGet("space.3")};
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) =>
    p.disabled
      ? themeGet("colors.icon.subtlest")
      : themeGet("colors.icon.default")};
`;

const ActionIcon = ({ icon }) => {
  let Component;

  if (icon === "launch") {
    Component = AfBoxExternalIcon;
  } else if (icon === "download") {
    Component = AfDownloadIcon;
  } else if (icon === "link") {
    Component = AfArrowRightIcon;
  } else if (icon === "disabled") {
    Component = AfCancelIcon;
  } else {
    throw Error(
      `Unrecognized icon: ${icon}. Please use an accepted action icon with Resource Card.`
    );
  }

  return <Component icon={icon} fill="none" aria-label="open resource" />;
};

const IconImgWrapper = styled(Box)`
  position: absolute;
  bottom: ${themeGet("space.2")};
  left: ${themeGet("space.3")};
  width: 44px;
  height: 44px;
  padding: 6px;
  border-radius: ${themeGet("radii.3")};
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: fit-content;
  color: ${themeGet("colors.icon.default")};
`;

export default ResourceCard;
