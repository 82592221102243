import styled from 'styled-components';
import sx from '../../sx.js';
import Checkbox from '../Checkbox/Checkbox.js';

// literally undo all Checkbox styles, because, well, Atlassian
const UnstyledCheckbox = styled(Checkbox).withConfig({
  displayName: "UnstyledCheckbox",
  componentId: "sc-1nkphdq-0"
})(["appearance:none;border-color:unset;border-style:unset;border-width:unset;background-color:unset;cursor:unset;display:unset;height:unset;margin:unset;margin-top:unset;place-content:unset;position:unset;width:unset;&:disabled{cursor:unset;background-color:unset;border-color:unset;}border-radius:unset;transition:unset;&::before{width:unset;height:unset;visibility:unset;content:unset;background-color:unset;transition:unset;clip-path:unset;mask-image:unset;mask-size:unset;mask-repeat:unset;mask-position:unset;@media screen and (prefers-reduced-motion:no-preference){animation:unset;}}&:checked,&:indeterminate{background-color:unset;border-color:unset;&::before{@media screen and (prefers-reduced-motion:no-preference){animation:unset;}}}&:checked{transition:unset;&:before{visibility:visible;transition:unset;}&:disabled{cursor:not-allowed;background-color:unset;border-color:unset;opacity:unset;&:before{background-color:unset;}}@media (forced-colors:active){background-color:unset;border-color:unset;}}&:indeterminate{background:unset;&::before{mask-image:unset;visibility:unset;}}&:focus:not(:disabled){box-shadow:unset;outline:unset;outline-offset:unset;&:not(:focus-visible){outline:unset;}}&:focus-visible:not(:disabled){box-shadow:n unset;outline:unset;outline-offset:unset;}", ""], sx);

export { UnstyledCheckbox };
