import styled, { keyframes } from 'styled-components';
import sx from '../../sx.js';

const SVG = styled.svg.withConfig({
  displayName: "styles__SVG",
  componentId: "sc-r3e7vi-0"
})(["fill:", ";", ""], p => p.color ? p.color : 'inherit', sx);
const StopOne = styled.stop.withConfig({
  displayName: "styles__StopOne",
  componentId: "sc-r3e7vi-1"
})(["stop-color:var(--stopColor1);"]);
const StopTwo = styled.stop.withConfig({
  displayName: "styles__StopTwo",
  componentId: "sc-r3e7vi-2"
})(["stop-color:var(--stopColor2);"]);
const Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-r3e7vi-3"
})([""]);
const markLine = keyframes(["0%,100%{stroke-dasharray:500;stroke-dashoffset:-850;}50%{stroke-dasharray:500;stroke-dashoffset:-450;}"]);
const markColors = keyframes(["0%,100%{stroke:#f96c2c;}33%{stroke:#e54074;}66%{stroke:#a044b9;}"]);
const HiddenSVG = styled.svg.withConfig({
  displayName: "styles__HiddenSVG",
  componentId: "sc-r3e7vi-4"
})(["visibility:hidden;position:absolute;left:-9999px;overflow:hidden;"]);
const MarkForeground = styled.path.withConfig({
  displayName: "styles__MarkForeground",
  componentId: "sc-r3e7vi-5"
})(["stroke-dasharray:500;stroke-dashoffset:-850;"]);
const MarkBackground = styled.path.withConfig({
  displayName: "styles__MarkBackground",
  componentId: "sc-r3e7vi-6"
})([""]);
const AnimatedSVG = styled.svg.withConfig({
  displayName: "styles__AnimatedSVG",
  componentId: "sc-r3e7vi-7"
})(["&:hover{cursor:pointer;", "{animation-name:", ";animation-duration:1s;animation-fill-mode:forwards;}", "{animation-name:", ";animation-duration:1s;animation-fill-mode:forwards;}}"], MarkBackground, markColors, MarkForeground, markLine);

export { AnimatedSVG, HiddenSVG, MarkBackground, MarkForeground, SVG, StopOne, StopTwo, Wrapper, markColors, markLine };
