import React from "react";
import styled from "styled-components";
import { Link, Text, themeGet } from "@fuegokit/react";

const StorybookLink = ({ href }) => {
  return (
    <StyledLink
      subtle
      href={href}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 3,
        borderRadius: 2,
      }}
    >
      <StorybookIcon />
      <Text sx={{ ml: 1, display: "inline-block" }}>Storybook</Text>
    </StyledLink>
  );
};

const StorybookIcon = () => {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 24 24"
      width={16}
      height={16}
      fill="currentColor"
      style={{ verticalAlign: "text-bottom" }}
    >
      <path d="M16.71.243l-.12 2.71a.18.18 0 00.29.15l1.06-.8.9.7a.18.18 0 00.28-.14l-.1-2.76 1.33-.1a1.2 1.2 0 011.279 1.2v21.596a1.2 1.2 0 01-1.26 1.2l-16.096-.72a1.2 1.2 0 01-1.15-1.16l-.75-19.797a1.2 1.2 0 011.13-1.27L16.7.222zM13.64 9.3c0 .47 3.16.24 3.59-.08 0-3.2-1.72-4.89-4.859-4.89-3.15 0-4.899 1.72-4.899 4.29 0 4.45 5.999 4.53 5.999 6.959 0 .7-.32 1.1-1.05 1.1-.96 0-1.35-.49-1.3-2.16 0-.36-3.649-.48-3.769 0-.27 4.03 2.23 5.2 5.099 5.2 2.79 0 4.969-1.49 4.969-4.18 0-4.77-6.099-4.64-6.099-6.999 0-.97.72-1.1 1.13-1.1.45 0 1.25.07 1.19 1.87z" />
    </svg>
  );
};

const StyledLink = styled(Link)`
  border: 1px solid;
  border-color: ${themeGet("colors.border.subtle")};
  &.active {
    font-weight: ${themeGet("fontWeights.semibold")};
    color: ${themeGet("colors.link.default")};
  }
  &:hover {
    text-decoration: none;
    background-color: ${themeGet("colors.background.neutral.default.hovered")};
    color: ${themeGet("colors.text.default")};
  }
`;

export default StorybookLink;
