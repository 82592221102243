import { Box, Text, themeGet } from "@fuegokit/react";
import React from "react";
import { sentenceCase } from "sentence-case";
import useSiteMetadata from "../../hooks/use-site-metadata";

function SearchResults({ results, getItemProps, highlightedIndex }) {
  const siteMetadata = useSiteMetadata();
  if (results.length === 0) {
    return (
      <Box sx={{ p: 3, fontSize: 4, color: "text.subtle", width: "100%" }}>
        No results
      </Box>
    );
  }

  return results.map((item, index) => {
    // the worker will occasionally not fire and we wind up with an un-destructured 'item' object
    const title = item.title || (item.item && item.item.title);

    return (
      <Box
        key={item.path}
        {...getItemProps({
          item,
          flexDirection: "column",
          flex: "0 0 auto",
          px: 2,
          py: 2,
          color: "text.default",
          fontSize: 4,
          style: { cursor: "pointer" },
          borderRadius: 2,
        })}
        sx={{
          display: "flex",
          bg:
            highlightedIndex === index
              ? "background.neutral.default.hovered"
              : "transparent",
        }}
      >
        <Text sx={{ fontSize: 1, color: "text.subtle" }}>
          {item.path
            ? getBreadcrumbs(siteMetadata.siteName, item.path).join(" / ")
            : getBreadcrumbs(siteMetadata.siteName, item.item.path).join(" / ")}
        </Text>
        {title}
      </Box>
    );
  });
}

function getBreadcrumbs(siteTitle, path) {
  return [
    siteTitle,
    ...path
      .split("/")
      .filter(Boolean)
      // The last title will be displayed separately, so we exclude it
      // from the breadcrumbs to avoid displaying it twice.
      .slice(0, -1)
      .map(sentenceCase),
  ];
}

export default SearchResults;
