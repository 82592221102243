import styled from 'styled-components';
import { themeGet } from '../../utils/theme-get.js';
import sx from '../../sx.js';

const StyledCode = styled.code.withConfig({
  displayName: "styles__StyledCode",
  componentId: "sc-1wbeaqc-0"
})(["font-size:0.875em;font-weight:normal;background-color:", ";color:", ";border-style:none;border-radius:3px;display:inline;padding:2px 0.5ch;white-space:pre-wrap;overflow-wrap:break-word;overflow:auto;-webkit-box-decoration-break:clone;", ""], themeGet('colors.background.neutral.default.[default]'), themeGet('colors.text.default'), sx);

export { StyledCode };
