import React from "react";
import styled from "styled-components";
import { themeGet, sx } from "@fuegokit/react";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";
import useSiteMetadata from "../../hooks/use-site-metadata";

// export const Link = styled.a`
//   /* text-decoration: underline;
//   text-underline-offset: 10%; */
//   text-decoration: none;
//   color: ${themeGet("colors.link.default")};
//   &:visited {
//     text-decoration: none;
//     color: ${themeGet("colors.link.default")};
//   }
// `;

const Link = ({
  href,
  target,
  rel,
  getProps,
  underline,
  activeClassName,
  partiallyActive,
  sx,
  ...delegated
}) => {
  // There are three types of links:
  // - Internal links to other pages within the same app
  // - External links, to other domains
  // - Hash links (#introduction), for the same page.

  // console.log("this is what we get in the link component: ", href);
  const { site } = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
    }
  `);
  // console.log("this is what we get from graphQL: ", site);

  let linkType;

  if (href.match(/^#/)) {
    linkType = "hash";
  } else if (href.match(/(^http|^mailto)/i) || target === "_blank") {
    linkType = "external";
  } else {
    linkType = "internal";
  }

  // By default, external links should open in a new tab.
  // This is overrideable though.
  if (typeof target === "undefined") {
    target = linkType === "external" ? "_blank" : undefined;
  }
  const safeRel = target === "_blank" ? "noopener noreferrer" : rel;

  // 1. Gatsby links require their own syntax by default.
  // 2. Gatsby is also appending a duplicate path-prefix to every URL,
  // so we need to remove it when we pass it in to MDX links at build time
  if (linkType === "internal") {
    return (
      <GatsbyLink
        to={href}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        underline={underline}
        {...delegated}
      />
    );
  }

  const link = (
    <ExternalLink
      as={linkType === "internal" ? GatsbyLink : "a"}
      href={href}
      rel={safeRel}
      target={target}
      underline={underline}
      {...delegated}
    />
  );

  return link;
};

const ExternalLink = styled.a`
  color: ${themeGet("colors.link.default")};
  text-decoration: ${(p) => (p.underline ? "" : "none")};
  text-underline-offset: 10%;
  &:focus {
    outline: 2px auto ${themeGet("colors.link.default")};
    outline-offset: 2px;
  }
  &:focus:not(.focus-visible) {
    outline: none;
  }
  &:visited {
    text-decoration: none;
    color: ${themeGet("colors.link.default")};
  }
  ${sx}
`;

const InternalLink = styled(ExternalLink).attrs((props) => ({
  to: props.href,
}))`
  text-decoration: none;
  ${sx}
`;

export default Link;
