import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";

import { Box, Link, Text, themeGet } from "@fuegokit/react";
import { AkChevronDownIcon } from "@fuegokit/fuegoicons-react";
import Accordion from "../Accordion";

export const SideNavAccordionTrigger = React.forwardRef(
  ({ children, ...props }, forwardedRef) => (
    <Accordion.Header>
      <Accordion.Trigger {...props} ref={forwardedRef}>
        {children}
        <AkChevronDownIcon aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  )
);
const SideNavItems = ({ items }) => {
  const location = useLocation();

  return (
    <Box
      sx={{
        overflow: "hidden",
        flex: "1 1 0%",
        pt: 2,
      }}
    >
      {items.map((item) => {
        const isActive = location.pathname.includes(item.url);
        return (
          <Box
            key={item.title}
            sx={{
              py: 1,
              backgroundColor: "elevation.surface.default.[default]",
            }}
          >
            <Accordion
              type="single"
              collapsible
              defaultValue={isActive ? item.title : "Components"}
            >
              <Accordion.Item value={item.title}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <SideNavAccordionTrigger>
                    {item.title}
                  </SideNavAccordionTrigger>
                  {item.children ? (
                    <Accordion.Content sx={{ backgroundColor: "red" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 2,
                          gap: 2,
                        }}
                      >
                        {item.children.map((child) => (
                          <NavLink
                            key={child.title}
                            as={GatsbyLink}
                            to={child.url}
                            activeClassName="active"
                            sx={{
                              display: "block",
                            }}
                          >
                            {child.title}
                          </NavLink>
                        ))}
                      </Box>
                    </Accordion.Content>
                  ) : null}
                </Box>
              </Accordion.Item>
            </Accordion>
          </Box>
        );
      })}
    </Box>
  );
};

const NavLink = styled(GatsbyLink)`
  text-decoration: none;
  color: ${themeGet("colors.text.subtle")};
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: ${themeGet("space.2")} ${themeGet("space.3")};
  /* border-radius: ${themeGet("radii.1")}; */
  font-size: ${themeGet("fontSizes.3")};
  font-weight: ${themeGet("fontWeights.normal")};
  &.active {
    font-weight: ${themeGet("fontWeights.semibold")};
    background-color: ${themeGet(
      "colors.background.neutral.default.[default]"
    )};
  }
  &:hover {
    text-decoration: none;
    background-color: ${themeGet("colors.background.neutral.default.hovered")};
  }

  @media (min-width: ${themeGet("breakpoints.1")}) {
    font-size: ${themeGet("fontSizes.4")};
  }
`;
const NavTitleLink = styled(NavLink)`
  padding: ${themeGet("space.2")} ${themeGet("space.2")};
  font-weight: ${themeGet("fontWeights.semibold")};
  &.active {
    font-weight: ${themeGet("fontWeights.semibold")};
    background-color: revert;
    background-color: ${themeGet(
      "colors.background.neutral.default.[default]"
    )};
  }
`;

export default SideNavItems;
