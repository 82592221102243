import styled from 'styled-components';
import { themeGet } from '../../utils/theme-get.js';
import sx from '../../sx.js';

const StyledLabel = styled.label.withConfig({
  displayName: "StyledLabel",
  componentId: "sc-vx2i12-0"
})(["color:", ";&[disabled]{color:", ";}&[data-monospace]{font-family:", ";}", ""], themeGet('colors.text.default'), themeGet('colors.text.disabled'), themeGet('fontFamily.mono'), sx);

export { StyledLabel };
