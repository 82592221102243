import { Box, themeGet } from "@fuegokit/react";
import styled from "styled-components";
import React from "react";

function Container({ children }) {
  return (
    <Wrapper
      sx={{
        width: "100%",
        p: 5,
        mx: "auto",
        backgroundColor: "elevation.surface.default.[default]",
      }}
    >
      {children}
    </Wrapper>
  );
}
const Wrapper = styled(Box)`
  padding: ${themeGet("space.0")};
  @media (min-width: ${themeGet("breakpoints.1")}) {
    padding-top: ${themeGet("space.5")};
    padding-bottom: ${themeGet("space.5")};
    padding-right: ${themeGet("space.5")};
  }
`;
export default Container;
