import styled, { css } from 'styled-components';
import { themeGet } from '../../utils/theme-get.js';
import sx from '../../sx.js';

const textInputBasePadding = '12px';
const textInputBaseVerticalPadding = '3px';
const textInputHorizontalPadding = textInputBasePadding;
const renderFocusStyles = (hasTrailingAction, isInputFocused) => {
  if (hasTrailingAction) {
    return isInputFocused && css(["border-color:", ";outline:none;box-shadow:inset 0 0 0 1px ", ";"], themeGet('colors.border.focused'), themeGet('colors.border.focused'));
  }
  return css(["&:focus-within{border-color:", ";outline:none;box-shadow:inset 0 0 0 1px ", ";}"], themeGet('colors.border.focused'), themeGet('colors.border.focused'));
};
const paddingTopBottomMappings = {
  small: '0px',
  medium: textInputBaseVerticalPadding,
  large: '10px'
};
const innerActionSizeMappings = {
  small: '20px',
  medium: '24px',
  large: '28px'
};

// start with a base wrapper for low-level structural input styles
const TextInputBaseWrapper = styled.span.withConfig({
  displayName: "TextInputWrapper__TextInputBaseWrapper",
  componentId: "sc-1alwnll-0"
})(["font-size:", ";line-height:20px;color:", ";vertical-align:middle;background-color:", ";border:1px solid ", ";border-radius:", ";outline:none;display:inline-flex;align-items:stretch;min-height:32px;input,textarea{cursor:text;}select{cursor:pointer;}&::placeholder{color:", ";}", " > textarea{padding:", ";}", " ", " @media (min-width:", "){font-size:", ";}--inner-action-size:", ";padding-left:", ";padding-right:", ";padding-top:", ";padding-bottom:", ";min-height:", ";font-size:", ";line-height:", ";height:", ";", ";"], themeGet('fontSizes.2'), themeGet('colors.text.default'), themeGet('colors.background.input.default'), themeGet('colors.border.default'), themeGet('radii.2'), themeGet('colors.text.subtlest'), p => renderFocusStyles(Boolean(p.hasTrailingAction), Boolean(p.isInputFocused)), textInputBasePadding, p => p.isDisabled && css(["color:", ";background-color:", ";border-color:", ";input,textarea,select{cursor:not-allowed;}"], themeGet('colors.text.disabled'), themeGet('colors.background.disabled'), themeGet('colors.border.default')), p => p.block && css(["width:100%;display:flex;align-self:stretch;"]), themeGet('breakpoints.1'), themeGet('fontSizes.2'), p => innerActionSizeMappings[p.size], p => p.size === 'medium' ? 'revert' : themeGet('space.2'), p => p.size === 'medium' ? 'revert' : themeGet('space.2'), p => paddingTopBottomMappings[p.size], p => paddingTopBottomMappings[p.size], p => p.size === 'small' ? '28px' : 'revert', p => p.size === 'small' ? themeGet('fontSizes.1') : 'revert', p => p.size === 'small' ? '20px' : 'revert', p => p.size === 'large' ? '40px' : 'revert', sx);
// compose TextInputBaseWrapper for leading & trailing visual styles
const TextInputWrapper = styled(TextInputBaseWrapper).attrs(props => ({
  'data-testid': props.testId || undefined
})).withConfig({
  displayName: "TextInputWrapper",
  componentId: "sc-1alwnll-1"
})(["background-repeat:no-repeat;background-position:right 8px center;& > :not(:last-child){margin-right:", ";}.text-input-visual,.text-input-action{align-self:center;color:", ";flex-shrink:0;}", " ", ""], themeGet('space.2'), themeGet('colors.icon.subtle'), p => css(["padding-left:", ";padding-right:", ";> input,> select{padding-left:", ";padding-right:", ";}"], p.hasLeadingVisual ? textInputHorizontalPadding : 0, p.hasTrailingVisual && !p.hasTrailingAction ? textInputHorizontalPadding : 0, !p.hasLeadingVisual ? textInputHorizontalPadding : 0, !p.hasTrailingVisual && !p.hasTrailingAction ? textInputHorizontalPadding : 0), sx);
var TextInputWrapper$1 = TextInputWrapper;

export { TextInputBaseWrapper, TextInputWrapper$1 as default, textInputHorizontalPadding };
