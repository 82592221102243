import React from "react";
import { Box, Link, Text, themeGet } from "@fuegokit/react";
import styled from "styled-components";
import useActiveHeading from "../../hooks/use-active-heading";
import { Details, Summary } from "../Details";

const TableOfContents = ({ items, title, depth }) => {
  const activeHeadingId = useActiveHeading(items);

  function getSubItemTitle(subItemTitle) {
    const splitTitle = subItemTitle.split("--");
    if (splitTitle.length > 1) {
      return splitTitle[1];
    }
    return subItemTitle;
  }
  return (
    <Box
      as="ul"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.neutral.subtle.default",
        lineHeight: "relaxed",
        listStyle: "none",
        borderLeft: "1px solid",
        borderColor: "border.subtle",
      }}
    >
      <Text as="h3">{title ? title : null}</Text>
      {items.map((item) => {
        return (
          <ItemWrapper
            as="li"
            key={item.url}
            $isActiveHeading={activeHeadingId === item.url}
          >
            {item.items ? (
              <Details open>
                <Summary>
                  <Item sx={{ paddingBottom: 1 }} href={item.url}>
                    {item.title}
                  </Item>
                </Summary>
                <Box
                  as="ul"
                  sx={{
                    listStyle: "none",
                    paddingLeft: 4,
                  }}
                >
                  {item.items.map((subitem) => {
                    return (
                      <ItemWrapper
                        as="li"
                        key={subitem.url}
                        $isActiveHeading={activeHeadingId === subitem.url}
                      >
                        {item.title ? (
                          <Item href={subitem.url}>
                            {getSubItemTitle(subitem.title)}
                          </Item>
                        ) : null}
                      </ItemWrapper>
                    );
                  })}
                </Box>
              </Details>
            ) : (
              item.title && <Item href={item.url}>{item.title}</Item>
            )}
          </ItemWrapper>
        );
      })}
    </Box>
  );
};

const ItemWrapper = styled(Box)`
  color: ${(p) =>
    p.$isActiveHeading
      ? themeGet("colors.link.default")
      : themeGet("colors.text.subtlest")};
`;

const Item = styled(Link)`
  display: inline-block;
  padding-top: ${themeGet("space.1")};
  font-size: ${themeGet("fontSizes.3")};
  font-weight: ${themeGet("fontWeights.medium")};
  opacity: 0.8;
  transition: opacity 500ms, color 200ms ease 0s;
  color: inherit;
  line-height: ${themeGet("lineHeights.default")};
  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0ms;
    text-decoration: none;
  }
`;

export default TableOfContents;

TableOfContents.defaultProps = {
  depth: 0,
};
