import React from "react";
import styled from "styled-components";
import { Box, themeGet } from "@fuegokit/react";

import useHasMounted from "../../hooks/use-has-mounted";
import { ResourceLink } from "./ResourceLink";

const ResourcesList = ({ children }) => {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  return (
    <>
      <StyledBox as="ul" sx={{ display: "flex", flexDirection: "column" }}>
        {/* {children.length > 1 ? (
          children.map((link, index) => {
            return (
              <>
                <ResourceLink href={link.props.href}>
                  {link.props.children}
                </ResourceLink>
              </>
            );
          })
        ) : (
          <>
            <ResourceLink href={children.props.href}>
              {children.props.children}
            </ResourceLink>
          </>
        )} */}
        {children}
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box)`
  // scoot this over
  margin-left: -22px;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    margin-left: -20px;
  }
  // turn this into a "grid" on larger screens
  @media (min-width: ${themeGet("breakpoints.2")}) {
    height: 350px;
    flex-wrap: wrap;
  }
`;

export default ResourcesList;
