import React from "react";
import styled from "styled-components";
import { Link, Text, themeGet } from "@fuegokit/react";

const FigmaLink = ({ href }) => {
  return (
    <StyledLink
      subtle
      href={href}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 3,
        borderRadius: 2,
        minWidth: "112px",
      }}
    >
      <FigmaIcon />
      <Text sx={{ ml: 1, display: "inline-block" }}>Figma</Text>
    </StyledLink>
  );
};

const FigmaIcon = () => {
  return (
    <svg
      aria-hidden="true"
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: "text-bottom" }}
    >
      <path
        d="M5.5 5.44333H7.77885C9.00556 5.44333 10 4.44866 10 3.22166C10 1.99467 9.00556 1 7.77885 1H5.5M5.5 5.44333V1M5.5 5.44333H3.22115C1.99444 5.44333 1 4.44866 1 3.22166C1 1.99467 1.99444 1 3.22115 1H5.5M1 7.66506C1 8.89205 1.99444 9.88672 3.22115 9.88672H5.5V5.44339H3.22115C1.99444 5.44339 1 6.43806 1 7.66506ZM1 12.1083C1 13.3353 2.00877 14.33 3.23548 14.33C4.47813 14.33 5.5 13.3224 5.5 12.0795V9.88666H3.22115C1.99444 9.88666 1 10.8813 1 12.1083ZM5.5 7.66506C5.5 8.89205 6.49444 9.88672 7.72115 9.88672H7.77885C9.00556 9.88672 10 8.89205 10 7.66506C10 6.43806 9.00556 5.44339 7.77885 5.44339H7.72115C6.49444 5.44339 5.5 6.43806 5.5 7.66506Z"
        stroke="currentColor"
      />
    </svg>
  );
};

const StyledLink = styled(Link)`
  border: 1px solid;
  border-color: ${themeGet("colors.border.subtle")};
  &.active {
    font-weight: ${themeGet("fontWeights.semibold")};
    color: ${themeGet("colors.link.default")};
  }
  &:hover {
    text-decoration: none;
    background-color: ${themeGet("colors.background.neutral.default.hovered")};
    color: ${themeGet("colors.text.default")};
  }
`;

export default FigmaLink;
