import { themeGet } from "@fuegokit/react"
import styled from "styled-components"

const ToolsList = styled.ul`
  list-style: circle;
  padding: 0 ${themeGet("space.3")};
  font-size: ${themeGet("fontSizes.4")};

  li {
    margin: ${themeGet("space.3")} 0;
    line-height: ${themeGet("lineHeights.relaxed")};
  }
`
export default ToolsList
