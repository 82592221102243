import styled, { css } from 'styled-components';
import { themeGet } from '../../utils/theme-get.js';
import sx from '../../sx.js';

const autocompleteBasePadding = '12px';
const autocompleteBaseVerticalPadding = '3px';
const textInputHorizontalPadding = autocompleteBasePadding;
const renderFocusStyles = (hasTrailingAction, isInputFocused) => {
  if (hasTrailingAction) {
    return isInputFocused && css(["border-color:", ";outline:none;box-shadow:inset 0 0 0 1px ", ";"], themeGet('colors.border.focused'), themeGet('colors.border.focused'));
  }
  return css(["&:focus-within{border-color:", ";outline:none;box-shadow:inset 0 0 0 1px ", ";}"], themeGet('colors.border.focused'), themeGet('colors.border.focused'));
};
// start with a base wrapper for low-level structural input styles
const AutocompleteBaseWrapper = styled.span.withConfig({
  displayName: "_AutocompleteInputWrapper__AutocompleteBaseWrapper",
  componentId: "sc-16rszr4-0"
})(["font-size:", ";line-height:20px;color:", ";vertical-align:middle;background-color:", ";border:1px solid ", ";border-radius:", ";outline:none;display:inline-flex;align-items:stretch;min-height:32px;input,textarea{cursor:text;}select{cursor:pointer;}&::placeholder{color:", ";}", " > textarea{padding:", ";}", " ", " @media (min-width:", "){font-size:", ";}--inner-action-size:", ";padding-left:", ";padding-right:", ";padding-top:", ";padding-bottom:", ";min-height:", ";font-size:", ";line-height:", ";height:", ";", ";"], themeGet('fontSizes.2'), themeGet('colors.text.default'), themeGet('colors.background.input.default'), themeGet('colors.border.default'), themeGet('radii.2'), themeGet('colors.text.subtlest'), p => renderFocusStyles(Boolean(p.hasTrailingAction), Boolean(p.isInputFocused)), autocompleteBasePadding, p => p.isDisabled && css(["color:", ";background-color:", ";border-color:", ";input,textarea,select{cursor:not-allowed;}"], themeGet('colors.text.disabled'), themeGet('colors.background.disabled'), themeGet('colors.border.default')), p => p.block && css(["width:100%;display:flex;align-self:stretch;"]), themeGet('breakpoints.1'), themeGet('fontSizes.2'), p => {
  if (p.size === 'small') return '20px';
  if (p.size === 'large') return '28px';
  return '24px';
}, p => p.size === 'small' || p.size === 'large' ? themeGet('space.1') : 'revert', p => p.size === 'small' || p.size === 'large' ? themeGet('space.1') : 'revert', p => {
  if (p.size === 'small') return '0px';
  if (p.size === 'large') return '10px';
  return autocompleteBaseVerticalPadding;
}, p => {
  if (p.size === 'small') return '0px';
  if (p.size === 'large') return '10px';
  return autocompleteBaseVerticalPadding;
}, p => p.size === 'small' ? '28px' : 'revert', p => p.size === 'small' ? themeGet('fontSizes.1') : 'revert', p => p.size === 'small' ? '20px' : 'revert', p => p.size === 'large' ? '40px' : 'revert', sx);
const fontSize = () => 14;
// compose autocompleteBaseWrapper for leading & trailing visual styles
const AutocompleteInputWrapper = styled(AutocompleteBaseWrapper).withConfig({
  displayName: "_AutocompleteInputWrapper__AutocompleteInputWrapper",
  componentId: "sc-16rszr4-1"
})(["background-repeat:no-repeat;background-position:right 8px center;& > :not(:last-child){margin-right:", ";}.autocomplete-input-visual,.autocomplete-input-action{align-self:center;color:", ";flex-shrink:0;}", " vertical-align:middle;line-height:", ";align-items:center;border-radius:", ";border-width:2;border-style:solid;display:flex;flex:1;width:100%;font-size:", ";justify-content:space-between;overflow:hidden;transition:background-color 0.2s ease-in-out,border-color 0.2s ease-in-out;pointer-events:auto;padding:", " 12px;cursor:text;background-color:", ";border-color:", ";color:inherit;&[aria-invalid]{border-color:", ";}&:focus{outline:none;border-color:", ";box-shadow:0 0 0 1px ", ";&[aria-invalid]{border-color:", ";box-shadow:0 0 0 1px ", ";}}", ""], themeGet('space.2'), themeGet('colors.icon.subtle'), p => css(["padding-left:", ";padding-right:", ";> input,> select{padding-left:", ";padding-right:", ";}"], p.hasLeadingVisual ? textInputHorizontalPadding : 0, p.hasTrailingVisual && !p.hasTrailingAction ? textInputHorizontalPadding : 0, !p.hasLeadingVisual ? 12 : 0, !p.hasTrailingVisual && !p.hasTrailingAction ? textInputHorizontalPadding : 0), 8 * 2.5 / fontSize(), themeGet('radii.1'), themeGet('fontSizes.2'), themeGet('space.2'), themeGet('colors.background.input.default'), themeGet('colors.border.input'), themeGet('colors.border.danger'), themeGet('colors.border.focused'), themeGet('colors.border.focused'), themeGet('colors.border.danger'), themeGet('colors.border.danger'), sx);
var AutocompleteInputWrapper$1 = AutocompleteInputWrapper;

export { AutocompleteBaseWrapper, AutocompleteInputWrapper$1 as default, textInputHorizontalPadding };
