import React from "react";
import { Box, Heading, Link, Fuegoicon, themeGet } from "@fuegokit/react";

import styled from "styled-components";
import { HEADER_HEIGHT } from "../Header";
import { slugifyHeadings, getText } from "../../lib/utils";
import { AkLinkIcon } from "@fuegokit/fuegoicons-react";

function MarkdownHeading({ children, ...props }) {
  const text = children ? getText(children) : "";
  const id = text ? slugifyHeadings(text) : "";

  return (
    <StyledHeading id={id} {...props}>
      <Link
        href={`#${id}`}
        aria-label={`${text} permalink`}
        sx={{
          py: 2,
          px: 1,
          ml: -32,
          color: "text.default",
        }}
      >
        <StyledLinkIcon className="fuegoicon-link">
          <AkLinkIcon />
        </StyledLinkIcon>
      </Link>
      {children}
    </StyledHeading>
  );
}

const StyledHeading = styled(Heading)`
  margin-top: ${themeGet("space.2")};
  margin-bottom: ${themeGet("space.4")};
  scroll-margin-top: ${HEADER_HEIGHT + 24}px;
  display: flex;
  align-items: center;

  code {
    display: none;
  }
`;
const StyledH1 = styled(StyledHeading).attrs({ as: "h1" })`
  font-size: ${themeGet("fontSizes.11")};
`;

const StyledH2 = styled(StyledHeading).attrs({ as: "h2" })`
  font-size: ${themeGet("fontSizes.7")};
  margin-top: ${themeGet("space.8")};
`;

const StyledH3 = styled(StyledHeading).attrs({ as: "h3" })`
  font-size: ${themeGet("fontSizes.6")};
  margin-top: ${themeGet("space.6")};
`;

const StyledH4 = styled(StyledHeading).attrs({ as: "h4" })`
  font-size: ${themeGet("fontSizes.5")};
`;

const StyledH5 = styled(StyledHeading).attrs({ as: "h5" })`
  font-size: ${themeGet("fontSizes.4")};
`;

const StyledH6 = styled(StyledHeading).attrs({ as: "h6" })`
  font-size: ${themeGet("fontSizes.3")};
  color: ${themeGet("colors.text.subtle")};
`;

const StyledLinkIcon = styled(Box)`
  margin-top: -6px;
  color: ${themeGet("colors.icon.subtle")};
  visibility: hidden;
  ${StyledH1}:hover & {
    visibility: revert;
    color: ${themeGet("colors.link.default")};
  }
  ${StyledH2}:hover & {
    visibility: revert;
    color: ${themeGet("colors.link.default")};
  }
  ${StyledH3}:hover & {
    visibility: revert;
    color: ${themeGet("colors.link.default")};
  }
  ${StyledH4}:hover & {
    visibility: revert;
    color: ${themeGet("colors.link.default")};
  }
  ${StyledH5}:hover & {
    visibility: revert;
    color: ${themeGet("colors.link.default")};
  }
  ${StyledH6}:hover & {
    visibility: revert;
    color: ${themeGet("colors.link.default")};
  }
`;

export const H1 = (props) => <MarkdownHeading as={StyledH1} {...props} />;
export const H2 = (props) => <MarkdownHeading as={StyledH2} {...props} />;
export const H3 = (props) => <MarkdownHeading as={StyledH3} {...props} />;
export const H4 = (props) => <MarkdownHeading as={StyledH4} {...props} />;
export const H5 = (props) => <MarkdownHeading as={StyledH5} {...props} />;
export const H6 = (props) => <MarkdownHeading as={StyledH6} {...props} />;
