import React from "react";
import styled from "styled-components";
import { Box, VisuallyHidden, themeGet } from "@fuegokit/react";

import { generatedId } from "../../lib/utils";

const TextInput = React.forwardRef(
  (
    { id, label, leadingVisual, trailingVisual, disabled, variant, ...rest },
    ref
  ) => {
    const { current: actualId } = React.useRef(id || generatedId());
    const [isFocused, setIsFocused] = React.useState(false);

    const { ariaLabelledby } = rest;
    return (
      <>
        <Wrapper>
          {" "}
          {leadingVisual && (
            <LeadingVisualWrapper leadingVisual={leadingVisual} />
          )}
          {trailingVisual && (
            <TrailingVisualWrapper trailingVisual={trailingVisual} />
          )}
          <Input
            {...rest}
            id={id}
            disabled={disabled}
            ref={ref}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </Wrapper>
      </>
    );
  }
);

const Wrapper = styled(Box)`
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  background-color: ${themeGet("colors.background.input.default")};
  border-color: ${themeGet("colors.border.default")};
  color: ${themeGet("colors.text.default")};
  cursor: text;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  font-size: 14px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out 0s,
    border-color 0.2s ease-in-out 0s;
  overflow-wrap: break-word;
  vertical-align: top;
  pointer-events: auto;
`;

const Label = styled.label`
  font-size: ${themeGet("fontSizes.3")};
  border: none;
  color: ${themeGet("colors.text.default")};
`;

const Input = styled.input`
  height: 32px;
  min-width: 220px;
  max-width: 280px;
  padding: 0px 12px 0px 24px;
  box-sizing: border-box;
  outline: none;
  border-radius: ${themeGet("radii.2")};
  font-size: ${themeGet("fontSizes.3")};
  line-height: ${themeGet("lineHeights.default")};
  border: 2px solid ${themeGet("colors.border.input")};
  box-shadow: none;
  background-color: ${themeGet("colors.background.input.default")};
  color: ${themeGet("colors.text.default")};
  &:focus-within {
    background-color: ${themeGet("colors.elevation.surface.default.[default]")};
    border-color: ${themeGet("colors.border.focused")};
  }
  &::placeholder {
    margin-bottom: -2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const LeadingVisualWrapper = ({ leadingVisual }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        left: "8px",
        top: "8px",
        margin: "auto 0",
        color: "icon.subtle",
      }}
    >
      {leadingVisual}
    </Box>
  );
};

const TrailingVisualWrapper = ({ trailingVisual }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        right: "8px",
        top: "8px",
        margin: "auto 0",
        marginTop: "-1px",
        color: "icon.subtle",
      }}
    >
      {trailingVisual}
    </Box>
  );
};
const Border = styled(Box)``;

export default TextInput;
