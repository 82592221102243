import { useReducer } from 'react';
import { useDrag as useDrag$1 } from 'react-aria';

/* eslint-disable @typescript-eslint/no-explicit-any */
const MOVE = 'move';
const COPY = 'copy';
const LINK = 'link';
const CANCEL = 'cancel';
const reducer = (state, action) => {
  switch (action.type) {
    case MOVE:
      return {
        ...state,
        moved: true
      };
    case COPY:
      return {
        ...state,
        copied: true
      };
    case LINK:
      return {
        ...state,
        linked: true
      };
    case CANCEL:
      return {
        ...state,
        canceled: true
      };
    default:
      return state;
  }
};
const initialState = {
  moved: false,
  copied: false,
  linked: false,
  canceled: false
};
const useDrag = (previewRef, dragData) => {
  const [dragState, dispatch] = useReducer(reducer, initialState);
  const {
    dragProps,
    isDragging
  } = useDrag$1({
    preview: previewRef,
    getItems() {
      return [dragData];
    },
    onDragEnd(e) {
      if (e.dropOperation === MOVE) {
        dispatch({
          type: MOVE
        });
      }
      if (e.dropOperation === COPY) {
        dispatch({
          type: COPY
        });
      }
      if (e.dropOperation === LINK) {
        dispatch({
          type: LINK
        });
      }
      if (e.dropOperation === CANCEL) {
        dispatch({
          type: CANCEL
        });
      }
    },
    getAllowedDropOperations() {
      return [MOVE, COPY, CANCEL, LINK];
    }
  });
  return {
    dragState,
    dragProps,
    isDragging
  };
};

export { useDrag as default };
