import styled from 'styled-components';
import { system } from 'styled-system';
import { get } from '../../constants.js';
import sx from '../../sx.js';

const hoverColor = system({
  hoverColor: {
    property: 'color',
    scale: 'colors'
  }
});

/**
 * Use the Link component to style an anchor tag with anchor tags with platform hyperlink color cues and hover text decoration,
 * while also respecting accessibility.
 *
 * When choosing a link or a button, links are used for destinations, whenever the action may refresh the browers,
 * or when moving from one page to another.
 *
 * The Link component can be used as a semantic `button`, by using `<Link as='button' onClick={your onClick here}>.
 *
 * If you use the `as` prop, be sure to pass the inclusive and semantically correct element, such as an `a`, `button`, or
 * `summary` tag.
 */
const Link = styled.a.withConfig({
  displayName: "Link",
  componentId: "sc-1hq871b-0"
})(["color:", ";text-decoration:", ";font-weight:", ";&:hover{cursor:pointer;background:none;text-decoration:underline;transition-duration:0s,0.15s;", "}&:active{background:none;transition-duration:0s;color:", ";}&:focus{background:none;box-shadow:0 0 0 2px ", ";transition-duration:0s,0.2s;color:", ";}&:is(button){display:inline-block;padding:0;margin:0;font-size:inherit;white-space:nowrap;cursor:pointer;user-select:none;background-color:transparent;border:0;appearance:none;}", ""], p => p.subtle ? get('colors.text.subtlest') : get('colors.link.default'), p => p.underline ? 'underline' : 'none', get('fontWeights.semibold'), p => {
  if (p.hoverColor) return hoverColor;
  if (p.subtle) return `color: ${get('colors.text.subtlest')}`;
  return '';
}, get('colors.link.pressed'), get('colors.border.focused'), get('colors.text.subtle'), sx);
var Link$1 = Link;

export { Link$1 as default };
