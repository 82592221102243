import React from "react";
import { themeGet, Text } from "@fuegokit/react";
import { AkChevronRightIcon } from "@fuegokit/fuegoicons-react";

import styled from "styled-components";

function MarkdownDetails({ children, ...props }) {
  return <StyledDetails {...props}>{children}</StyledDetails>;
}
function MarkdownSummary({ children, ...props }) {
  return (
    <StyledSummary {...props}>
      <AkChevronRightIcon />
      {children}
    </StyledSummary>
  );
}

const StyledDetails = styled.details`
  margin-bottom: ${themeGet("space.3")};

  display: flex;
  details summary {
    cursor: pointer;
  }

  details summary > * {
    display: inline;
  }

  &[open] .fuegoicon-ak-chevron-right {
    transform: rotate(90deg);
  }

  ${Text} {
    margin: ${themeGet("fontSizes.2")} ${themeGet("space.4")};
  }
`;

const StyledSummary = styled.summary`
  font-size: ${themeGet("fontSizes.4")};
  display: flex;
  align-items: start;
`;

export const Details = (props) => (
  <MarkdownDetails as={StyledDetails} {...props} />
);
export const Summary = (props) => (
  <MarkdownSummary as={StyledSummary} {...props} />
);
