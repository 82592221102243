import styled from 'styled-components';
import sx from '../../sx.js';
import Button from '../Button/Button.js';

const invisibleButtonStyleOverrides = {
  paddingTop: '2px',
  paddingRight: '4px',
  paddingBottom: '2px',
  paddingLeft: '4px',
  position: 'relative',
  background: 'transparent',
  border: 'none',
  '&[data-component="IconButton"]': {
    width: 'var(--inner-action-size)',
    height: 'var(--inner-action-size)'
  },
  '&:focus': {
    border: 'none',
    outline: 'none'
  },
  '&:hover': {
    cursor: 'pointer'
  },
  '@media (pointer: coarse)': {
    ':after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      transform: 'translateY(-50%)',
      top: '50%',
      minHeight: '44px'
    }
  }
};
const UnstyledButton = styled(Button).withConfig({
  displayName: "styles__UnstyledButton",
  componentId: "sc-dkwjri-0"
})(["display:'block';margin:0;padding:0;border:none;background:transparent;cursor:pointer;text-align:left;font:inherit;color:inherit;&:focus{outline-offset:2px;}&:focus:not(:focus-visible){outline:none;}", ""], sx);

export { UnstyledButton, invisibleButtonStyleOverrides };
