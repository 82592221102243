import { Box } from "@fuegokit/react";
import React from "react";
import Container from "../Container";
import Head from "../Head";
import Header from "../Header";
import Hero from "../Hero";
import Footer from "../Footer";
import SidePanel from "../SidePanel";

function HeroLayout({ children, pageContext }) {
  return (
    <Box
      sx={{
        flexDirection: "column",
        minHeight: "100vh",
        display: "flex",
        backgroundColor: "elevation.surface.default.[default]",
      }}
    >
      <Head />
      <Header />
      <Box sx={{ flexDirection: "row", display: "flex", flex: 1 }}>
        <Box sx={{ display: ["none", null, null, "block"] }}>
          <SidePanel />
        </Box>
        {/* Main content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              flex: "1 1 auto",
              justifyContent: "space-between",
              px: [5, null, null, 10],
              py: [3, null, null, 5],
            }}
          >
            <Hero />
            <Container>{children}</Container>
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default HeroLayout;
