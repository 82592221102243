import { themeGet } from '../../../utils/theme-get.js';
import styled, { css } from 'styled-components';
import Box from '../../Box/Box.js';
import Text from '../../Text/Text.js';

const Wrapper = styled(Box).withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-oxr9rl-0"
})(["position:relative;"]);
const Section = styled.section.withConfig({
  displayName: "styles__Section",
  componentId: "sc-oxr9rl-1"
})(["margin-left:auto;margin-right:auto;position:relative;display:flex;flex-direction:column;gap:", ";justify-content:space-between;align-items:center;@media (min-width:", "){}@media (min-width:", "){flex-direction:row;}@media (min-width:", "){}@media (min-width:", "){}"], themeGet('space.5'), themeGet('breakpoints.0'), themeGet('breakpoints.1'), themeGet('breakpoints.2'), themeGet('breakpoints.3'));
const Hero = styled.div.withConfig({
  displayName: "styles__Hero",
  componentId: "sc-oxr9rl-2"
})(["display:flex;flex-direction:column;flex:1;text-align:center;gap:", ";@media (min-width:", "){text-align:revert;padding:revert;}@media (min-width:", "){text-align:left;}"], themeGet('space.1'), themeGet('breakpoints.0'), themeGet('breakpoints.1'));
const CenteredHero = styled(Hero).withConfig({
  displayName: "styles__CenteredHero",
  componentId: "sc-oxr9rl-3"
})(["text-align:center;"]);
const HeroTitle = styled(Text).withConfig({
  displayName: "styles__HeroTitle",
  componentId: "sc-oxr9rl-4"
})(["font-size:", "rem;font-weight:700;letter-spacing:-0.025em;@media (min-width:", "){font-size:", "rem;line-height:1;}@media (min-width:", "){font-size:", "rem;line-height:1;}"], 29 / 16, themeGet('breakpoints.0'), 29 / 16, themeGet('breakpoints.1'), 38 / 16);
const HeroCaption = styled(Text).withConfig({
  displayName: "styles__HeroCaption",
  componentId: "sc-oxr9rl-5"
})(["margin-top:", ";font-size:", ";font-weight:", ";line-height:", ";margin-left:auto;margin-right:auto;@media (min-width:", "){width:clamp(375px,65%,480px);margin-left:revert;margin-right:revert;}@media (min-width:", "){margin-left:revert;margin-right:revert;}"], themeGet('space.2'), themeGet('fontSizes.3'), themeGet('fontWeights.normal'), themeGet('lineHeights.default'), themeGet('breakpoints.0'), themeGet('breakpoints.1'));
const CenteredHeroCaption = styled(HeroCaption).withConfig({
  displayName: "styles__CenteredHeroCaption",
  componentId: "sc-oxr9rl-6"
})(["margin-left:auto;margin-right:auto;margin-top:", ";"], themeGet('space.4'));
const ButtonWrapper = styled.div.withConfig({
  displayName: "styles__ButtonWrapper",
  componentId: "sc-oxr9rl-7"
})(["margin-top:", ";font-size:", ";display:flex;flex-direction:column;justify-content:center;flex:1;gap:8px;padding:0 8px;@media (min-width:", "){padding:revert;display:flex;justify-content:flex-start;flex-direction:row;}@media (min-width:", "){justify-content:start;}"], themeGet('space.5'), themeGet('fontSizes.3'), themeGet('breakpoints.0'), themeGet('breakpoints.1'));
const ButtonGroupWrapper = styled(ButtonWrapper).withConfig({
  displayName: "styles__ButtonGroupWrapper",
  componentId: "sc-oxr9rl-8"
})(["", ""], props => {
  return props.variant === 'centered' ? css(["justify-content:center;align-items:center;@media (min-width:", "){justify-content:center;align-items:center;}@media (min-width:", "){justify-content:center;align-items:center;}"], themeGet('breakpoints.0'), themeGet('breakpoints.1')) : ``;
});
const ImageWrapper = styled(Box).withConfig({
  displayName: "styles__ImageWrapper",
  componentId: "sc-oxr9rl-9"
})(["flex:1;margin-top:24px;display:flex;justify-content:center;img{max-width:100%;}@media (min-width:", "){}@media (min-width:", "){margin-top:revert;}@media (min-width:", "){}"], themeGet('breakpoints.0'), themeGet('breakpoints.1'), themeGet('breakpoints.2'));
const Img = styled.img.withConfig({
  displayName: "styles__Img",
  componentId: "sc-oxr9rl-10"
})(["width:100%;object-fit:cover;height:16rem;@media (min-width:", "){height:18rem;}@media (min-width:", "){}@media (min-width:", "){}"], themeGet('breakpoints.0'), themeGet('breakpoints.1'), themeGet('breakpoints.2'));
const SVG = styled.svg.withConfig({
  displayName: "styles__SVG",
  componentId: "sc-oxr9rl-11"
})(["display:block;user-select:none;overflow:visible;"]);
const ResponsiveGridBox = styled(Box).withConfig({
  displayName: "styles__ResponsiveGridBox",
  componentId: "sc-oxr9rl-12"
})(["display:flex;flex-wrap:wrap;@media (min-width:", "){}@media (min-width:", "){display:grid;grid-template-columns:1fr 1fr;align-items:center;justify-items:center;gap:", ";flex:1;}@media (min-width:", "){}"], themeGet('breakpoints.0'), themeGet('breakpoints.1'), themeGet('space.3'), themeGet('breakpoints.2'));

export { ButtonGroupWrapper, ButtonWrapper, CenteredHero, CenteredHeroCaption, Hero, HeroCaption, HeroTitle, ImageWrapper, Img, ResponsiveGridBox, SVG, Section, Wrapper };
