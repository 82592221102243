// Users can shadow this file to add custom components to scope of all MDX files.
import {
  ToolsList,
  ArticlePageWrapper,
  ArticlePageDivider,
} from "../../components"
export default {
  ToolsList,
  ArticlePageWrapper,
  ArticlePageDivider,
}
