import React from "react";
import { throttle } from "../lib/utils";

function useActiveHeading(headings) {
  const [activeHeadingId, setActiveHeadingId] = React.useState(null);

  React.useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.pageYOffset === 0) {
        return setActiveHeadingId(null);
      }
      // are there active headings in the viewport right now? if so, pick the top one
      // if there are no active headings in the viewport, are there any above the viewport? if so,
      // pick the last one, which is the most recently scrolled out of view

      // if neither condition is met, we are in the first heading section
      let headingBoxes = headings.map((heading) => {
        const elem = document.querySelector(`${heading.url}`);
        return { id: heading.url, box: elem.getBoundingClientRect() };
      });

      // highlight the first heading in the viewport
      const TOP_OFFSET = 120;
      let firstHeadingInViewport = headingBoxes.find(({ box }) => {
        return box.bottom > TOP_OFFSET && box.top < window.innerHeight;
      });

      // if there is no heading in the viewport, check for any above the viewport
      if (!firstHeadingInViewport) {
        const reversedBoxes = [...headingBoxes].reverse();
        firstHeadingInViewport = reversedBoxes.find(({ box }) => {
          return box.bottom < TOP_OFFSET;
        });
      }

      if (!firstHeadingInViewport) {
        setActiveHeadingId(null);
      } else if (firstHeadingInViewport.id !== activeHeadingId) {
        setActiveHeadingId(firstHeadingInViewport.id);
      }
    }, 350);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeHeadingId, headings]);

  return activeHeadingId;
}

export default useActiveHeading;
