import React from "react";
import styled from "styled-components";
import { Box, themeGet } from "@fuegokit/react";
import usePersistentScroll from "../../hooks/use-persistent-scroll";
import navItems from "../../nav.yml";
import SideNav from "../SideNav";

const SidePanel = () => {
  const scrollContainerProps = usePersistentScroll("sidepanel");
  return (
    <>
      {navItems.length > 0 ? (
        <StyledBox
          {...scrollContainerProps}
          sx={{
            borderRight: "1px solid",
            borderColor: "border.default",
          }}
        >
          <Box sx={{ flexDirection: "column", display: "flex" }}>
            <SideNav items={navItems} />
          </Box>
        </StyledBox>
      ) : null}
    </>
  );
};

const StyledBox = styled(Box)`
  background-color: ${themeGet("colors.elevation.surface.default.[default]")};
  min-height: 100vh;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    scrollbar-color: transparent;

    ::-webkit-scrollbar {
      background: transparent;
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${themeGet("colors.background.neutral.subtle.pressed")};
      border-radius: ${themeGet("radii.2")};
    }
    /* width: 300px; */
  }
`;

export default SidePanel;
