import { useDrop as useDrop$1 } from 'react-aria';
import { isTextDropItem, isFileDropItem, isDirectoryDropItem } from '../utils/predicates.js';

// override the items type

// override the onDrop callback

const useDrop = options => {
  async function onDrop(e) {
    const textItems = e.items.filter(isTextDropItem);
    const fileItems = e.items.filter(isFileDropItem);
    const directoryItems = e.items.filter(isDirectoryDropItem);
    const items = {
      textItems,
      fileItems,
      directoryItems
    };
    if (options.onDrop) {
      options.onDrop({
        ...e,
        ...items
      });
    }
  }
  const dropResults = useDrop$1({
    ...options,
    onDrop
  });
  return dropResults;
};

export { useDrop as default };
