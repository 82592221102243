import React from "react";
import styled from "styled-components";
import { Link, Text, themeGet } from "@fuegokit/react";

const A11yLink = ({ href }) => {
  return (
    <StyledLink
      subtle
      href={href}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 3,
        borderRadius: 2,
      }}
    >
      <A11yIcon />
      <Text sx={{ ml: 1, display: "inline-block" }}>WAI-ARIA</Text>
    </StyledLink>
  );
};

const A11yIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 7V6H4.5V7H7V8.36701L5.066 11.752L5.93401 12.248L7.79001 9H8.21L10.066 12.248L10.934 11.752L9 8.36701V7H11.5Z"
        fill="currentColor"
      />
      <path
        d="M8 5.50001C8.55229 5.50001 9 5.05228 9 4.5C9 3.94773 8.55229 3.50001 8 3.50001C7.44772 3.50001 7 3.94773 7 4.5C7 5.05228 7.44772 5.50001 8 5.50001Z"
        fill="currentColor"
      />
      <path
        d="M8.00001 15C6.61554 15 5.26215 14.5895 4.11101 13.8203C2.95986 13.0511 2.06267 11.9579 1.53285 10.6788C1.00303 9.3997 0.864409 7.99224 1.13451 6.63437C1.4046 5.27651 2.07129 4.02923 3.05025 3.05025C4.02923 2.07129 5.27651 1.4046 6.63437 1.13451C7.99224 0.864409 9.3997 1.00303 10.6788 1.53285C11.9579 2.06267 13.0511 2.95986 13.8203 4.11101C14.5895 5.26215 15 6.61554 15 8.00001C14.9979 9.85588 14.2597 11.6351 12.9474 12.9474C11.6351 14.2597 9.85588 14.9979 8.00001 15ZM8.00001 2C6.81331 2 5.65328 2.3519 4.66659 3.01119C3.67988 3.67047 2.91085 4.60754 2.45672 5.7039C2.00261 6.80025 1.88378 8.00665 2.11528 9.17055C2.3468 10.3344 2.91824 11.4035 3.75737 12.2426C4.59648 13.0818 5.66557 13.6532 6.82946 13.8847C7.99334 14.1162 9.19976 13.9974 10.2961 13.5433C11.3925 13.0891 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.1867 14 8.00001C13.9982 6.40927 13.3655 4.88419 12.2406 3.75936C11.1158 2.63453 9.59074 2.00182 8.00001 2Z"
        fill="currentColor"
      />
    </svg>
  );
};

const StyledLink = styled(Link)`
  border: 1px solid;
  border-color: ${themeGet("colors.border.subtle")};
  &.active {
    font-weight: ${themeGet("fontWeights.semibold")};
    color: ${themeGet("colors.link.default")};
  }
  &:hover {
    text-decoration: none;
    background-color: ${themeGet("colors.background.neutral.default.hovered")};
    color: ${themeGet("colors.text.default")};
  }
`;

export default A11yLink;
