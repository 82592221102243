import styled, { keyframes } from 'styled-components';
import sx from '../../../sx.js';
import Select from '../../Select/Select.js';
import { get } from '../../../constants.js';

// animations
const slideUpAndFade = keyframes(["from{opacity:0;transform:translateY(2px);}to{opacity:1;transform:translateY(0);}"]);
const slideRightAndFade = keyframes(["from{opacity:0;transform:translateX(-2px);}to{opacity:1;transform:translateX(0);}"]);
const slideDownAndFade = keyframes(["from{opacity:0;transform:translateY(-2px);}to{opacity:1;transform:translateY(0);}"]);
const slideLeftAndFade = keyframes(["from{opacity:0;transform:translateX(2px);}to{opacity:1;transform:translateX(0);}"]);
// styles
const StyledSelect = styled(Select).withConfig({
  displayName: "styles__StyledSelect",
  componentId: "sc-egxeod-0"
})(["button{all:unset;}width:100%;"]);
const StyledSelectTrigger = styled(Select.Trigger).withConfig({
  displayName: "styles__StyledSelectTrigger",
  componentId: "sc-egxeod-1"
})(["appearance:none;margin:0;padding:0;border:none;background:transparent;cursor:pointer;text-align:left;font:inherit;user-select:none;display:flex;align-items:center;color:inherit;width:100%;justify-content:space-between;font-weight:normal;font-size:", ";text-decoration:none;opacity:0.85;font-weight:", ";border:1px solid ", ";border-radius:", ";span{margin-top:", ";}&:hover,&:focus{opacity:1;text-decoration:none;color:", ";background-color:", ";}&:active{opacity:1;text-decoration:none;background-color:", ";color:", ";border-color:", ";}&:focus,.focus-visible{outline:none;transition-duration:0s,0.2s;box-shadow:0 0 0 2px ", ";}&:focus:not(:focus-visible):not(.focus-visible){box-shadow:0 0 0 2px ", ";}svg{transition:transform 0.15s ease;}&[data-state='open']{--icon-rotation:", ";background-color:", ";color:", ";svg{transform:rotate(var(--icon-rotation));}}&[data-spacing='default']{padding:", " 12px;font-size:", ";}&[data-spacing='relaxed']{padding:", " 20px;font-size:", ";border-radius:", ";}&[data-spacing='compact']{padding:", " 8px;}&[data-spacing='none']{padding:", " 4px;}"], get('fontSizes.3'), get('fontWeights.semibold'), get('colors.border.input'), p => p.$variant === 'default' ? get('radii.1') : get('radii.5'), p => p.$variant === 'default' ? '' : '-2px', get('colors.link.default'), get('colors.background.neutral.subtle.hovered'), get('colors.background.selected.default.[default]'), get('colors.text.selected'), get('colors.border.focused'), get('colors.border.focused'), get('colors.border.focused'), p => p.$rotateTrigger ? '180deg' : '', get('colors.background.selected.default.[default]'), get('colors.text.selected'), get('space.2'), get('fontSizes.3'), get('space.3'), get('fontSizes.5'), p => p.$variant === 'pill' ? get('radii.5') : get('radii.3'), get('space.1'), get('space.0'));
const StyledSelectContent = styled(Select.Content).withConfig({
  displayName: "styles__StyledSelectContent",
  componentId: "sc-egxeod-2"
})(["min-width:184px;position:absolute;left:calc(-1 * calc(var(--radix-select-trigger-width) / 2));right:0;top:", ";width:var(--radix-select-trigger-width);overflow:auto;display:flex;flex-direction:column;background-color:", ";border:1px solid ", ";border-radius:", ";padding:", " 0;box-shadow:", ";animation-duration:400ms;animation-timing-function:cubic-bezier(0.16,1,0.3,1);will-change:transform,opacity;&[data-side='top']{animation-name:", ";}&[data-side='right']{animation-name:", ";}&[data-side='bottom']{animation-name:", ";}&[data-side='left']{animation-name:", ";}", ""], get('space.1'), get('colors.elevation.surface.default.[default]'), get('colors.border.default'), get('radii.2'), get('space.2'), get('shadows.elevation.shadow.overlay'), slideDownAndFade, slideLeftAndFade, slideUpAndFade, slideRightAndFade, sx);
const StyledSelectViewport = styled(Select.Viewport).withConfig({
  displayName: "styles__StyledSelectViewport",
  componentId: "sc-egxeod-3"
})([""]);
styled(Select.Label).withConfig({
  displayName: "styles__StyledSelectLabel",
  componentId: "sc-egxeod-4"
})(["padding:0;font-size:", ";line-height:", ";color:", ";"], get('fontSizes.3'), get('lineHeights.relaxed'), get('colors.text.subtle'));
const StyledSelectItem = styled(Select.Item).withConfig({
  displayName: "styles__StyledSelectItem",
  componentId: "sc-egxeod-5"
})(["font-size:", ";line-height:1;min-height:40px;color:", ";display:flex;align-items:center;height:", ";padding:0 ", ";position:relative;padding-left:", ";user-select:none;outline:none;text-align:left;margin:0;text-decoration:none;&[data-highlighted]{color:", ";background-color:", ";cursor:pointer;}&[data-disabled]{color:", ";background-color:", ";cursor:not-allowed;}&[data-state='checked']{background-color:", ";color:", ";font-weight:600;box-shadow:inset 2px 0px 0px ", ";}&:hover[data-disabled]{color:", ";background-color:", "!important;cursor:not-allowed;}&:hover{text-decoration:none;background-color:", ";}&:active{background-color:", ";color:", ";}&:visited{color:", ";}", ""], get('fontSizes.3'), get('colors.text.default'), get('space.5'), get('space.2'), get('space.3'), get('colors.text.default'), get('colors.background.neutral.subtle.hovered'), get('colors.text.disabled'), get('colors.background.neutral.subtle.default'), get('colors.background.selected.default.hovered'), get('colors.text.selected'), get('colors.border.selected'), get('colors.text.disabled'), get('colors.background.neutral.subtle.default'), get('colors.background.neutral.subtle.hovered'), get('colors.background.selected.default.[default]'), get('colors.text.selected'), get('colors.text.selected'), sx);

export { StyledSelect, StyledSelectContent, StyledSelectItem, StyledSelectTrigger, StyledSelectViewport };
