import React from "react";

function usePersistentScroll(id) {
  const ref = React.useRef();

  const handleScroll = React.useCallback(
    // Save scroll position in session storage on every scroll change
    (event) => window.sessionStorage.setItem(id, event.target.scrollTop),
    [id]
  );

  React.useLayoutEffect(() => {
    // Restore scroll position when component mounts
    const scrollPosition = window.sessionStorage.getItem(id);
    if (scrollPosition && ref.current) {
      ref.current.scrollTop = scrollPosition;
    }
  }, []);

  // Return props to spread onto the scroll container
  return {
    ref,
    onScroll: handleScroll,
  };
}

export default usePersistentScroll;
