import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Box,
  Heading,
  Text,
  Link,
  Stack,
  themeGet,
  VisuallyHidden,
} from "@fuegokit/react";
import { useLocation } from "@gatsbyjs/reach-router";
import Head from "../Head";
import Header from "../Header";
import Footer from "../Footer";
import SidePanel from "../SidePanel";
import StorybookLink from "../StorybookLink";
import FigmaLink from "../FigmaLink";
import SourceLink from "../SourceLink";
import TableOfContents from "../TableOfContents";
import StatusLabel from "../StatusLabel";
import PageUtilities from "../PageUtilities";

import { HEADER_HEIGHT } from "../Header";
import navItems from "../../nav.yml";
import useSiteMetadata from "../../hooks/use-site-metadata";

const Layout = ({ children, pageContext, variant = "default" }) => {
  let {
    title,
    description,
    status,
    source,
    storybook,
    figma,
    additionalContributors,
  } = pageContext.frontmatter;
  let {
    contributors,
    packageJsonVersion,
    editUrl,
    tableOfContents,
    packageName,
  } = pageContext;

  if (!additionalContributors) {
    additionalContributors = [];
  }

  const siteMetadata = useSiteMetadata();
  const location = useLocation();

  const [currentRoute, setCurrentRoute] = useState(location);

  useEffect(() => {
    setCurrentRoute(location);
  }, [location]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Head title={title} description={description} />
        <Header packageName={packageName} />

        {navItems.length > 0 ? (
          <GridLayout>
            <DesktopSidePanel>
              <SidePanel />
            </DesktopSidePanel>

            {/* Main content */}
            {/* Main */}
            <Main as="main" sx={{ overflowX: "hidden" }}>
              {variant !== "react" && title && (
                <Box
                  sx={{
                    width: "100%",
                    mr: 6,
                    flexDirection: "column",
                    display: "flex",
                    px: 5,
                    py: 5,
                  }}
                >
                  {/* Metadata */}

                  <Box my={4}>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      {title === "Fuegokit Tokens" ||
                      title === "Fuegodocs" ||
                      title === "Fuegoicons" ? (
                        <PackageHeading
                          title={title}
                          version={packageJsonVersion}
                        />
                      ) : (
                        <MetadataHeading
                          as="h1"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {title}
                        </MetadataHeading>
                      )}
                      {status ? <StatusLabel status={status} /> : null}
                    </Box>
                  </Box>

                  <Box>
                    {description ? (
                      <Box
                        sx={{
                          fontSize: 4,
                          lineHeight: "relaxed",
                          pb: 2,
                          color: "text.subtle",
                          maxWidth: "77ch",
                        }}
                      >
                        {description}
                      </Box>
                    ) : null}
                    {source || storybook || figma ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          my: 4,
                        }}
                      >
                        <MetadataWrapper
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "stretch",
                            alignContent: "center",
                          }}
                        >
                          <Stack direction={`row`} size={2}>
                            {source ? <SourceLink href={source} /> : null}
                            {storybook ? (
                              <StorybookLink href={storybook} />
                            ) : null}
                            {figma ? <FigmaLink href={figma} /> : null}
                          </Stack>
                        </MetadataWrapper>
                        <Text
                          sx={{
                            fontSize: 3,
                            px: 2,
                            fontStyle: "italic",
                            color: "text.subtle",
                            maxWidth: "63ch",
                            lineHeight: "relaxed",
                          }}
                        >
                          Access to Fuegokit npm packages, source code
                          repositories, and some content is limited to Appfire
                          staff. Log in with Slack to continue.
                        </Text>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              )}
              {/* Main content */}
              <Box
                sx={{
                  pl: [5, null, null, 5],
                  pr: [5, null, null, 2],
                  pb: 4,
                  pt: 1,
                  flex: "1 1 auto",
                }}
                as="article"
              >
                {children}
              </Box>

              <Footer
                editUrl={editUrl ? editUrl : undefined}
                contributors={
                  contributors &&
                  contributors.concat(
                    additionalContributors.map((login) => ({ login }))
                  )
                }
              />
            </Main>
            {/* Aside */}
            {tableOfContents && tableOfContents.items ? (
              <Aside as="aside">
                <Box
                  sx={{
                    display: ["none", null, "block"],
                    marginBottom: 6,
                    paddingLeft: 4,
                  }}
                >
                  <VisuallyHidden id="toc-heading">On this page</VisuallyHidden>
                  <TableOfContents
                    aria-labelledby="toc-heading"
                    items={tableOfContents.items}
                  />
                </Box>
              </Aside>
            ) : null}
          </GridLayout>
        ) : (
          children
        )}
        {siteMetadata && siteMetadata.isSurveyEnabled ? (
          <PageUtilities />
        ) : null}
      </Box>
    </>
  );
};

const GridLayout = styled(Box)`
  display: grid;
  position: sticky;
  top: 0;

  @media (min-width: ${themeGet("breakpoints.1")}) {
    grid-template-columns: 16rem 1fr;
  }
  @media (min-width: ${themeGet("breakpoints.3")}) {
    grid-template-columns: 16rem 1fr 16rem;
  }
`;

const DesktopSidePanel = styled(Box)`
  display: none;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: block;
    position: sticky;
    top: 64px;
    height: 100vh;
    overflow: auto;
  }
`;

const Main = styled(Box)`
  scrollbar-color: transparent;

  ::-webkit-scrollbar {
    background: transparent;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${themeGet("colors.background.neutral.subtle.pressed")};
    border-radius: ${themeGet("radii.2")};
  }
`;

const Aside = styled(Box)`
  display: none;
  @media (min-width: ${themeGet("breakpoints.3")}) {
    display: flex;
    position: sticky;
    top: 120px;
    height: 100vh;
    overflow: auto;
  }
`;

const MetadataWrapper = styled(Box)`
  flex-direction: column;
  gap: ${themeGet("space.1")};
  @media (min-width: ${themeGet("breakpoints.0")}) {
    flex-direction: row;
    gap: ${themeGet("space.4")};
  }
`;

const MetadataHeading = styled(Heading)`
  font-size: ${themeGet("fontSizes.7")};
`;
// @TODO: there's too much imperative logic related to which package a landing page is for
// we should build a single Layout, compute which package it's for, and just pass the packageJson from gatsby-node
const PackageHeading = ({ title, version }) => {
  return (
    <Box as="span" sx={{ fontSize: 7, marginBottom: 4 }}>
      <Heading as="h1">
        {title}
        <Text sx={{ fontSize: 4, color: "text.subtle", paddingLeft: 1 }}>
          v{version}
        </Text>
      </Heading>
    </Box>
  );
};

export default Layout;
