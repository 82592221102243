import sx from '../../sx.js';
import { themeGet } from '../../utils/theme-get.js';
import styled from 'styled-components';

const StyledRadio = styled.input.withConfig({
  displayName: "styles__StyledRadio",
  componentId: "sc-185hazb-0"
})(["appearance:none;border-color:", ";border-style:solid;border-width:", ";background-color:", ";cursor:pointer;display:grid;height:16px;margin:0;margin-top:0.125rem;place-content:center;position:relative;width:16px;&:disabled{background-color:", ";border-color:", ";}border-radius:100vh;transition:background-color,border-color 80ms cubic-bezier(0.33,1,0.68,1);&:checked{border-color:", ";border-width:", ";&:disabled{cursor:not-allowed;border-color:", ";}}@media (forced-colors:active){background-color:canvastext;border-color:canvastext;}", ""], themeGet('colors.border.default'), themeGet('borderWidths.1'), themeGet('colors.background.input.default'), themeGet('colors.background.disabled'), themeGet('colors.border.disabled'), themeGet('colors.background.selected.bold.default'), themeGet('space.1'), themeGet('colors.border.disabled'), sx);

export { StyledRadio };
