import styled from 'styled-components';
import Box from '../Box/Box.js';

const OrderedList = styled(Box).withConfig({
  displayName: "styles__OrderedList",
  componentId: "sc-ggod3g-0"
})(["justify-content:", ";flex-direction:", ";text-align:center;width:100%;display:flex;flex:1;> * + *{margin-block-start:", ";}"], p => p.stepCount === 1 ? 'flex-end' : 'space-between', p => p.orientation === 'vertical' ? 'column' : 'row', p => p.orientation === 'vertical' ? '12px' : 'revert');
const Wrapper = styled(Box).withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-ggod3g-1"
})(["display:flex;flex:1;transition-property:border-color;transition-duration:normal;"]);

export { OrderedList, Wrapper };
