/* eslint-disable @typescript-eslint/no-explicit-any */

function throttle(func, limit) {
  let inThrottle;
  let lastResult;
  return (...args) => {
    const context = args;
    if (!inThrottle) {
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, limit);
      lastResult = func.apply(context, args);
    }
    return lastResult;
  };
}
const isNumber = value => value != null && parseInt(value.toString(), 10) > 0;

export { isNumber, throttle };
