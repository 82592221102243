const gridSpaceSizes = {
  0: 0,
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 24,
  6: 48,
  7: 64,
  8: 80,
  9: 96,
  10: 112,
  11: 128,
  12: 144
};

export { gridSpaceSizes };
