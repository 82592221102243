import React from "react";
import { Box, Text, VisuallyHidden } from "@fuegokit/react";

import SideNavItems from "./SideNavItems";

const SideNav = React.forwardRef(function SideNav(props, ref) {
  return (
    <>
      <Box as="nav" aria-labelledby="page-navigation-menu-label" ref={ref}>
        <VisuallyHidden id="page-navigation-menu-label">
          Page navigation menu
        </VisuallyHidden>
        <SideNavItems items={props.items} />
      </Box>
    </>
  );
});

export default SideNav;
