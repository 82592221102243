import styled from 'styled-components';
import { themeGet } from '../../utils/theme-get.js';
import Box from '../Box/Box.js';
import Text from '../Text/Text.js';
import Radio from '../Radio/Radio.js';

const StyledBox = styled(Box).withConfig({
  displayName: "styles__StyledBox",
  componentId: "sc-1nckjku-0"
})(["padding:6px ", ";background:", ";border:1px solid ", ";border-radius:11px;"], themeGet('space.2'), themeGet('colors.background.neutral.default.[default]'), themeGet('colors.border.default'));
const StyledText = styled(Text).withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1nckjku-1"
})(["font-style:", ";font-weight:", ";font-size:", ";line-height:", ";color:", ";&:hover{color:", ";}"], themeGet('fontWeights.normal'), themeGet('fontWeights.semibold'), themeGet('fontSizes.5'), themeGet('lineHeights.condensed'), themeGet('colors.text.subtle'), themeGet('colors.text.default'));
const StyledSubText = styled(Text).withConfig({
  displayName: "styles__StyledSubText",
  componentId: "sc-1nckjku-2"
})(["padding-top:", ";color:", ";font-size:", ";font-weight:", ";"], themeGet('space.1'), themeGet('colors.text.subtlest'), themeGet('fontSizes.3'), themeGet('fontWeights.normal'));
const Label = styled.label.withConfig({
  displayName: "styles__Label",
  componentId: "sc-1nckjku-3"
})(["font-size:2rem;line-height:1.1;display:grid;grid-template-columns:1em auto;gap:", ";&:hover{& > ", "{& > ", "{color:", ";}& > ", "{color:", ";}}}"], themeGet('space.2'), StyledBox, StyledText, themeGet('colors.text.default'), StyledSubText, themeGet('colors.text.subtle'));
const StyledRadio = styled(Radio).withConfig({
  displayName: "styles__StyledRadio",
  componentId: "sc-1nckjku-4"
})(["--radio-dot-color:", ";--radio-background-color-hover:", ";appearance:none;margin:0;width:", ";height:", ";border:", " solid ", ";border-radius:", ";transform:translate(25%,25%);display:grid;place-content:center;&::before{content:'';width:", ";height:", ";border-radius:", ";transform:scale(0);box-shadow:inset 1em 1em ", ";transition:120ms transform linear;}&:hover{background-color:var(--radio-background-color-hover);}&:checked{&::before{transform:scale(1);}& + ", "{& > ", "{color:", ";}& > ", "{color:", ";}}background-color:var(--radio-dot-color);}"], themeGet('colors.background.brand.bold.default'), themeGet('colors.background.input.hovered'), themeGet('space.4'), themeGet('space.4'), themeGet('space.1'), themeGet('colors.border.default'), themeGet('radii.5'), themeGet('space.2'), themeGet('space.2'), themeGet('radii.5'), themeGet('colors.elevation.surface.default.[default]'), StyledBox, StyledText, themeGet('colors.text.default'), StyledSubText, themeGet('colors.text.subtle'));

export { Label, StyledBox, StyledRadio, StyledSubText, StyledText };
