import { BaseStyles, themeGet } from "@fuegokit/react";
import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  * {
    margin: 0;
  }
  html {
    --reach-dialog: 1;
  }
  html, body, #___gatsby {
    min-height: 100%;
    // for better legibility
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    // to prevent text resizing unexpectedly in landscape mode on iOS
    -webkit-text-size-adjust: 100%;
  }
  
  #___gatsby {
    isolation: isolate;
  }
  
  body {
    color: ${themeGet("colors.text.default")};
    background-color: ${themeGet("colors.elevation.surface.default.[default]")};
    font-size: ${themeGet("fontSizes.3")};
  }
 
`;

function wrapPageElement({ element }) {
  return (
    <BaseStyles>
      <GlobalStyles />
      {element}
    </BaseStyles>
  );
}

export default wrapPageElement;
