import React from "react"

import {
  StyledSwitchLabel,
  StyledSwitch,
  StyledSwitchThumb,
} from "./StyledSwitch"

import { SwitchLabelProps, SwitchProps, SwitchThumbProps } from "./types"

export const SwitchLabel = ({ activeTheme, children }: SwitchLabelProps) => {
  return (
    <StyledSwitchLabel activeTheme={activeTheme}>{children}</StyledSwitchLabel>
  )
}

export const Switch = ({ activeTheme, children, ...props }: SwitchProps) => {
  return (
    <StyledSwitch activeTheme={activeTheme} {...props}>
      {children}
    </StyledSwitch>
  )
}

export const SwitchThumb = ({
  children,
  activeTheme,
  ...props
}: SwitchThumbProps) => {
  return (
    <StyledSwitchThumb activeTheme={activeTheme}>{children}</StyledSwitchThumb>
  )
}
