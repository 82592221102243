import { useState } from 'react';

function useRuleBuilderSteps({
  initialStep
}) {
  const [activeStep, setActiveStep] = useState(initialStep);
  const [isSelected, setIsSelected] = useState(false);
  const nextStep = () => {
    setActiveStep(prev => prev + 1);
  };
  const prevStep = () => {
    setActiveStep(prev => prev - 1);
  };
  const reset = () => {
    setActiveStep(initialStep);
  };
  const setActiveStepIndex = step => {
    setActiveStep(step);
  };
  return {
    nextStep,
    prevStep,
    reset,
    setActiveStep: setActiveStepIndex,
    activeStep,
    isSelected,
    setIsSelected
  };
}

export { useRuleBuilderSteps };
