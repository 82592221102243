import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Box, themeGet, useOnScroll } from "@fuegokit/react";
import axios from "axios";
import FeedbackSurvey from "../FeedbackSurvey/FeedbackSurvey";

const PageUtilities = () => {
  const [hidden, setHidden] = useState(true);
  const [visible, setVisible] = useState(false);

  const [scrollTop, setScrollTop] = React.useState(0);
  const handleScroll = () => {
    setScrollTop(window.scrollY);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    // Hiden button when higher up on page
    const BUTTON_HIDE_THRESHOLD = hidden ? 102 : 20;

    if (scrollTop < BUTTON_HIDE_THRESHOLD) {
      setHidden(true);
    } else if (scrollTop > BUTTON_HIDE_THRESHOLD) {
      setHidden(false);
    }
  }, [scrollTop]);

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      // console.log("success");
      return;
    }
  };

  const onSubmit = (data) => {
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: process.env.GATSBY_FEEDBACK_SURVEY_ENDPOINT,
      data: data,
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!", data);
      })
      .catch((r) => {
        // console.log("r: ", r);
        handleServerResponse(false, r.response.data.error, data);
      });
  };

  return (
    <Wrapper
      hidden={hidden}
      aria-label="This section contains page utilities like a back-to-top button and a feedback modal trigger"
    >
      <Box sx={{ width: "256px", paddingLeft: "72px" }}>
        <FeedbackSurvey
          onSubmit={onSubmit}
          setVisible={setVisible}
          visible={visible}
        />
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  padding: ${themeGet("space.4")};
  /* border: 1px dashed deeppink; */
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition-property: opacity;

  position: sticky;

  display: flex;
  justify-content: flex-end;

  @media (min-width: ${themeGet("breakpoints.2")}) {
    bottom: 1.7rem;
    right: 0;
  }
  @media (min-width: ${themeGet("breakpoints.3")}) {
    bottom: 1.7rem;
    right: 0;
  }

  ${(p) => {
    if (p.hidden) {
      return css`
        opacity: 0;
        visibility: hidden;
        transition-property: opacity, visibility;
      `;
    } else {
      return css`
        opacity: 1;
        visibility: visible;
      `;
    }
  }}
`;

export default PageUtilities;
