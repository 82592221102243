/**
 * @fuegokit/tokens 0.24.1
 * Do not edit directly
 * Generated on Fri, 13 Dec 2024 14:28:18 GMT
 */

export default {
  scale: {
    white: "#ffffff",
    black: "#ffffff00",
    gray: {
      "100": "#F8F8F8",
      "200": "#F4F4F4",
      "300": "#F2F2F2",
      "400": "#EBEBEB",
      "500": "#DEDEDE",
      "600": "#C8C8C8",
      "700": "#A6A6A6",
      "800": "#666666",
      "900": "#3C3C3C",
      "000": "#FFFFFF",
    },
    neutral: {
      "100": "#252423",
      "200": "#292827",
      "300": "#323130",
      "400": "#3b3a39",
      "500": "#484644",
      "600": "#605e5c",
      "700": "#797775",
      "800": "#a19f9d",
      "900": "#bebbb8",
      "1000": "#e1dfdd",
      "1100": "#ffffff",
      "000": "#201f1e",
    },
    neutralAlpha: {
      "100": "#ffffff0a",
      "200": "#ffffff0f",
      "300": "#ffffff1f",
      "400": "#ffffff29",
      "500": "#ffffff2e",
      "600": "#ffffff4a",
      "700": "#ffffff99",
      "800": "#ffffffb3",
      "900": "#ffffffcc",
      "000": "#ffffff05",
    },
    blue: {
      "100": "#98c6ff",
      "200": "#79b5ff",
      "300": "#528fd9",
      "400": "#497ebf",
      "500": "#0081e3",
      "600": "#0067b5",
      "700": "#0078d4",
      "800": "#376091",
      "900": "#223b59",
      "000": "#b8d8ff",
    },
    teal: {
      "100": "#CAEDEC",
      "200": "#A3DFE4",
      "300": "#70E2DF",
      "400": "#41D1DB",
      "500": "#00B7C3",
      "600": "#00757C",
      "700": "#006167",
      "800": "#004D51",
      "900": "#003C3C",
      "000": "#DEF4F4",
    },
    purple: {
      "100": "#E6B6EC",
      "200": "#D585E0",
      "300": "#C75CD6",
      "400": "#B933CC",
      "500": "#9F1BB1",
      "600": "#881798",
      "700": "#690976",
      "800": "#54075F",
      "900": "#3F0547",
      "000": "#F4DEF7",
    },
    green: {
      "100": "#dfe9df",
      "200": "#c7e0c7",
      "300": "#bad80a",
      "400": "#92c353",
      "500": "#7fb800",
      "600": "#55a362",
      "700": "#107c10",
      "800": "#014f01",
      "900": "#393d1b",
      "000": "#dff6dd",
    },
    yellow: {
      "100": "#fef7b2",
      "200": "#f8ed86",
      "300": "#fded68",
      "400": "#fce100",
      "500": "#eed604",
      "600": "#dfc800",
      "700": "#b18525",
      "800": "#986f0b",
      "900": "#795703",
      "000": "#fffbdc",
    },
    red: {
      "100": "#f1707b",
      "200": "#f75967",
      "300": "#f75967",
      "400": "#da0a00",
      "500": "#e81123",
      "600": "#cb0f1f",
      "700": "#b80e1c",
      "800": "#8d010d",
      "900": "#442726",
      "000": "#fdd5d9",
    },
    orange: {
      "100": "#ffeec5",
      "200": "#ffe4a3",
      "300": "#ffd265",
      "400": "#fdb913",
      "500": "#d67f3c",
      "600": "#d67730",
      "700": "#b85e06",
      "800": "#ab6630",
      "900": "#433519",
      "000": "#fbf2ec",
    },
    magenta: {
      "100": "#FBB6E1",
      "200": "#F87CC9",
      "300": "#F53DAE",
      "400": "#E3008C",
      "500": "#CC007E",
      "600": "#B2006E",
      "700": "#9B0062",
      "800": "#660040",
      "900": "#4D0030",
      "000": "#FEE7F5",
    },
    lime: {
      "100": "#DCF4A4",
      "200": "#C5EC6A",
      "300": "#B9E84A",
      "400": "#A8E21D",
      "500": "#8CBD18",
      "600": "#769F14",
      "700": "#54710E",
      "800": "#425A0C",
      "900": "#324409",
      "000": "#EDF9D1",
    },
  },
  elevation: {
    surface: {
      default: {
        "[default]": "#201f1e",
        hovered: "#ffffff2e",
        pressed: "#ffffff4a",
      },
      sunken: "#292827",
      raised: {
        default: "#323130",
        hovered: "#292827",
        pressed: "#376091",
      },
      overlay: {
        default: "#292827",
        hovered: "#323130",
        pressed: "#323130",
      },
    },
    shadow: {
      raised: "0px 1.6px 3.6px 0px #00000066, 0px 0.3px 0.9px 0px #00000052",
      overlay: "0px 6.4px 14.4px 0px #00000066, 0px 1.2px 3.6px 0px #00000052",
      overflow: {
        default: "0px 0px 12px 0px #0304048f, 0px 0px 1px 0px #03040480",
        spread: "#0304048f",
        perimeter: "#091e421f",
      },
    },
  },
  border: {
    default: "#ffffff1f",
    bold: "#ffffff4a",
    selected: "#0081e3",
    focused: "#79b5ff",
    subtle: "#ffffff14",
    input: "#ffffff91",
    inverse: "#201f1e",
    disabled: "#ffffff0f",
    brand: "#497ebf",
    danger: "#f1707b",
    warning: "#d67730",
    success: "#7fb800",
    discovery: "#C75CD6",
    information: "#0081e3",
    accent: {
      blue: "#0067b5",
      red: "#e81123",
      orange: "#d67730",
      yellow: "#dfc800",
      green: "#55a362",
      purple: "#C75CD6",
      teal: "#41D1DB",
      magenta: "#E3008C",
      gray: "#605e5c",
    },
    table: {
      default: "#ffffff05",
      container: "#ffffff1f",
    },
  },
  background: {
    accent: {
      blue: {
        subtlest: "#223b59",
        subtler: "#376091",
        subtle: "#0078d4",
        bolder: "#528fd9",
      },
      red: {
        subtlest: "#442726",
        subtler: "#8d010d",
        subtle: "#b80e1c",
        bolder: "#f1707b",
      },
      orange: {
        subtlest: "#433519",
        subtler: "#ab6630",
        subtle: "#b85e06",
        bolder: "#ffd265",
      },
      yellow: {
        subtlest: "#795703",
        subtler: "#986f0b",
        subtle: "#b18525",
        bolder: "#fded68",
      },
      green: {
        subtlest: "#393d1b",
        subtler: "#014f01",
        subtle: "#107c10",
        bolder: "#7fb800",
      },
      purple: {
        subtlest: "#3F0547",
        subtler: "#54075F",
        subtle: "#690976",
        bolder: "#C75CD6",
      },
      teal: {
        subtlest: "#003C3C",
        subtler: "#004D51",
        subtle: "#006167",
        bolder: "#70E2DF",
      },
      magenta: {
        subtlest: "#4D0030",
        subtler: "#660040",
        subtle: "#9B0062",
        bolder: "#F53DAE",
      },
      gray: {
        subtlest: {
          default: "#ffffff05",
          hovered: "#ffffff0a",
          pressed: "#ffffff4a",
        },
        subtler: {
          default: "#3b3a39",
          hovered: "#323130",
          pressed: "#292827",
        },
        subtle: {
          default: "#484644",
          hovered: "#3b3a39",
          pressed: "#323130",
        },
        bolder: {
          default: "#797775",
          hovered: "#bebbb8",
          pressed: "#e1dfdd",
        },
      },
      lime: {
        subtlest: "#324409",
        subtler: "#425A0C",
        subtle: "#54710E",
        bolder: "#B9E84A",
      },
    },
    input: {
      default: "#252423",
      hovered: "#292827",
      pressed: "#252423",
    },
    inverse: {
      subtle: {
        default: "#FFFFFF29",
        hovered: "#FFFFFF3D",
        pressed: "#FFFFFF52",
      },
    },
    neutral: {
      default: {
        "[default]": "#323130",
        hovered: "#292827",
        pressed: "#ffffff2e",
      },
      subtle: {
        default: "#ffffff05",
        hovered: "#292827",
        pressed: "#ffffff2e",
      },
      bold: {
        default: "#e1dfdd",
        hovered: "#bebbb8",
        pressed: "#0078d4",
      },
    },
    brand: {
      subtlest: {
        default: "#223b59",
        hovered: "#376091",
        pressed: "#0078d4",
      },
      bold: {
        default: "#0081e3",
        hovered: "#0067b5",
        pressed: "#0078d4",
      },
      boldest: {
        default: "#b8d8ff",
        hovered: "#98c6ff",
        pressed: "#79b5ff",
      },
    },
    selected: {
      default: {
        "[default]": "#292827",
        hovered: "#223b59",
        pressed: "#0067b5",
      },
      bold: {
        default: "#528fd9",
        hovered: "#79b5ff",
        pressed: "#98c6ff",
      },
    },
    disabled: "#ffffff0a",
    information: {
      default: {
        "[default]": "#223b59",
        hovered: "#292827",
        pressed: "#797775",
      },
      bold: {
        default: "#bebbb8",
        hovered: "#0067b5",
        pressed: "#0081e3",
      },
    },
    danger: {
      default: {
        "[default]": "#442726",
        hovered: "#f1707b",
        pressed: "#f75967",
      },
      bold: {
        default: "#f1707b",
        hovered: "#f75967",
        pressed: "#b80e1c",
      },
    },
    success: {
      default: {
        "[default]": "#393d1b",
        hovered: "#014f01",
        pressed: "#107c10",
      },
      bold: {
        default: "#7fb800",
        hovered: "#c7e0c7",
        pressed: "#dfe9df",
      },
    },
    discovery: {
      default: {
        "[default]": "#3F0547",
        hovered: "#54075F",
        pressed: "#690976",
      },
      bold: {
        default: "#C75CD6",
        hovered: "#D585E0",
        pressed: "#E6B6EC",
      },
    },
    warning: {
      default: {
        "[default]": "#433519",
        hovered: "#fdb913",
        pressed: "#ffe4a3",
      },
      bold: {
        default: "#d67730",
        hovered: "#fdb913",
        pressed: "#fdb913",
      },
    },
  },
  blanket: {
    default: "#10121499",
    selected: "#1d7afc14",
    danger: "#e3493514",
  },
  text: {
    default: "#fffffff2",
    subtle: "#ffffffcc",
    subtlest: "#bebbb8",
    disabled: "#ffffff80",
    selected: "#98c6ff",
    inverse: "#201f1e",
    success: "#7fb800",
    danger: "#f75967",
    information: "#79b5ff",
    warning: {
      default: "#fdb913",
      inverse: "#201f1e",
    },
    discovery: "#D585E0",
    brand: "#528fd9",
    accent: {
      blue: {
        default: "#79b5ff",
        bolder: "#98c6ff",
      },
      red: {
        default: "#f75967",
        bolder: "#f1707b",
      },
      orange: {
        default: "#ffe4a3",
        bolder: "#ffeec5",
      },
      yellow: {
        default: "#fef7b2",
        bolder: "#fef7b2",
      },
      green: {
        default: "#c7e0c7",
        bolder: "#dfe9df",
      },
      purple: {
        default: "#D585E0",
        bolder: "#E6B6EC",
      },
      teal: {
        default: "#A3DFE4",
        bolder: "#CAEDEC",
      },
      magenta: {
        default: "#F87CC9",
        bolder: "#FBB6E1",
      },
      gray: {
        default: "#a19f9d",
        bolder: "#e1dfdd",
      },
      lime: {
        default: "#C5EC6A",
        bolder: "#DCF4A4",
      },
    },
  },
  link: {
    default: "#79b5ff",
    hovered: "#b8d8ff",
    pressed: "#b8d8ff",
    visited: "#D585E0",
  },
  icon: {
    accent: {
      blue: "#497ebf",
      red: "#da0a00",
      orange: "#fdb913",
      yellow: "#fce100",
      green: "#92c353",
      purple: "#B933CC",
      teal: "#41D1DB",
      magenta: "#E3008C",
      gray: "#605e5c",
      lime: "#A8E21D",
    },
    default: "#fffffff2",
    subtle: "#797775",
    inverse: "#201f1e",
    disabled: "#484644",
    brand: "#497ebf",
    selected: "#0078d4",
    danger: "#f75967",
    success: "#92c353",
    discovery: "#C75CD6",
    information: "#61a8ff",
    warning: {
      default: "#fdb913",
      inverse: "#e1dfdd",
    },
  },
  interaction: {
    hovered: "#00000029",
    pressed: "#00000052",
  },
  skeleton: {
    default: "#ffffff0f",
    subtle: "#ffffff0a",
  },
  opacity: {
    disabled: 0.2,
    loading: 0.4,
  },
  chart: {
    brand: {
      default: "#497ebf",
      hovered: "#528fd9",
    },
    neutral: {
      default: "#605e5c",
      hovered: "#797775",
    },
    success: {
      default: {
        "[default]": "#92c353",
        hovered: "#bad80a",
      },
      bold: {
        default: "#c7e0c7",
        hovered: "#dfe9df",
      },
    },
    danger: {
      default: {
        "[default]": "#e81123",
        hovered: "#da0a00",
      },
      bold: {
        default: "#f75967",
        hovered: "#f1707b",
      },
    },
    warning: {
      default: {
        "[default]": "#fce100",
        hovered: "#fded68",
      },
      bold: {
        default: "#f8ed86",
        hovered: "#fef7b2",
      },
    },
    information: {
      default: {
        "[default]": "#0081e3",
        hovered: "#497ebf",
      },
      bold: {
        default: "#79b5ff",
        hovered: "#98c6ff",
      },
    },
    discovery: {
      default: {
        "[default]": "#9F1BB1",
        hovered: "#B933CC",
      },
      bold: {
        default: "#D585E0",
        hovered: "#E6B6EC",
      },
    },
    categorical: {
      "1": {
        default: "#00B7C3",
        hovered: "#41D1DB",
      },
      "2": {
        default: "#D585E0",
        hovered: "#E6B6EC",
      },
      "3": {
        default: "#d67f3c",
        hovered: "#fdb913",
      },
      "4": {
        default: "#F87CC9",
        hovered: "#FBB6E1",
      },
      "5": {
        default: "#98c6ff",
        hovered: "#b8d8ff",
      },
      "6": {
        default: "#9F1BB1",
        hovered: "#B933CC",
      },
      "7": {
        default: "#FBB6E1",
        hovered: "#FEE7F5",
      },
      "8": {
        default: "#ffe4a3",
        hovered: "#ffeec5",
      },
    },
    blue: {
      bold: {
        default: "#0081e3",
        hovered: "#497ebf",
      },
      bolder: {
        default: "#497ebf",
        hovered: "#528fd9",
      },
      boldest: {
        default: "#79b5ff",
        hovered: "#98c6ff",
      },
    },
    red: {
      bold: {
        default: "#e81123",
        hovered: "#da0a00",
      },
      bolder: {
        default: "#da0a00",
        hovered: "#f75967",
      },
      boldest: {
        default: "#f75967",
        hovered: "#f1707b",
      },
    },
    orange: {
      bold: {
        default: "#fdb913",
        hovered: "#ffd265",
      },
      bolder: {
        default: "#ffd265",
        hovered: "#ffe4a3",
      },
      boldest: {
        default: "#ffe4a3",
        hovered: "#ffeec5",
      },
    },
    yellow: {
      bold: {
        default: "#fce100",
        hovered: "#fded68",
      },
      bolder: {
        default: "#fded68",
        hovered: "#f8ed86",
      },
      boldest: {
        default: "#f8ed86",
        hovered: "#fef7b2",
      },
    },
    green: {
      bold: {
        default: "#92c353",
        hovered: "#bad80a",
      },
      bolder: {
        default: "#bad80a",
        hovered: "#c7e0c7",
      },
      boldest: {
        default: "#c7e0c7",
        hovered: "#dfe9df",
      },
    },
    teal: {
      bold: {
        default: "#41D1DB",
        hovered: "#70E2DF",
      },
      bolder: {
        default: "#70E2DF",
        hovered: "#A3DFE4",
      },
      boldest: {
        default: "#A3DFE4",
        hovered: "#CAEDEC",
      },
    },
    purple: {
      bold: {
        default: "#9F1BB1",
        hovered: "#B933CC",
      },
      bolder: {
        default: "#B933CC",
        hovered: "#C75CD6",
      },
      boldest: {
        default: "#D585E0",
        hovered: "#E6B6EC",
      },
    },
    magenta: {
      bold: {
        default: "#CC007E",
        hovered: "#E3008C",
      },
      bolder: {
        default: "#E3008C",
        hovered: "#F53DAE",
      },
      boldest: {
        default: "#F87CC9",
        hovered: "#FBB6E1",
      },
    },
    gray: {
      bold: {
        default: "#484644",
        hovered: "#605e5c",
      },
      bolder: {
        default: "#605e5c",
        hovered: "#797775",
      },
      boldest: {
        default: "#797775",
        hovered: "#a19f9d",
      },
    },
    lime: {
      bold: {
        default: "#A8E21D",
        hovered: "#B9E84A",
      },
      bolder: {
        default: "#B9E84A",
        hovered: "#C5EC6A",
      },
      boldest: {
        default: "#C5EC6A",
        hovered: "#DCF4A4",
      },
    },
  },
};
