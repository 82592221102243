import { partitionColors, omitScale } from "../ThemeSelector.helpers"
import light from "@fuegokit/tokens/dist/js/esm/fk-atlassian/colors/light"
import dark from "@fuegokit/tokens/dist/js/esm/fk-atlassian/colors/dark"
import typography from "@fuegokit/tokens/dist/js/esm/fk-atlassian/base/typography"
import radii from "@fuegokit/tokens/dist/js/esm/fk-atlassian/base/radii"
import spacing from "@fuegokit/tokens/dist/js/esm/fk-atlassian/base/size"

const colors = { light: { ...light }, dark: { ...dark } }

let colorSchemes = Object.entries(colors).reduce((acc, [name, variables]) => {
  const { colors, shadows } = partitionColors(variables)
  //@ts-expect-error @TODO - types
  acc[name] = {
    colors: omitScale(colors),
    shadows: omitScale(shadows),
  }

  return acc
}, {})

const { size, space: spaceObject } = spacing

const {
  fontStack,
  fontFamily,
  fontSize: fontSizeObject,
  lineHeight: lineHeights,
  fontWeight: fontWeights,
} = typography

const fonts = {
  sansSerif: fontStack.sansSerif,
  monospace: fontStack.monospace,
  title: fontStack.title,
  body: fontStack.body,
}

const fontSizes = Object.values(fontSizeObject)
const space = Object.values(spaceObject)

export const atlassian = {
  /** colors, spacing, typoography */
  lineHeights,
  fontWeights,
  space,
  radii,
  colorSchemes,
  fontSizes,
  fonts,
}
