import styled from "styled-components"
import { themeGet, TextInput } from "@fuegokit/react"
import { TextFieldProps } from "./types"

export const StyledTextField = styled(TextInput)`
  border-radius: ${themeGet("radii.1")};
  padding: ${themeGet("space.2")} ${themeGet("space.3")} !important;
`

// @TODO - proper dynamic theming for text field
export const StyledAppfireInput = styled.input<TextFieldProps>`
  height: 32px;
  min-width: 220px;
  /* max-width: 280px; */
  padding: 0px 12px 0px 24px;
  box-sizing: border-box;
  outline: none;
  border-radius: ${themeGet("radii.5")};
  font-size: ${themeGet("fontSizes.3")};
  /* line-height: ${themeGet("lineHeights.default")}; */
  line-height: 25.2px;
  height: 49px;
  border: 1px solid ${themeGet("colors.border.default")};
  box-shadow: none;
  background-color: ${p =>
    p.activeTheme.name === "appfire"
      ? // @TODO: brand tokens don't have nested surface interaction modifiers, but they should
        // right now, if you tried to reference themeGet('colors.elevation.surface.default'), TS will complain that that
        // token doesn't exist in FuegokitThemeTypes; we should keep that as the DX and just add the nested modifier to the token
        "#090a0f"
      : themeGet("colors.elevation.surface.default.[default]")};
  color: ${themeGet("colors.border.default")};
  &:focus-within {
    background-color: ${themeGet("colors.elevation.surface.default.[default]")};
    border-color: ${themeGet("colors.border.focused")};
  }
  &::placeholder {
    margin-bottom: -2px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rebeccapurple;
  }
`
