import { useContext } from 'react';
import { VerticalPositionContext } from '../context/VerticalPositionContext.js';

// The useCenterY() hook provides the centerY value,
// which represents the vertical center position of the reference element,
// likely the PositionAwareItem components in the LeftPanel of a RuleBuilder pattern.
// This centerY value serves as the target position for the indicator caret of a RuleDetail.
function useCenterY() {
  const context = useContext(VerticalPositionContext);
  if (!context) {
    throw new Error('useCenterY must be used within a VerticalPositionProvider. Wrap your layout component in a VerticalPositionProvider. See https://fuegokit.design/react/screens/rule-builder-patterns.');
  }
  return context;
}

export { useCenterY };
