import React from "react";
import styled from "styled-components";
import { Box, Text, Link } from "@fuegokit/react";
import {
  AkCrossCircleIcon,
  AkWarningIcon,
  AkDiscoverFilledIcon,
  AkInfoIcon,
} from "@fuegokit/fuegoicons-react";

const InfoBox = ({ variant, withIcon, url, linkText, children }) => {
  let Component;
  if (variant === "info") {
    Component = InformationBox;
  } else if (variant === "warning") {
    Component = WarningBox;
  } else if (variant === "danger") {
    Component = DangerBox;
  } else if (variant === "discovery") {
    Component = DiscoveryBox;
  } else {
    throw new Error(
      `Unrecognized InfoBox ${variant}. Please use 'info', 'warning', 'discovery', or 'danger'.`
    );
  }
  return (
    <Component
      variant={variant}
      withIcon={withIcon}
      url={url}
      linkText={linkText}
    >
      {children}
    </Component>
  );
};

const InformationBox = ({ children, withIcon, url, linkText }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.information.default.[default]",
        p: 3,
        borderRadius: 1,
        textAlign: "left",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
            alignSelf: "flex-start",
            color: "icon.information",
          }}
        >
          {withIcon && <AkInfoIcon />}
        </Box>
        <Box sx={{ flexGrow: 1, display: "inline" }}>
          <Text as="p" sx={{ fontSize: 4, color: "text.accent.blue.bolder" }}>
            {children}
            {url && (
              <>
                &nbsp;
                <Link href={url} sx={{ fontSize: 4 }}>
                  {linkText}
                </Link>
              </>
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

const WarningBox = ({ children, url, withIcon, linkText }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
        backgroundColor: "background.warning.default.[default]",
        p: 3,
        textAlign: "left",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
            alignSelf: "flex-start",
            color: "icon.warning.default",
          }}
        >
          {withIcon && <AkWarningIcon />}
        </Box>
        <Box sx={{ flexGrow: 1, display: "inline" }}>
          <Text as="p" sx={{ fontSize: 4, color: "text.warning.default" }}>
            {children}
            {url && (
              <>
                &nbsp;
                <Link
                  href={url}
                  sx={{ fontSize: 4, color: "text.warning.default" }}
                >
                  {linkText}
                </Link>
              </>
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

const DiscoveryBox = ({ children, withIcon, url, linkText }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
        backgroundColor: "background.discovery.default.[default]",
        p: 3,
        textAlign: "left",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
            alignSelf: "flex-start",
            color: "icon.discovery",
          }}
        >
          {withIcon && <AkDiscoverFilledIcon />}
        </Box>
        <Box sx={{ flexGrow: 1, display: "inline" }}>
          <Text as="p" sx={{ fontSize: 4, color: "text.discovery" }}>
            {children}
            {url && (
              <>
                &nbsp;
                <Link href={url} sx={{ fontSize: 4, color: "text.discovery" }}>
                  {linkText}
                </Link>
              </>
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

const DangerBox = ({ children, withIcon, url, linkText }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 1,
        backgroundColor: "background.danger.default.[default]",
        p: 3,
        textAlign: "left",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
            alignSelf: "flex-start",
            color: "icon.danger",
          }}
        >
          {withIcon && <AkCrossCircleIcon />}
        </Box>
        <Box sx={{ flexGrow: 1, display: "inline" }}>
          <Text as="p" sx={{ fontSize: 4, color: "text.danger" }}>
            {children}
            {url && (
              <>
                &nbsp;
                <Link href={url} sx={{ fontSize: 4, color: "text.danger" }}>
                  {linkText}
                </Link>
              </>
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default InfoBox;
