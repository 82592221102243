import React from "react";
import { Box } from "@fuegokit/react";
import { Table } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Table";

function DataAttributeTable({ children }) {
  return (
    <Table>
      <colgroup>
        <col style={{ width: "40%" }} />
        <col style={{ width: "60%" }} />
      </colgroup>
      <thead>
        <tr>
          <Box as="th" textAlign="left">
            Data attribute
          </Box>
          <Box as="th" textAlign="left">
            Values
          </Box>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </Table>
  );
}
function Row({ name, type }) {
  return (
    <tr>
      <Box
        as="td"
        fontFamily="mono"
        fontSize={1}
        sx={{ whiteSpace: "nowrap" }}
        verticalAlign="top"
      >
        {name}
      </Box>
      <Box as="td" fontFamily="mono" fontSize={1} verticalAlign="top">
        <Box
          as="pre"
          fontFamily="inherit"
          fontSize="inherit"
          margin={0}
          sx={{ whiteSpace: "pre-wrap" }}
        >
          {type}
        </Box>
      </Box>
    </tr>
  );
}

Object.assign(DataAttributeTable, {
  Row,
});

export { DataAttributeTable };
