import sx from '../../sx.js';
import styled from 'styled-components';
import Box from '../Box/Box.js';

const PageContainer = styled(Box).withConfig({
  displayName: "PageContainer",
  componentId: "sc-jajhio-0"
})(["margin-top:24px;justify-content:space-evenly;display:flex;flex-direction:column;align-items:center;", ""], sx);

export { PageContainer };
