import { themeGet, sx } from "@fuegokit/react";
import { Accordion as FuegokitAccordion } from "@fuegokit/react";
import styled, { keyframes } from "styled-components";

const slideDown = keyframes`
  from {
    height: 0;
    opacity: 0
  }
  to {
    height: var(--radix-accordion-content-height);
    opacity: 1
  }
`;
const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height)
  }
  to {
    height: 0
  }
`;
const easeInOut = `cubic-bezier(0.87, 0, 0.13, 1)`;

const Accordion = styled(FuegokitAccordion)``;

const Header = styled(FuegokitAccordion.Header)`
  width: 100%;
  justify-self: flex-start;
  margin: 0;
  cursor: pointer;
  display: flex;
  //
  text-decoration: none;

  position: relative;
  align-items: center;

  padding: ${themeGet("space.2")} ${themeGet("space.3")};

  font-size: ${themeGet("fontSizes.3")};
  font-weight: ${themeGet("fontWeights.normal")};
  color: ${themeGet("colors.text.subtle")};

  /* border-radius: ${themeGet("radii.1")}; */

  @media (min-width: ${themeGet("breakpoints.1")}) {
    font-size: ${themeGet("fontSizes.4")};
  }
  //
  padding: ${themeGet("space.2")} ${themeGet("space.2")};
  font-weight: ${themeGet("fontWeights.semibold")};
  &.active {
    font-weight: ${themeGet("fontWeights.semibold")};
    background-color: revert;
    background-color: ${themeGet(
      "colors.background.neutral.default.[default]"
    )};
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    /* background-color: ${themeGet(
      "colors.background.neutral.default.hovered"
    )}; */
  }
`;

const Trigger = styled(FuegokitAccordion.Trigger)`
  // reset
  /* display: block; */
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;
  color: inherit;

  &:focus {
    outline-offset: 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
  // because it's a button, we want to stretch it
  &[data-orientation="horizontal"] {
    height: 100%;
  }
  &[data-orientation="vertical"] {
    width: 100%;
  }
  // and remove center text alignment in favour of inheriting
  /* text-align: inherit;
  box-sizing: border-box;
  appearance: none;
  border: none; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: ${themeGet("space.2")};
  background-color: ${themeGet("colors.background.neutral.default.default")};
  color: ${themeGet("colors.text.default")};
  font-weight: ${themeGet("fontWeights.bold")};
  font-family: ${themeGet("fontFamilies.normal")};
  font-size: ${themeGet("fontSizes.4")};
  --shadow-color: ${themeGet("colors.background.neutral.default.selected")};

  &:focus {
    outline: none;
    box-shadow: inset 0 -5px 0 0 var(--shadow-color);
    color: ${themeGet("colors.text.default")};
  }

  &[data-disabled] {
    color: ${themeGet("colors.text.disabled")};
  }

  &[data-state="open"] {
    background-color: ${themeGet("colors.background.neutral.default.selected")};
    color: ${themeGet("colors.text.default")};

    &:focus {
      --shadow-color: ${themeGet("colors.elevation.shadow.overlay")};
      color: ${themeGet("colors.text.default")};
    }
  }
  svg {
    color: ${themeGet("colors.icon.default")};
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state="open"] > svg {
    transform: rotate(180deg);
  }
`;

const Label = styled.span`
  font-family: --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 500;
  color: green;
  flex: 1;
`;

const Content = styled(FuegokitAccordion.Content)`
  padding-block-end: 0;
  padding-inline-end: 0;
  border: none;
  background-color: ${themeGet("colors.elevation.surface.default.[default]")};
  &[data-state="open"] {
    animation: ${slideDown} 300ms ${easeInOut};
  }
  &[data-state="closed"] {
    animation: ${slideUp} 300ms ${easeInOut};
  }
  @media (prefers-reduced-motion) {
    animation: none;
  }
`;

const Item = styled(FuegokitAccordion.Item)``;
// exports
Accordion.displayName = "Accordion";
Header.displayName = "Header";
Trigger.displayName = "Trigger";
Label.displayName = "Label";
Content.displayName = "Content";
Item.displayName = "Item";

export default Object.assign(Accordion, {
  Header,
  Trigger,
  Label,
  Content,
  Item,
});
