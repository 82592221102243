import styled from "styled-components";
import { themeGet, sx } from "@fuegokit/react";

export const InlineCode = styled.code`
  padding: 0.2em 0.4em;
  font-family: ${themeGet("fonts.mono")};
  font-size: 87%;
  background-color: ${themeGet("colors.background.neutral.default.[default]")};
  border-radius: ${themeGet("radii.2")};
  ${sx};
`;
