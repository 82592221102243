import styled from "styled-components"
import {
  Button as ButtonPrimitive,
  ButtonProps as ButtonPrimitiveProps,
  themeGet,
  sx,
} from "@fuegokit/react"
import { ButtonProps } from "./types"

const colors: { [key: string]: any } = {
  background: {
    atlassian: {
      default: "colors.background.neutral.default.[default]",
      hover: "colors.background.neutral.default.hovered",
      active: "colors.background.neutral.default.pressed",
      disabled: "colors.background.disabled",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
    monday: {
      default: "colors.background.brand.bold.default",
      hover: "colors.background.brand.bold.hovered",
      active: "colors.background.brand.bold.pressed",
      disabled: "colors.background.disabled",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
    formula: {
      default: "colors.background.neutral.default.[default]",
      hover: "colors.background.warning.bold.hovered",
      active: "colors.background.warning.bold.pressed",
      disabled: "colors.background.disabled",
      selected: "colors.background.selected.default.[default]",
      focusSelected: "colors.background.selected.default.[default]",
    },
    appfire: {
      default: "colors.background.danger.bold.default",
      hover: "colors.background.danger.bold.hovered",
      active: "colors.background.danger.bold.pressed",
      disabled: "colors.background.disabled",
      selected: "colors.background.danger.bold.pressed",
      focusSelected: "colors.background.selected.default.[default]",
    },
  },
  boxShadowColor: {
    atlassian: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
    },
    monday: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
    },
    formula: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
    },
    appfire: {
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
    },
  },
  borderColor: {
    atlassian: {
      default: "transparent",
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
    },
    monday: {
      default: "transparent",
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
    },
    formula: {
      default: "colors.background.neutral.subtle.default",
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
    },
    appfire: {
      default: "transparent",
      focus: "colors.border.focused",
      focusSelected: "colors.border.focused",
    },
  },
  color: {
    atlassian: {
      default: "colors.text.default",
      active: "colors.text.default",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    monday: {
      default: "colors.text.inverse",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    formula: {
      default: "colors.text.warning.inverse",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
    appfire: {
      default: "colors.text.inverse",
      disabled: "colors.text.disabled",
      selected: "colors.text.selected",
      focusSelected: "colors.text.selected",
    },
  },
}

export const StyledButton = styled(ButtonPrimitive)<
  ButtonPrimitiveProps & ButtonProps
>`
  border: 1px solid;
  padding: ${themeGet("space.2")} ${themeGet("space.3")};
  border-radius: ${themeGet("radii.1")};
  cursor: pointer;

  // ecosystem appearances
  background-color: ${p => {
    if (p.ecosystem && p.isSelected)
      return themeGet(colors.background[p.ecosystem].focusSelected)
    if (p.ecosystem) return themeGet(colors.background[p.ecosystem].default)
    return themeGet("colors.background.neutral.default.[default]")
  }};
  color: ${p => {
    if (p.ecosystem && p.isSelected)
      return themeGet(colors.color[p.ecosystem].focusSelected)
    if (p.ecosystem) return themeGet(colors.color[p.ecosystem].default)
    return themeGet("colors.text.default")
  }};
  border-color: ${p =>
    p.ecosystem
      ? themeGet(colors.borderColor[p.ecosystem].default)
      : "transparent"};
  ${sx}
`
