import styled from "styled-components";
import { themeGet } from "@fuegokit/react";

const List = styled.ul`
  padding-left: 2em;
  list-style: circle;
  ul,
  ol {
    margin-top: 0;
    margin-bottom: ${themeGet("space.1")};
  }

  li {
    word-wrap: break-all;
  }

  li > p {
    margin-top: ${themeGet("space.3")};
    line-height: ${themeGet("lineHeights.default")};
  }

  li + li {
    margin-top: ${themeGet("space.2")};
  }
`;

export default List;
