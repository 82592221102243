export { default as mergeWith } from 'lodash.mergewith';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNumber(value) {
  return typeof value === 'number';
}
const TransitionEasings = {
  ease: [0.25, 0.1, 0.25, 1],
  easeIn: [0.4, 0, 1, 1],
  easeOut: [0, 0, 0.2, 1],
  easeInOut: [0.4, 0, 0.2, 1]
};
const withDelay = {
  enter: (transition, delay) => ({
    ...transition,
    delay: isNumber(delay) ? delay : delay === null || delay === void 0 ? void 0 : delay.enter
  }),
  exit: (transition, delay) => ({
    ...transition,
    delay: isNumber(delay) ? delay : delay === null || delay === void 0 ? void 0 : delay.exit
  })
};

export { TransitionEasings, isNumber, withDelay };
