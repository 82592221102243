import { Box, Image, themeGet } from "@fuegokit/react";
import React from "react";
import styled from "styled-components";

// for later TS migration
// type ImageWrapperProps = {
//   children?: React.ReactNode;
//   imageMaxWidth?: number
// }

function ImageWrapper({ children, imageMaxWidth = undefined }) {
  return (
    <Box
      sx={{
        p: 6,
        bg: "background.neutral.default.[default]",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "border.default",
        borderRadius: 2,
        marginBottom: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <StyledBox imageMaxWidth={imageMaxWidth ? imageMaxWidth : undefined}>
        {children}
      </StyledBox>
    </Box>
  );
}

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  max-width: ${(p) => (p.imageMaxWidth ? `${p.imageMaxWidth}px` : "")};
  img {
    border-radius: ${themeGet("radii.2")};
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
  }
`;
export default ImageWrapper;
