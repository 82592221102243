import styled from 'styled-components';
import sx from '../../sx.js';
import { gridSpaceSizes } from './constants.js';
import Box from '../Box/Box.js';

const justifyContentMappings = {
  center: 'center',
  start: 'flex-start',
  end: 'flex-end',
  between: 'space-between'
};
const NonRecursiveStack = styled(Box).withConfig({
  displayName: "styles__NonRecursiveStack",
  componentId: "sc-1jmhrfn-0"
})(["display:flex;width:100%;height:", ";flex-direction:", ";justify-content:", ";align-items:", ";& > * + *{margin-block-start:", ";margin-inline-start:", ";}", ""], p => p.direction === 'column' ? 'auto' : 'revert', p => p.direction === 'column' ? 'column' : 'row', p => justifyContentMappings[p.justify] || 'revert', p => justifyContentMappings[p.justify] || 'revert', p => p.direction === 'column' ? `${gridSpaceSizes[p.size]}px` : 'revert', p => p.direction === 'row' ? `${gridSpaceSizes[p.size]}px` : 'revert', sx);
const RecursiveStack = styled(Box).withConfig({
  displayName: "styles__RecursiveStack",
  componentId: "sc-1jmhrfn-1"
})(["width:100%;height:", ";display:flex;flex-direction:", ";gap:", ";justify-content:", ";align-items:", ";& * + *{margin-block-start:", ";margin-inline-start:", ";}", ""], p => p.direction === 'column' ? 'auto' : 'revert', p => p.direction === 'column' ? 'column' : 'row', p => p.direction === 'row' ? `${gridSpaceSizes[p.size]}px` : 'revert', p => justifyContentMappings[p.justify] || 'revert', p => justifyContentMappings[p.justify] || 'revert', p => p.direction === 'column' ? `${gridSpaceSizes[p.size]}px` : 'revert', p => p.direction === 'row' ? `${gridSpaceSizes[p.size]}px` : 'revert', sx);

export { NonRecursiveStack, RecursiveStack };
