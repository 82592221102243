import { useStaticQuery, graphql } from "gatsby";

function useSiteMetadata() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          siteName
          description
          imageUrl
          isSearchEnabled
          isSurveyEnabled
        }
      }
    }
  `);
  return data.site.siteMetadata;
}

export default useSiteMetadata;
