import React, { useState } from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
  MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import { Box, Text, VisuallyHidden, themeGet, Link } from "@fuegokit/react";
import { AkCrossIcon, AkLockIcon } from "@fuegokit/fuegoicons-react";

import { HEADER_HEIGHT } from "../Header";

const CloseIcon = () => {
  return <AkCrossIcon size={20} />;
};

const MenuIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 5.01C4 4.451 4.443 4 5.01 4h1.98C7.549 4 8 4.443 8 5.01v1.98C8 7.549 7.557 8 6.99 8H5.01C4.451 8 4 7.557 4 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 13.549 7.557 14 6.99 14H5.01C4.451 14 4 13.557 4 12.99v-1.98zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C14 7.549 13.557 8 12.99 8h-1.98C10.451 8 10 7.557 10 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C20 7.549 19.557 8 18.99 8h-1.98C16.451 8 16 7.557 16 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm-12 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 19.549 7.557 20 6.99 20H5.01C4.451 20 4 19.557 4 18.99v-1.98zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98z"
      ></path>
    </svg>
  );
};

const Switcher = ({ items, variant }) => {
  const [isOpen, setIsOpen] = useState(false);

  let Component;

  if (variant === "desktop") {
    Component = DesktopSwitcher;
  } else if (variant === "mobile") {
    Component = MobileSwitcher;
  } else {
    throw new Error(
      `Unrecognized Switchewr ${variant}. Please use 'desktop' or 'mobile'.`
    );
  }
  return <Component items={items} isOpen={isOpen} setIsOpen={setIsOpen} />;
};

function MobileSwitcher({ items, isOpen, setIsOpen }) {
  const iconVariants = {
    opened: {
      rotate: 90,
    },
    closed: {
      rotate: 0,
    },
  };

  const menuVariants = {
    opened: {
      right: 0,
      opacity: 1,
      transition: {
        duration: 0.1,
        ease: [0.2, 0.2, 0.38, 0.9],
        delayChildren: 0.5,
      },
    },
    closed: {
      right: "-100%",
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: [0.2, 0.2, 0.38, 0.9],
      },
    },
  };

  const itemVariant = {
    closed: { opacity: 0 },
    opened: { opacity: 1 },
  };
  return (
    <>
      <Menu>
        {({ isExpanded }) => (
          <React.Fragment>
            <AnimatePresence>
              <StyledMenuButton
                id="menu-button--mobile-menu"
                $isExpanded={isExpanded}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <VisuallyHidden>Expand menu</VisuallyHidden>
                <SvgWrapper
                  variants={iconVariants}
                  animate={isOpen ? "opened" : "closed"}
                >
                  {isOpen ? <CloseIcon /> : <MenuIcon />}
                </SvgWrapper>
              </StyledMenuButton>

              <StyledMotionMenuList
                key={`styled-motion-list`}
                initial={false}
                variants={menuVariants}
                animate={isOpen ? "opened" : "closed"}
              >
                {items.map((item, index) => {
                  if (item.children) {
                    return (
                      <StyledMotionBox
                        forwardedAs="span"
                        variants={itemVariant}
                        key={item.title + index}
                      >
                        <StyledMenuItemsTitle>
                          {item.title}
                        </StyledMenuItemsTitle>
                        <StyledHR />
                        {item.children.map((child, index) => (
                          <StyledMenuItem
                            key={child.title + index}
                            onSelect={() => {}}
                            $isPrivate={child.isPrivate}
                          >
                            <StyledLink
                              href={child.url}
                              $isPrivate={child.isPrivate}
                            >
                              {child.title}
                              {child.isPrivate && <AkLockIcon size={`small`} />}
                            </StyledLink>
                          </StyledMenuItem>
                        ))}
                      </StyledMotionBox>
                    );
                  }
                  return (
                    // todo: return this to Link when URL deployed and link stable
                    <StyledMenuItem
                      key={item.title + index}
                      onSelect={() => {}}
                    >
                      <StyledLink href={item.url}>{item.title}</StyledLink>
                    </StyledMenuItem>
                  );
                })}
              </StyledMotionMenuList>
            </AnimatePresence>
          </React.Fragment>
        )}
      </Menu>
    </>
  );
}

function DesktopSwitcher({ items, isOpen, setIsOpen }) {
  const iconVariants = {
    opened: {
      rotate: 90,
    },
    closed: {
      rotate: 0,
    },
  };
  const menuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.25,
      },
      y: "66px",
      display: "block",
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.5,
        delay: 0.3,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };
  const menuVariants = {
    opened: {
      top: 0,
      opacity: 1,
      transition: {
        duration: 0.1,
        ease: [0.2, 0.2, 0.38, 0.9],
        delayChildren: 0.1,
      },
    },
    closed: {
      top: "-109vh",
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: [0.2, 0.2, 0.38, 0.9],
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: 1.2 },
    },
  };

  const itemVariant = {
    closed: { opacity: 0 },
    opened: { opacity: 1 },
  };
  return (
    <>
      <Menu>
        {({ isExpanded }) => (
          <React.Fragment>
            <StyledMenuButton
              key={`styled-motion-button`}
              $isExpanded={isExpanded}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <VisuallyHidden>Expand menu</VisuallyHidden>
              <SvgWrapper
                variants={iconVariants}
                animate={isOpen ? "opened" : "closed"}
              >
                {isOpen ? <CloseIcon /> : <MenuIcon />}
              </SvgWrapper>
            </StyledMenuButton>

            <AnimatePresence>
              <StyledMotionMenuList
                key={`styled-motion-list`}
                initial="exit"
                variants={menuAnimate}
                animate={isOpen ? "enter" : "exit"}
              >
                {items.map((item, index) => {
                  if (item.children) {
                    return (
                      <StyledMotionBox
                        forwardedAs="span"
                        variants={itemVariant}
                        key={item.title + index}
                      >
                        <StyledMenuItemsTitle>
                          {item.title}
                        </StyledMenuItemsTitle>
                        <StyledHR />
                        {item.children.map((child, index) => (
                          <StyledMenuItem
                            key={child.title + index}
                            onSelect={() => {}}
                            $isPrivate={child.isPrivate}
                          >
                            <StyledLink
                              href={child.url}
                              $isPrivate={child.isPrivate}
                            >
                              {child.title}
                              {child.isPrivate && <AkLockIcon size={`small`} />}
                            </StyledLink>
                          </StyledMenuItem>
                        ))}
                      </StyledMotionBox>
                    );
                  }
                  return (
                    // todo: return this to Link when URL deployed and link stable
                    <StyledMenuItem
                      key={item.title + index}
                      onSelect={() => {}}
                    >
                      <StyledLink href={item.url}>{item.title}</StyledLink>
                    </StyledMenuItem>
                  );
                })}
              </StyledMotionMenuList>
            </AnimatePresence>
          </React.Fragment>
        )}
      </Menu>
    </>
  );
}

const StyledMenuButton = styled(MenuButton)`
  color: ${themeGet("colors.icon.default")};
  height: 44px;
  width: 44px;
  background: ${(p) =>
    p.$isExpanded
      ? themeGet("colors.background.subtle.neutral.default")
      : "transparent"};
  border: transparent;
  border-radius: 99999999px;
  display: block;
  &:hover {
    cursor: pointer;
    color: ${themeGet("colors.background.brand.bold.hovered")};
    background-color: ${themeGet(
      "colors.background.information.default.hovered"
    )};
  }
  &[data-reach-menu-button][aria-expanded] {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${themeGet(
      "colors.background.selected.default.[default]"
    )};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  border-radius: ${themeGet("radii.2")};

  &[data-reach-menu-item] {
    padding: 8px 32px 8px 16px;
  }
  &[data-reach-menu-item][data-selected] {
    text-decoration: none;
    color: ${themeGet("colors.text.default")};
    font-weight: ${themeGet("fontWeights.semibold")};
    background-color: ${(p) =>
      p.$isPrivate
        ? themeGet("colors.elevation.surface.overlay.default")
        : themeGet("colors.background.neutral.default.hovered")};
  }
  &:hover {
    text-decoration: none;
    color: ${themeGet("colors.text.default")};
    font-weight: ${themeGet("fontWeights.semibold")};
    background-color: ${(p) =>
      p.$isPrivate
        ? ""
        : themeGet("colors.background.neutral.default.hovered")};
  }
  &:active {
    text-decoration: none;
    color: ${themeGet("colors.text.default")};
    font-weight: ${themeGet("fontWeights.semibold")};
    background-color: ${themeGet("colors.background.neutral.default.hovered")};
  }
  &:first-of-type {
    margin-top: ${themeGet("space.2")};
  }
  &:last-of-type {
    margin-bottom: ${themeGet("space.4")};
  }
`;

const StyledMenuItemsTitle = styled(Text)`
  &[data-reach-menu-item] {
    padding: 8px 32px 8px 16px;
  }
  padding: 8px 32px 8px 16px;
  display: block;
  color: inherit;
  font-size: "12px";
  text-decoration: none;
  font-weight: ${themeGet("fontWeights.medium")};

  &:hover {
    text-decoration: none;
    cursor: revert;
    background-color: revert;
  }
`;
const MotionMenuList = motion(MenuList);

const StyledMotionMenuList = styled(MotionMenuList)`
  display: block;
  box-sizing: border-box;
  position: fixed;
  overflow: auto;
  inset: 0px 16px auto auto;

  /* width: 250px; */
  max-width: 280px;
  min-height: 200px;
  max-width: 100vw;
  max-height: calc(100vh - 100px);

  padding: 16px;
  z-index: 1;
  flex: 1 1 auto;
  border-radius: 3px;
  background-color: ${themeGet("colors.elevation.surface.overlay.default")};

  overflow: auto;
  &[data-reach-menu-list] {
    background: ${themeGet("colors.elevation.surface.overlay.default")};
    padding: ${themeGet("space.3")} ${themeGet("space.4")}
      ${themeGet("space.3")} ${themeGet("space.2")};
    color: ${themeGet("colors.text.default")};
    border-left-color: ${themeGet("colors.border.subtle")};
    /* Mobile menu styles */
    min-height: 100%;
    border-top: none;
    overflow: auto;
    transform: translate3d(0px, 85px, 0px);
    min-width: 272;
    @media (min-width: ${themeGet("breakpoints.1")}) {
      transform: translate3d(0px, 76px, 0px);
      min-height: 448px;
      min-width: 368px;
      max-width: 450px;
      box-shadow: ${themeGet("shadows.elevation.shadow.overlay")};
    }
  }
  & ${StyledMenuItemsTitle}:not(:first-child) {
    margin-top: ${themeGet("space.4")};
  }
  transition: all 0.15s cubic-bezier(0, 0, 0.38, 0.9);
`;

const StyledGatsbyLink = styled(GatsbyLink)`
  width: 100%;
  display: flex;
  color: ${(p) =>
    p.$isPrivate
      ? themeGet("colors.text.disabled")
      : themeGet("colors.text.subtle")};
  font-size: ${themeGet("fontSizes.3")};
  text-decoration: none;

  border-radius: ${themeGet("radii.2")};
  font-weight: ${themeGet("fontWeights.normal")};
  justify-content: space-between;
  &:hover {
    pointer-events: ${(p) => (p.$isPrivate ? "none" : "revert")};
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  color: ${(p) =>
    p.$isPrivate
      ? themeGet("colors.text.disabled")
      : themeGet("colors.text.subtle")};
  font-size: ${themeGet("fontSizes.3")};
  text-decoration: none;

  border-radius: ${themeGet("radii.2")};
  font-weight: ${themeGet("fontWeights.normal")};
  justify-content: space-between;
  &:hover {
    pointer-events: ${(p) => (p.$isPrivate ? "none" : "revert")};
    text-decoration: none;
  }
`;

const StyledHR = styled.hr`
  height: 1px;
  margin: ${themeGet("space.1")} ${themeGet("space.3")};
  background-color: ${themeGet("colors.border.subtle")};
  border: 0;
`;

export const SvgWrapper = styled(motion.div)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledMotionBox = styled(motion.span)``;

export default Switcher;
