import styled from 'styled-components';
import { themeGet } from '../../utils/theme-get.js';
import sx from '../../sx.js';

const StyledTextarea = styled.textarea.withConfig({
  displayName: "StyledTextarea",
  componentId: "sc-1e166kb-0"
})(["font-family:", ";flex:1;width:100%;border-radius:", ";border:1px solid ", ";background-color:", ";padding:", ";font-size:", ";outline:none;transition:box-shadow 0.3s ease;&::placeholder{color:", ";font-family:", ";}&:focus-visible{box-shadow:0 0 0 2px ", ";}&:disabled{cursor:not-allowed;opacity:0.5;background-color:", ";}", ""], themeGet('fontFamily.normal'), themeGet('radii.1'), themeGet('colors.border.input'), themeGet('colors.background.input.default'), themeGet('space.2'), themeGet('fontSize.2'), themeGet('colors.text.subtlest'), themeGet('fontFamily.normal'), themeGet('colors.border.focused'), themeGet('colors.background.disabled'), sx);

export { StyledTextarea };
