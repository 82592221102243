import React from "react";
import styled, { keyframes } from "styled-components";
import { Box, Text, Popover, themeGet, Spacer, Link } from "@fuegokit/react";
import { AkCrossIcon } from "@fuegokit/fuegoicons-react";

const STATUS_TEXT_COLORS = {
  experimental: "text.subtle",
  alpha: "text.discovery",
  beta: "text.accent.blue.bolder",
  stable: "text.success",
  deprecated: "text.warning.inverse",
  removed: "text.danger",
};

const STATUS_BACKGROUND_COLORS = {
  experimental: "background.neutral.default.[default]",
  alpha: "background.discovery.default.[default]",
  beta: "background.information.default.[default]",
  stable: "background.success.default.[default]",
  deprecated: "background.warning.bold.default",
  removed: "background.danger.default.[default]",
};

const STATUS_HOVERED_BACKGROUND_COLORS = {
  experimental: "background.neutral.default.hovered",
  alpha: "background.discovery.default.hovered",
  beta: "background.information.default.hovered",
  stable: "background.success.default.hovered",
  deprecated: "background.warning.bold.hovered",
  removed: "background.danger.default.hovered",
};

const STATUS_BORDER_COLORS = {
  experimental: "border.subtle",
  alpha: "border.discovery",
  beta: "border.information",
  stable: "border.success",
  deprecated: "border.warning",
  removed: "border.danger",
};

const STATUS_DESCRIPTIONS = {
  experimental:
    "No support is implied, and breaking changes should be expected. Experimental doesn't always indicate that something is unstable, but sometimes we have to use that classification because we develop components in parallel, and there may already be an existing component shipped in the main bundle.",
  alpha:
    "Component is ready for preliminary usage, with breaking changes expected.",
  beta: "Component meets most of the criteria needed to be considered mature enough to be used in most scenarios and is used in production.",
  stable:
    "Component is mature and usage is strongly encouraged, with long-term support expected.",
  deprecated:
    "The component will be removed in the future and should be avoided.",
  removed: "The component no longer exists in Fuegokit.",
};

const getStatusTextColor = (status) => {
  return (
    STATUS_TEXT_COLORS[status.toLowerCase()] ||
    "background.subtle.neutral.default"
  );
};

const getStatusBackgroundColor = (status) => {
  return STATUS_BACKGROUND_COLORS[status.toLowerCase()] || "text.subtlest";
};

const getStatusBorderColor = (status) => {
  return STATUS_BORDER_COLORS[status.toLowerCase()] || "border.subtle";
};

const getStatusHoverBackgroundColor = (status) => {
  return (
    STATUS_HOVERED_BACKGROUND_COLORS[status.toLowerCase()] ||
    "background.neutral.default.hovered"
  );
};

const StatusLabel = ({ status }) => {
  return (
    <>
      <Box sx={{ display: "relative" }}>
        <StyledPopover>
          <Popover.Trigger asChild>
            <BaseStatusLabel
              sx={{
                color: getStatusTextColor(status),
                backgroundColor: getStatusBackgroundColor(status),
                border: "1px solid",
                borderColor: getStatusBorderColor(status),
                ":hover": {
                  cursor: "pointer",
                  backgroundColor: getStatusHoverBackgroundColor(status),
                },
              }}
            >
              {status}
            </BaseStatusLabel>
          </Popover.Trigger>
          <Popover.Portal>
            <StyledPopoverContent side={`bottom`} sideOffset={16}>
              <Text sx={{ fontWeight: "semibold" }}>{status}</Text>
              <br />
              <Spacer size={16} axis={`vertical`} />
              <Text sx={{ fontSize: 2, color: "text.subtle" }}>
                {STATUS_DESCRIPTIONS[status.toLowerCase()]}
              </Text>
              <br />
              <Spacer size={16} axis={`vertical`} />
              <Link
                href="https://fuegokit.design/react/getting-started/component-lifecycle-status"
                sx={{ textDecoration: "none", fontSize: 2 }}
              >
                Read more about our component lifecycle statuses.
              </Link>
              <StyledPopoverClose className="PopoverClose" aria-label="Close">
                <AkCrossIcon size={16} />
              </StyledPopoverClose>
              <StyledPopoverArrow className="PopoverArrow" />
            </StyledPopoverContent>
          </Popover.Portal>
        </StyledPopover>
      </Box>
    </>
  );
};

const BaseStatusLabel = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  max-height: 24px;
  width: fit-content;
  border-radius: ${themeGet("radii.2")};
  font-size: ${themeGet("fontSizes.1")};
  padding-left: ${themeGet("space.1")};
  padding-right: ${themeGet("space.1")};
  line-height: ${themeGet("lineHeights.default")};
  text-transform: uppercase;
  letter-spacing: -0.01em;
  font-size: 1;
  font-weight: bold;
`;

// popover

// animations
const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;
const fadeOut = keyframes`
 from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

export const IconButton = styled.button`
  // reset
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;
  user-select: none;
  display: flex;
  align-items: center;
  color: inherit;

  appearance: none;
  font-family: inherit;
  border-radius: ${themeGet("radii.2")};
  height: ${themeGet("space.6")};
  width: ${themeGet("space.6")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet("colors.text.subtle")};
  background-color: ${themeGet("colors.elevation.surface.default.[default]")};
  box-shadow: ${themeGet("shadows.elevation.shadow.overlay")};

  transition: background-color 0.1s ease-out,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);

  &:hover {
    background-color: ${themeGet("colors.background.neutral.subtle.hovered")};
  }
  &:focus {
    border: 1px solid;
    border-color: ${themeGet("colors.border.focused")};
    box-shadow: 0 0 0 2px ${themeGet("colors.border.focused")};
  }
`;
export const Fieldset = styled.fieldset`
  display: flex;
  gap: ${themeGet("space.4")};
  align-items: center;
  border: none;
`;
export const Label = styled.label`
  font-size: ${themeGet("fontSizes.3")};
  color: ${themeGet("colors.text.subtle")};
  width: ${themeGet("space.7")};
`;

//
export const StyledPopover = styled(Popover)`
  button,
  fieldset,
  input {
    all: unset;
  }
`;
export const StyledPopoverContent = styled(Popover.Content)`
  border-radius: ${themeGet("radii.2")};
  padding: ${themeGet("space.5")};
  width: 260px;
  background-color: ${themeGet("colors.elevation.surface.raised.default")};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &:focus {
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
      0 0 0 2px ${themeGet("colors.border.focused")};
  }
  &[data-state="open"][data-side="top"] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state="open"][data-side="right"] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state="open"][data-side="bottom"] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state="open"][data-side="left"] {
    animation-name: ${slideRightAndFade};
  }
`;
export const StyledPopoverArrow = styled(Popover.Arrow)`
  fill: ${themeGet("colors.elevation.surface.default.[default]")};
`;
export const StyledPopoverClose = styled(Popover.Close)`
  // reset
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;
  user-select: none;
  display: flex;
  align-items: center;
  color: inherit;
  font-family: inherit;
  border-radius: 100%;
  height: ${themeGet("space.6")};
  width: ${themeGet("space.6")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet("colors.icon.default")};
  position: absolute;
  top: ${themeGet("space.3")};
  right: ${themeGet("space.3")};

  &:hover {
    background-color: ${themeGet("colors.background.neutral.subtle.hovered")};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${themeGet("colors.border.focused")};
  }
`;

export default StatusLabel;
