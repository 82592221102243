import styled from 'styled-components';
import sx from '../../sx.js';
import Text from '../Text/Text.js';

const StyledText = styled(Text).withConfig({
  displayName: "styles__StyledText",
  componentId: "sc-1rartuu-0"
})(["font-size:var(--size);line-height:1;font-weight:800;letter-spacing:-0.025em;color:transparent;", ""], sx);
const Gradient = styled.span.withConfig({
  displayName: "styles__Gradient",
  componentId: "sc-1rartuu-1"
})(["background-color:var(--fallback-color);--gradient-stops:var(--gradient-from),var(--gradient-to,var(--fallback-color));-webkit-background-clip:text;-webkit-text-fill-color:transparent;-moz-background-clip:text;-moz-text-fill-color:transparent;background-clip:text;background-image:linear-gradient(to var(--gradient-direction),var(--gradient-stops));box-decoration-break:clone;-webkit-box-decoration-break:clone;"]);

export { Gradient, StyledText };
