import { Box, themeGet } from "@fuegokit/react";
import React from "react";
import styled from "styled-components";

export function VideoWrapper({ children }) {
  return (
    <Box
      sx={{
        p: 6,
        bg: "background.neutral.default.[default]",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "border.default",
        borderRadius: 2,
        marginBottom: 3,
      }}
    >
      <StyledBox>{children}</StyledBox>
    </Box>
  );
}

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  img {
    border-radius: ${themeGet("radii.2")};
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
  }
`;
