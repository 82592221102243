import { themeGet } from '../../utils/theme-get.js';
import styled from 'styled-components';
import sx from '../../sx.js';
import Box from '../Box/Box.js';

const Body = styled(Box).withConfig({
  displayName: "styles__Body",
  componentId: "sc-o6cxtw-0"
})(["display:flex;flex-direction:", ";list-style:none;margin:0;padding:0;> * + *{margin-top:", ";}gap:", ";", ""], p => p.direction === 'column' ? 'column' : 'row', p => p.direction === 'column' ? themeGet('space.2') : undefined, p => p.direction === 'column' ? undefined : themeGet('space.2'), sx);

export { Body };
