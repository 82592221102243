/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import styled from "styled-components";
import Downshift from "downshift";
import { navigate } from "gatsby";

import { Box, Text, themeGet, VisuallyHidden } from "@fuegokit/react";
import { AkSearchIcon } from "@fuegokit/fuegoicons-react";

import { TextInput } from "../TextInput";
import SearchResults from "./SearchResults";
import useSiteMetadata from "../../hooks/use-site-metadata";
import useSearch from "../../hooks/use-search";

function stateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.changeInput:
      if (!changes.inputValue) {
        // Close the menu if the input is empty.
        return { ...changes, isOpen: false };
      }
      return changes;
    default:
      return changes;
  }
}

const Search = () => {
  const [query, setQuery] = React.useState("");
  const results = useSearch(query);
  const siteMetadata = useSiteMetadata();

  const inputRef = React.useRef(null);
  const [isInputFocused, setIsInputFocused] = React.useState(false);

  const onChange = (e) => setQuery(e.target.value);

  // focus the search input when the user types "Cmd + K"
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const handleKeyDown = (e) => {
        // do something
        if ((e.metaKey || e.ctrlKey) && e.code === "KeyK") {
          inputRef.current && inputRef.current.focus();
          setIsInputFocused(true);
        }
      };
      const handleKeyUp = (e) => {
        // do something
        setIsInputFocused(false);
      };
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keydown", handleKeyUp);
      };
    }
  }, []);

  return (
    <Downshift
      id="search-results"
      labelId="search-results"
      inputValue={query}
      onInputValueChange={(inputValue) => setQuery(inputValue)}
      // We don't need Downshift to keep track of a selected item because as
      // soon as an item is selected we navigate to a new page.
      // Set the selected item to `null` to avoid any unexpected states related to the selected item
      // the search worker will occasionally not fire and we wind up with an un-destructured 'item' object, which means we
      // need this item.item.path || item.path logic
      selectedItem={null}
      onSelect={(item) => {
        if (item && item.item.path) {
          navigate(item.item.path);
          setQuery("");
        } else if (item && item.path) {
          navigate(item.path);
          setQuery("");
        }
      }}
      itemToString={(item) => (item ? item.title : "")}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        getLabelProps,
        isOpen,
        highlightedIndex,
      }) => (
        <>
          <label {...getLabelProps()}>
            <VisuallyHidden>Search</VisuallyHidden>
          </label>
          <Box {...getRootProps({ position: "relative" })}>
            <TextInput
              leadingVisual={<AkSearchIcon size={`small`} />}
              id={`search-results-input`}
              ref={inputRef}
              role="combobox"
              aria-expanded="false"
              {...getInputProps({
                placeholder: `Search ${siteMetadata.title}`,
                sx: {
                  width: 240,
                },
              })}
              {...getLabelProps}
              trailingVisual={<CmdK />}
            />
            {isOpen ? (
              <Box
                {...getMenuProps({
                  position: "absolute",
                  left: 0,
                  right: 0,
                  pt: 2,
                })}
                sx={{
                  backgroundColor: "elevation.surface.default.[default]",
                }}
              >
                <SearchBox>
                  <SearchResults
                    results={results}
                    getItemProps={getItemProps}
                    highlightedIndex={highlightedIndex}
                  />
                </SearchBox>
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Downshift>
  );
};
const CmdK = () => {
  return (
    <Box
      sx={{
        color: "text.subtlest",
        border: "1px solid",
        borderColor: "border.subtle",
        fontSize: 1,
        p: "2px",
        borderRadius: 2,
        mt: "-1.5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      ⌘K
    </Box>
  );
};
const SearchBox = styled(Box)`
  background: ${themeGet("colors.elevation.surface.overlay.default")};
  position: relative;
  isolation: isolate;
  overflow: auto;
  min-width: 380px;
  max-width: 280px;
  max-height: 70vh;
  padding: ${themeGet("space.3")};
  box-shadow: ${themeGet("colors.shadow.overlay")};
  border-color: ${themeGet("colors.border.subtle")};
  background: ${themeGet("colors.elevation.surface.overlay.default")};
  border-radius: ${themeGet("radii.2")};
  border-width: 1px;
  border-style: solid;
  z-index: 1;
`;

export default Search;
