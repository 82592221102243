import React from "react";
import { Analytics } from "@vercel/analytics/react";

const VercelAnalytics = () => {
  return (
    <>
      <Analytics />
    </>
  );
};

export default VercelAnalytics;
