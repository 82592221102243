import React from "react";
import styled from "styled-components";
import { Box, Heading, Text, themeGet } from "@fuegokit/react";
import useSiteMetadata from "../../hooks/use-site-metadata";

function Hero() {
  const { title, description } = useSiteMetadata();

  return (
    <Box
      sx={{
        bg: "elevation.surface.default.[default]",
        pb: 6,
      }}
    >
      <Wrapper>
        <HeroHeading
          as="h1"
          sx={{
            color: "text.default",
            mb: 2,
          }}
        >
          {title}
        </HeroHeading>
        <Text
          as="p"
          sx={{
            m: 0,
            mt: 2,
            color: "text.default",
            fontSize: 5,
            lineHeight: "condensed",
          }}
        >
          {description}
        </Text>
      </Wrapper>
    </Box>
  );
}

const Wrapper = styled(Box)`
  padding-top: ${themeGet("space.0")};
  width: 100%;
  margin: 0 auto;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    padding-top: ${themeGet("space.5")};
    padding-right: ${themeGet("space.5")};
    padding-bottom: ${themeGet("space.5")};
  }
`;

const HeroHeading = styled(Heading)`
  font-size: ${themeGet("fontSizes.11")};
  margin: 0;
  padding-left: 0;
  margin-top: ${themeGet("space.5")};
  @media (min-width: ${themeGet("breakpoints.1")}) {
    font-size: ${themeGet("fontSizes.12")};
  }
`;

export default Hero;
