import * as React from "react"

import { Text, themeGet } from "@fuegokit/react"
import styled from "styled-components"

type ArticlePageWrapperProps = {
  children?: React.ReactNode
}

const ArticlePageWrapper = ({ children }: ArticlePageWrapperProps) => {
  return <Main>{children}</Main>
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin: ${themeGet("space.7")} auto;
  padding: 0 ${themeGet("space.4")};
  margin-bottom: ${themeGet("space.12")};
  gap: ${themeGet("space.6")};
  max-width: 720px;

  ${Text} {
    font-size: ${themeGet("fontSizes.4")};
    line-height: 1.6;
  }

  hr {
    border: none;
    height: 1px;
    background-color: ${themeGet("colors.border.default")};
  }

  @media (min-width: ${themeGet("breakpoints.2")}) {
    margin: ${themeGet("space.8")} auto;
    width: 720px;
  }
`
export default ArticlePageWrapper
