/* eslint-disable no-useless-computed-key */

const variantMappingObj = {
  WithImage: 'olt',
  NoImage: 'olt',
  TwoLineTitleLeft: 'tltl',
  TwoLineTitleCenter: 'tltc',
  TwoColumnTitleCenter: 'tctc',
  VariableTwoColumnTitleCenter: 'vtctc'
};

export { variantMappingObj };
