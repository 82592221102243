import styled from 'styled-components';
import { get } from '../../constants.js';
import sx from '../../sx.js';

const Wrapper = styled.a.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-ln2mtz-0"
})(["color:", ";text-decoration:", ";font-weight:", ";&:hover{cursor:pointer;background:none;text-decoration:underline;transition-duration:0s,0.15s;", "}&:active{background:none;transition-duration:0s;color:", ";}&:focus{background:none;box-shadow:0 0 0 2px ", ";transition-duration:0s,0.2s;color:", ";}&:is(button){display:inline-block;padding:0;margin:0;font-size:inherit;white-space:nowrap;cursor:pointer;user-select:none;background-color:transparent;border:0;appearance:none;}& > svg{width:var(--size);height:var(--size);display:inline-block;user-select:none;vertical-align:text-bottom;overflow:visible;}", ""], p => p.subtle ? get('colors.text.subtlest') : get('colors.link.default'), p => p.underline ? 'underline' : 'none', get('fontWeights.bold'), p => p.subtle ? `${get('colors.text.subtlest')}` : '', get('colors.link.pressed'), get('colors.border.focused'), get('colors.text.subtle'), sx);

export { Wrapper };
