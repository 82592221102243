import { BaseStyles, themeGet } from "@fuegokit/react"
import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  * {
    margin: 0;
  }
  
  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }
  
  #root, #___gatsby {
    isolation: isolate;
  }
  [data-portal-root] {
    height: 100%;
  }
  
  body {
    color: ${themeGet("colors.text.default")};
    background-color: ${themeGet("colors.elevation.surface.default.[default]")};
    font-family: ${themeGet("fontFamily.sansSerif")}!important;
    -webkit-font-smoothing: antialiased;
  }

  ::-webkit-scrollbar {
      background-color: transparent;
      width: 8px;
  }
  ::-webkit-scrollbar-thumb {
      background: ${themeGet("colors.background.neutral.subtle.pressed")};
      border-radius: ${themeGet("radii.2")};
  }
  
`

function wrapPageElement({ element }: any) {
  return (
    <BaseStyles>
      <GlobalStyles />
      {element}
    </BaseStyles>
  )
}

export default wrapPageElement
