import React from "react";
import { useTheme, Box, AtlasButton } from "@fuegokit/react";
import { AfDayIcon, AfNightIcon } from "@fuegokit/fuegoicons-react";

const ColorModeToggle = () => {
  const { colorMode, setColorMode } = useTheme();

  return (
    <>
      <AtlasButton
        appearance={`subtle`}
        spacing={`default`}
        onClick={() => setColorMode(colorMode === "night" ? "day" : "night")}
        aria-label={
          "Switch to " +
          (colorMode === "night" ? "day" : "night") +
          " color mode"
        }
        sx={{ backgroundColor: "transparent" }}
      >
        <Box display="flex">
          {colorMode === "night" ? (
            <AfDayIcon size={`small`} />
          ) : (
            <AfNightIcon size={`small`} />
          )}
        </Box>
      </AtlasButton>
    </>
  );
};

export default ColorModeToggle;
