import sx from '../../sx.js';
import { themeGet } from '../../utils/theme-get.js';
import styled from 'styled-components';
import Box from '../Box/Box.js';

const ContentStack = styled(Box).withConfig({
  displayName: "styles__ContentStack",
  componentId: "sc-qjvak7-0"
})(["display:flex;flex-direction:column;justify-content:space-between;flex:1;& > * + *{margin-block-start:", ";}", ""], themeGet('lineHeights.default'), sx);

// TODO: this should be our media object, and
// see: https://github.com/mui/material-ui/blob/24015d1e4bdf135ba6c6091141cab2299d824be5/packages/mui-material/src/CardMedia/CardMedia.js
const CardMediaRoot = styled(Box).withConfig({
  displayName: "styles__CardMediaRoot",
  componentId: "sc-qjvak7-1"
})([""]);
const Img = styled.img.withConfig({
  displayName: "styles__Img",
  componentId: "sc-qjvak7-2"
})(["display:'block';background-size:'cover';background-repeat:'no-repeat';background-position:'center';object-fit:cover;width:100%;"]);

export { CardMediaRoot, ContentStack, Img };
