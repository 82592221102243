import React from "react"
import styled from "styled-components"
import {
  themeGet,
  AtlasButtonProps,
  sx,
  AtlasButton,
  ThemeProvider,
} from "@fuegokit/react"
import { AppfireButton } from "./AppfireButton"
import { StyledButton } from "./StyledButton"
import { ButtonProps } from "./types"

const Button = ({
  children,
  ecosystem = "atlassian",
  appearance,
  activeTheme,
  sx: sxProp,
  ...props
}: ButtonProps) => {
  let Component
  if (activeTheme.name === "appfire") {
    Component = AppfireButton
  } else if (activeTheme.name === "atlassian") {
    Component = AtlasButton
  } else {
    Component = StyledButton
  }
  return (
    <ThemeProvider theme={activeTheme}>
      <Component appearance={appearance} sx={sxProp} {...props}>
        {children}
      </Component>
    </ThemeProvider>
  )
}
export default Button
