import React from "react";
import styled from "styled-components";
import { Box } from "@fuegokit/react";

export const ResourceCardGrid = ({ children }) => {
  return <Grid as="div">{children}</Grid>;
};

const Grid = styled(Box)`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
`;
