import styled from "styled-components";
import { themeGet, sx } from "@fuegokit/react";

const Table = styled.table`
  display: block;
  width: 100%;
  margin: 0 0 ${themeGet("space.3")};
  overflow: auto;

  tr {
    background-color: ${themeGet("colors.white")};

    &:nth-child(2n) {
      background-color: ${themeGet("colors.elevation.surface.raised")};
    }
    &:not(:first-child) {
      border-top: 1px solid ${themeGet("colors.border.subtle")};
    }
  }

  img {
    background-color: transparent;
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  thead,
  tbody,
  tfoot {
    border-bottom: 2px solid ${themeGet("colors.border.default")};
  }

  td,
  th {
    border: none;
    padding: ${themeGet("space.3")} ${themeGet("space.3")};
    text-align: left;
  }

  th {
    vertical-align: top;
    font-weight: ${themeGet("fontWeights.semibold")};
  }

  td:first-child,
  th:first-child {
    padding-left: 0;
  }

  td:last-child,
  th:last-child {
    padding-right: 0;
  }

  caption {
    /* ${typography.h600()} */
    margin-bottom: ${themeGet("space.2")};
    text-align: left;
  }
  ${sx}
`;

export default Table;
