import styled from 'styled-components';

function getHeight({
  axis,
  size
}) {
  return axis === 'horizontal' ? 1 : size;
}
function getWidth({
  axis,
  size
}) {
  return axis === 'vertical' ? 1 : size;
}

/**
 *
 * The Spacer component is a utility component you can stretch and squash into any shape, whenever you need
 * structural space between elements. Fuegokit also makes a 0-config, platform grid-aware version of `<Spacer>`
 * available that accepts named grid-size value props instead of an arbitrary pixel value.
 *
 * In the product environment and in your components, avoid using `margin`. Margins collpase in surprising
 * and strange ways, and they break encapsulation, bleeding out and through the component boundary into neighboring elements.
 *
 * Use a `<Spacer>` component instead.
 *
 * `<Spacer>` renders a `span`, a more flexible tag than `div`. The HTML spec discourages using `div` elements
 * between elements like a `p` and a `button`, which makes a `span` a better semantic choice.
 *
 * `<Spacer>` is a block-level element. If you need `<Spacer>` to separate `inline-block` elements, you can use composition:
 *
 *
 * ```jsx
 * import Spacer from '@fuegokit/spacer';
 *
 * const InlineSpacer = styled(Spacer)`
 *  display: inline-block;
 * `
 * ```
 *
 * Because the product environment relies so heavily on the 8px grid, use multiples of 4 for your
 * `size` prop values.
 *
 * Use good judgement, but as a general rule, use multiples of 8, following
 * the 8px grid in the product environment, using 4px adjustments as necessary to fine tune.
 *
 * The 4px baseline allows more flexibility for line heights and smaller adjustments.
 */

const Spacer = styled.span.withConfig({
  displayName: "Spacer",
  componentId: "sc-1s091op-0"
})(["display:block;width:", "px;min-width:", "px;height:", "px;min-height:", "px;"], getWidth, getWidth, getHeight, getHeight);
var Spacer$1 = Spacer;

export { Spacer$1 as default };
