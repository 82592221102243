// export default {
//   plain: {
//     backgroundColor: "#23282E",
//     color: "#F8E6A0",
//   },
//   styles: [
//     {
//       types: ["comment", "prolog", "doctype", "cdata"],
//       style: {
//         color: "#6A768A",
//       },
//     },
//     {
//       types: ["namespace"],
//       style: {
//         opacity: 0.7,
//       },
//     },
//     {
//       types: ["tag", "operator"],
//       style: {
//         color: "#388BFF",
//       },
//     },
//     {
//       types: ["property", "function", "attr-name"],
//       style: {
//         color: "#8BDAE5",
//       },
//     },
//     {
//       types: ["variable"],
//       style: {
//         color: "#FFFFFF",
//       },
//     },
//     {
//       types: ["string"],
//       style: {
//         color: "#F797D2",
//       },
//     },
//     {
//       types: ["entity"],
//       style: {
//         cursor: "help",
//       },
//     },
//     {
//       types: [
//         "boolean",
//         "entity",
//         "url",
//         "attr-value",
//         "control",
//         "directive",
//         "unit",
//         "statement",
//         "regex",
//         "at-rule",
//         "selector",
//         "keyword",
//         "placeholder",
//       ],
//       style: {
//         color: "#9F8FEF",
//       },
//     },
//     {
//       types: ["deleted"],
//       style: {
//         textDecorationLine: "line-through",
//       },
//     },
//     {
//       types: ["inserted"],
//       style: {
//         borderBottom: "1px dotted #CD519D",
//         textDecorationLine: "underline",
//       },
//     },
//     {
//       types: ["italic"],
//       style: {
//         fontStyle: "italic",
//       },
//     },
//     {
//       types: ["important", "bold"],
//       style: {
//         fontWeight: "bold",
//       },
//     },
//     {
//       types: ["important"],
//       style: {
//         color: "#388BFF",
//       },
//     },
//   ],
// };

export default {
  plain: {
    color: "#ededed",
    backgroundColor: "#23282E",
  },
  styles: [
    {
      types: ["prolog"],
      style: {
        color: "#000080",
      },
    },
    {
      types: ["comment", "punctuation"],
      style: {
        color: "#fefefe", //"#FFE2BD", //"#1F845A", //#6A995 //#0c976b
      },
    },
    {
      types: ["builtin"],
      style: {
        color: "#4FC1FF",
      },
    },
    {
      types: ["number", "variable", "inserted"],
      style: {
        color: "#B5CEA8",
      },
    },
    {
      types: ["operator"],
      style: {
        color: "#D4D4D4",
      },
    },
    {
      types: ["constant", "hexcode"],
      style: {
        color: "#646695",
      },
    },
    {
      types: ["tag", "changed", "function", "keyword"],
      style: {
        color: "#ffa07a", //"#569CD6",
      },
    },
    {
      types: ["attr-name", "selector", "property"],
      style: {
        color: "#9CDCFE",
      },
    },
    {
      types: ["deleted", "string"],
      style: {
        color: "#FEC57C",
      },
    },
    {
      types: ["regex", "char"],
      style: {
        color: "#D16969",
      },
    },
    {
      types: ["class-name"],
      style: {
        color: "#4EC9B0",
      },
    },
  ],
};
