import styled from 'styled-components';

const getWidth = ({
  axis,
  size
}) => {
  return axis === 'vertical' ? 1 : size;
};
const gridSpaceSizes = {
  M4: 4,
  M8: 8,
  M12: 12,
  M16: 16,
  M24: 24,
  M32: 32,
  M40: 40,
  M72: 72
};
/**
 *
 * The `<GridSpacer>` component extends the raw `<Spacer>` utility component but works
 * as an out-of-the-box spacer to use in base-8 grid. It takes a `size` prop,
 * a multiple of 4 from a set of fixed spacing sizes, each of which corresponds to a pixel value:
 *
 * 'M4' | 'M8' | 'M12' | 'M16' | 'M24' | 'M32' | 'M40' | 'M72';
 *
 * Use `<GridSpacer>` whenever you're working in the product environment and need an easy 8px-grid-aware space
 * utility component.
 */
const GridSpacer = styled.span.withConfig({
  displayName: "GridSpacer",
  componentId: "sc-cje488-0"
})(["display:block;width:", "px;min-width:", "px;height:", "px;min-height:", "px;"], p => getWidth({
  size: gridSpaceSizes[p.size]
}), p => getWidth({
  size: gridSpaceSizes[p.size]
}), p => getWidth({
  size: gridSpaceSizes[p.size]
}), p => getWidth({
  size: gridSpaceSizes[p.size]
}));
var GridSpacer$1 = GridSpacer;

export { GridSpacer$1 as default, getWidth };
