import styled, { ThemedStyledProps } from "styled-components"
import { themeGet, Box, Radio, sx } from "@fuegokit/react"
import { RadioLabelProps } from "./types"

export const StyledRadioWrapper = styled(Box)`
  display: flex;
  align-items: center;
  border-radius: ${themeGet("radii.1")};
  gap: ${themeGet("space.1")};
  width: 100%;
`

export const StyledRadio = styled(Radio)`
  width: unset;
  height: unset;
  padding: 7px;
  &:checked {
    border-color: ${themeGet("colors.border.brand")};
    padding: 4px;
    border-width: ${themeGet("space.1")};
  }
`

export const StyledRadioStack = styled(Box)`
  display: flex;
  gap: ${themeGet("space.4")};
`

export const StyledRadioLabel = styled.label<RadioLabelProps>`
  width: 100%;
  margin-bottom: ${p =>
    p.theme.activeTheme?.name === "appfire" ? "-0.25em" : "revert"};
`
