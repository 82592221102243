import React from "react";
import styled from "styled-components";
import { Link } from "../Link";
import { themeGet, Box } from "@fuegokit/react";
import { useLocation } from "@gatsbyjs/reach-router";
import { slugifyHeadings } from "../../lib/utils";

export const ResourceLink = ({ parent, href, children, tabindex }) => {
  const location = useLocation();
  const parsedHref = `${location.pathname}/${slugifyHeadings(children)}`;

  return (
    <>
      <StyledListItem>
        <ListArrow aria-hidden="true" />
        <StyledLink href={href}>{children}</StyledLink>
      </StyledListItem>
    </>
  );
};

const StyledListItem = styled.li`
  position: relative;
  display: inline-block;
  transition: color 150ms ease-in-out;
  text-decoration: none;
  margin-bottom: ${themeGet("space.3")};

  font-size: ${themeGet("fontSizes.5")};
  color: ${themeGet("colors.link.default")};
  &:hover {
    color: ${themeGet("colors.link.hovered")};
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }

  @media (min-width: ${themeGet("breakpoints.2")}) {
    font-size: ${themeGet("fontSizes.7")};
  }
`;

const ListArrow = styled.span`
  &:before {
    content: "→";
    position: absolute;
    font-size: ${themeGet("fontSizes.4")};
    left: -${themeGet("space.4")};
    top: ${themeGet("space.1")};
    color: ${themeGet("colors.icon.default")};

    @media (min-width: ${themeGet("breakpoints.2")}) {
      top: ${themeGet("space.2")};
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${themeGet("colors.link.default")};

  &:hover {
    color: ${themeGet("colors.link.hovered")};
    cursor: pointer;
    text-decoration: underline;
  }
`;
