import React from "react"
import styled from "styled-components"
import {
  themeGet,
  TextInput,
  AtlasTextfield,
  ThemeProvider,
} from "@fuegokit/react"

import { TextFieldProps } from "./types"
import { StyledAppfireInput, StyledTextField } from "./StyledTextField"

const colors: { [key: string]: any } = {
  background: {
    atlassian: {
      default: `colors.elevation.surface.default.[default]`,
    },
    monday: {
      default: `colors.elevation.surface.default.[default]`,
    },
    formula: {
      default: `colors.elevation.surface.default.[default]`,
    },
    appfire: {
      default: `colors.elevation.surface.raised.default`,
    },
  },
  boxShadowColor: {
    atlassian: {
      // @TODO -- if needed
    },
    monday: {
      // @TODO -- if needed
    },
    formula: {
      // @TODO -- if needed
    },
    appfire: {
      // @TODO -- if needed
    },
  },
  borderColor: {
    atlassian: {
      // @TODO -- if needed
    },
    monday: {
      // @TODO -- if needed
    },
    formula: {
      // @TODO -- if needed
    },
    appfire: {
      // @TODO -- if needed
    },
  },
  color: {
    atlassian: {
      default: `colors.text.default`,
    },
    monday: {
      default: `colors.text.default`,
    },
    formula: {
      default: `colors.text.default`,
    },
    appfire: {
      default: `colors.text.default`,
    },
  },
}
// @TODO - make this a proper dynamically theme-aware text field
const TextField = ({ activeTheme, children, ...props }: TextFieldProps) => {
  let Component
  if (activeTheme.name === "appfire") {
    Component = AppfireInput
  } else if (activeTheme.name === "atlassian") {
    Component = AtlasInput
  } else if (activeTheme.name === "formula") {
    Component = FormulaInput
  } else if (activeTheme.name === "monday") {
    Component = MondayInput
  } else {
    throw new Error(
      `Unrecognized TextField ${activeTheme}. Please use 'appfire', 'atlassian', 'formula', or 'monday'.`
    )
  }
  return (
    <ThemeProvider theme={activeTheme}>
      <Component activeTheme={activeTheme} {...props}>
        {children}
      </Component>
    </ThemeProvider>
  )
}

export default TextField

// @TODO - consolidate into single theme-aware input
const AppfireInput = ({ children, size, ...props }: TextFieldProps) => {
  return <StyledAppfireInput {...props} />
}

const AtlasInput = ({ children, ...props }: any) => {
  return <AtlasTextfield {...props} />
}

const FormulaInput = ({ children, ...props }: any) => {
  return <StyledTextField {...props} />
}

const MondayInput = ({ children, ...props }: any) => {
  return <StyledTextField {...props} />
}

const StyledDemoInput = ({ children, ...props }: any) => {
  return <StyledTextField {...props} />
}
