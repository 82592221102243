import React from "react";
import styled from "styled-components";

import { Box, VisuallyHidden, themeGet, Link, Spacer } from "@fuegokit/react";
// import { AkEditFilledIcon } from "@fuegokit/fuegoicons-react";

import { Contributors } from "../Contributors";
import { generatedId } from "../../lib/utils";

const AkEditFilledIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.02 19.23c-.03311.162-.02554.3297.02204.488.04758.1584.13368.3025.2506.4194.11691.1169.26099.203.41934.2506.15835.0475.32603.0551.48802.022l3.81-.78-4.21-4.21-.78 3.81ZM19.844 6.707l-2.12-2.122c-.1859-.18592-.4067-.3333-.6497-.43369-.243-.10039-.5034-.15181-.7663-.15131-.512 0-1.024.195-1.415.585l-9.757 9.758 4.95 4.95 9.757-9.758c.3749-.37505.5856-.88367.5856-1.414 0-.53032-.2107-1.03894-.5856-1.414h.001Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

const Footer = ({ editUrl, contributors }) => {
  const date = new Date().getFullYear();
  return (
    <StyledFooter>
      <VisuallyHidden>
        <h2>Footer</h2>
      </VisuallyHidden>

      <Box
        sx={{
          py: [4, null, null, 6],
          px: [5, null, null, 10],
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-end",
            fontSize: 3,
          }}
        >
          {contributors && contributors.length > 0 ? (
            <Contributors contributors={contributors} />
          ) : null}
          {editUrl ? (
            <Link
              subtle
              href={editUrl}
              sx={{
                display: "flex",
                fontWeight: "normal",
                marginLeft: "auto",
                alignItems: "center",
              }}
            >
              <AkEditFilledIcon />
              <Spacer axis={`horizontal`} size={8} />
              Edit this page on GitHub
            </Link>
          ) : null}
        </Box>
      </Box>
    </StyledFooter>
  );
};
const StyledFooter = styled.footer`
  width: 100%;
  /* background-color: ${themeGet("colors.elevation.surface.raised")}; */
  display: flex;
  flex-direction: column;
  height: "84px";
`;

Footer.displayName = "Footer";
export default Footer;
