import React from "react";
import { Box, themeGet } from "@fuegokit/react";
import styled from "styled-components";

function getBorderRadius({ size, square }) {
  if (square) {
    return size && size <= 24 ? "4px" : "6px";
  } else {
    return "50%";
  }
}

const Avatar = styled.img.attrs((props) => ({
  height: props.size,
  width: props.size,
}))`
  display: inline-block;
  overflow: hidden; // Ensure page layout in Firefox should images fail to load
  line-height: ${themeGet("lineHeights.condensedUltra")};
  vertical-align: middle;
  border-radius: ${(props) => getBorderRadius(props)};
  &:hover {
    border: "1px solid";
    border-color: ${themeGet("colors.border.information")};
  }
`;

Avatar.defaultProps = {
  size: 20,
  alt: "",
  square: false,
};

export default Avatar;
