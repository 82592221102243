import { formula } from "./formula"
import { monday } from "./monday"
import { appfire } from "./appfire"
import { atlassian } from "./atlassian"

export const theme = {
  formula: {
    ...formula,
    name: "formula",
    description: "Fuegokit for Azure DevOps products",
  },
  monday: {
    ...monday,
    name: "monday",
    description: "Fuegokit for Monday.com products",
  },
  atlassian: {
    ...atlassian,
    name: "atlassian",
    description: "Fuegokit for Atlassian products",
  },
  appfire: {
    ...appfire,
    name: "appfire",
    description: "Fuegokit for Appfire.com",
  },
}
