import React from "react"
import styled from "styled-components"
import { themeGet, Tabs } from "@fuegokit/react"
import { TabsListProps, TabsTriggerProps } from "./types"
import { StyledTabsList, StyledTabsTrigger } from "./StyledTabs"

export const TabsList = ({
  activeTheme,
  children,
  ...props
}: TabsListProps) => {
  return (
    <StyledTabsList activeTheme={activeTheme} {...props}>
      {children}
    </StyledTabsList>
  )
}

export const TabsTrigger = ({
  activeTheme,
  children,
  ...props
}: TabsTriggerProps) => {
  return (
    <StyledTabsTrigger activeTheme={activeTheme} {...props}>
      {children}
    </StyledTabsTrigger>
  )
}
