import React from "react";

import { BaseStyles, Box } from "@fuegokit/react";
import CustomFrame from "../CustomFrame";

// Shadow this file to wrap live previews.
// Useful for applying global styles.
function LivePreviewWrapper({ children }) {
  return (
    <CustomFrame>
      <BaseStyles>
        <Box sx={{ p: 3 }}>{children}</Box>
      </BaseStyles>
    </CustomFrame>
  );
}

export default LivePreviewWrapper;
