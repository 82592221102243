import * as React from "react"

import { Box, Text, Link, themeGet, AtlasButton } from "@fuegokit/react"
import styled from "styled-components"

import { Head, Header } from "@fuegokit/gatsby-theme-fuegodocs"

import Footer from "../Footer"
import BackButton from "../BackButton"
import ArticlePageWrapper from "../ArticlePageWrapper"

const ArticleLayout = ({ pageContext, children, title }: any) => {
  return (
    <>
      <Head title={title} description={pageContext.frontmatter.description} />

      <Box
        as="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
        }}
      >
        <Header packageName={"website"} />

        <ArticlePageWrapper>
          <BackButton />
          {children}
        </ArticlePageWrapper>

        <Footer />
      </Box>
    </>
  )
}

ArticleLayout.defaultProps = {
  pageContext: {
    frontmatter: {},
  },
}

export default ArticleLayout
