import React, { useState } from "react";
import styled from "styled-components";
import { Box, VisuallyHidden, themeGet } from "@fuegokit/react";

const NEGATIVE = "feedback-survey-negative";
const NEUTRAL = "feedback-survey-neutral";
const POSITIVE = "feedback-survey-positive";

const Experience = React.forwardRef(function Experience(props, ref) {
  const [selected, setSelected] = useState(NEUTRAL);
  const onExperienceChange = (e) => {
    setSelected(e.target.id);
  };

  return (
    <Fieldset onChange={onExperienceChange}>
      <VisuallyHidden>
        <legend>Rate your experience:</legend>
      </VisuallyHidden>
      <Wrapper>
        <Label htmlFor={NEGATIVE}>
          <Input
            ref={selected === NEGATIVE ? ref : undefined}
            aria-label={NEGATIVE}
            type="radio"
            id={NEGATIVE}
            defaultChecked={selected === NEGATIVE}
            name="feedback-survey-experience"
            value="Negative"
          />
          <VisuallyHidden>
            <span>Negative</span>
          </VisuallyHidden>
          {selected === NEGATIVE ? "🙁" : "🙁"}
        </Label>

        <Label selected={selected} htmlFor={NEUTRAL}>
          <Input
            ref={selected === NEUTRAL ? ref : undefined}
            aria-label={NEUTRAL}
            type="radio"
            id={NEUTRAL}
            defaultChecked={selected === NEUTRAL}
            name="feedback-survey-experience"
            value="Neutral"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <VisuallyHidden>
            <span>Neutral</span>
          </VisuallyHidden>
          {selected === NEUTRAL ? "😐" : "😐"}
        </Label>

        <Label selected={selected} htmlFor={POSITIVE}>
          <Input
            ref={selected === POSITIVE ? ref : undefined}
            type="radio"
            aria-label={POSITIVE}
            id={POSITIVE}
            defaultChecked={selected === POSITIVE}
            name="feedback-survey-experience"
            value="Positive"
          />
          <VisuallyHidden>
            <span>Positive</span>
          </VisuallyHidden>

          {selected === POSITIVE ? "🤩" : "🤩"}
        </Label>
      </Wrapper>
    </Fieldset>
  );
});

const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`;

const Fieldset = styled.fieldset`
  display: flex;
  gap: ${themeGet("space.4")};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0;
  background: ${themeGet("colors.surface.sunken")};
`;
const Label = styled.label`
  transition: all 150ms cubic-bezier(0.16, 1, 0.3, 1);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* height: 96px; */
  /* width: 100%; */
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;

  &:hover {
    background: ${themeGet("colors.background.neutral.subtle.hovered")};
  }

  svg {
    fill: ${themeGet("colors.icon.default")};
    pointer-events: none;
  }
  span {
    position: absolute;
    pointer-events: none;
    top: ${themeGet("space.3")};
    left: ${themeGet("space.3")};
  }
`;

const Input = styled.input`
  all: unset;

  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  appearance: none;
  margin: 0;
  border: none;
  outline: none;
  border-radius: ${themeGet("radii.2")};
  &:checked {
    border: 1px solid
      ${(p) => (p.selected ? themeGet("colors.border.selected") : "")};
    border: 1px solid
      ${(p) => (p.selected ? themeGet("colors.border.selected") : "")};
    background: ${themeGet("colors.background.neutral.subtle.pressed")};
    z-index: 1;
  }
  &:focus {
    outline: 2px solid ${themeGet("colors.border.selected")};
  }
  svg,
  &:hover svg {
    fill: ${themeGet("colors.icon.default")};
  }

  &:hover {
    color: ${themeGet("colors.background.neutral.subtle.hovered")};
  }
`;

export default Experience;
