import styled from 'styled-components';
import sx from '../../sx.js';
import { MaxWidthWrapper } from './FooterSection.js';

/**
 * The GettingStartedStack component is a general purpose abstract stack component that injects
 * margin between sections in the layout via their common parent.
 *
 * It uses the adjacent sibling combinator and applys `56px` of margin, but only where a section
 * is preceded by another element.
 *
 */
const GettingStartedStack = styled(MaxWidthWrapper).withConfig({
  displayName: "GettingStartedStack",
  componentId: "sc-86y0rf-0"
})(["& > * + *{margin-block-start:56px;}", ""], sx);

export { GettingStartedStack };
