import { Tabs } from "@fuegokit/react"
import styled from "styled-components"
import { themeGet, sx } from "@fuegokit/react"
import { TabsListProps, TabsTriggerProps } from "./types"

export const StyledTabsList = styled(Tabs.List)<TabsListProps>`
  display: flex;
  border-bottom: 1px solid
    ${p =>
      p.activeTheme?.name === "appfire"
        ? "rgb(92, 91, 94)"
        : themeGet("colors.border.default")};
  gap: ${themeGet("space.4")};
`

export const StyledTabsTrigger = styled(Tabs.Trigger)<TabsTriggerProps>`
  cursor: pointer;
  background-color: transparent;
  border: none;
  line-height: 1.8;
  margin: 0px;
  padding: ${themeGet("space.2")} 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${p =>
    p.theme.name === "appfire"
      ? themeGet("colors.text.default")
      : themeGet("colors.text.subtle")};
  border-bottom: 2px solid transparent;

  &[aria-selected="true"] {
    color: ${p =>
      p.theme.name === "appfire"
        ? themeGet("colors.background.brand.bold.default")
        : themeGet("colors.text.selected")};
    border-bottom: 2px solid
      ${p =>
        p.theme.name === "appfire"
          ? themeGet("colors.background.brand.bold.default")
          : themeGet("colors.border.selected")};
  }
`
